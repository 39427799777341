import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { FinalizeRetryMomoComponent } from '../finalize-retry-momo/finalize-retry-momo.component';

@Component({
  selector: 'app-finalize-momo-payment',
  templateUrl: './finalize-momo-payment.component.html',
  styleUrls: ['./finalize-momo-payment.component.scss']
})
export class FinalizeMomoPaymentComponent implements OnInit {
  appuser: any;

  constructor(
    private service:DashboardService,
    public dialogRef: MatDialogRef<FinalizeMomoPaymentComponent>,
    public dialogRetryRef: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router:Router
    ) { 
      this.appuser = this.service.currentUser();

    }

     selectedProvider:any=null;
     isLoading: boolean=true;
     disableFinish:boolean=true
     pleaseWait:boolean=true
     queryInfo:string='please wait...'

     ngOnInit() {
      //  setTimeout(()=>{
      //    this.isLoading=true
      //    this.pleaseWait=true
      //  },15000)

        this.selectedProvider=this.providers.find(r=>r.value===this.data.quotePayload.momo_type)
        if(this.selectedProvider.value==='VODAFONE')
         this.selectedProvider={
          ...this.selectedProvider,
          title:'Your deposit is being processed. A confirmation pop-up should be sent to you by Vodafone for your transaction to be confirmed.',
          details:`<strong>Please note:</strong>If no confirmation pop-up is received,please verify your available mobile money balance before attempting your next transaction.`,
        }
        else if(this.selectedProvider.value==='MTN')
        this.selectedProvider={
          ...this.selectedProvider,
          title:'Your deposit is being processed. A confirmation pop-up should be sent to you by MTN for your transaction to be confirmed.',
          details:`
          <strong>Please note:</strong>If no pop-up is received, please verify your available mobile money balance and complete the following steps to approve your transaction for processing:
          <br/>
           <div style="margin-left:10px"><br/>
              1.Dial <strong>*170#</strong> to access the USSD menu<br/>
              2.Select option 6 <strong>(My Wallet)</strong><br/>
              3.Select option 3 <strong>(My Approval)</strong><br/>
              4.List of pending transactions will be displayed<br/>
              5.Confirmation screen is displayed<br/>
              6.Select 1 <strong>(Yes)</strong> to approve<br/>
              7.Confirm that transaction has been approved is displayed
           </div>
          `,
        }
        else if(this.selectedProvider.value==='ARTLTIGO')
        this.selectedProvider={
          ...this.selectedProvider,
          title:'Your deposit is being processed. A confirmation pop-up should be sent to you by Airtel Tigo for your transaction to be confirmed.',
          details:`<strong>Please note:</strong>If no confirmation pop-up is received,please verify your available mobile money balance before attempting your next transaction.`,
        }


        let paymentQueryInterval=  setInterval(()=>{
          this.onFinish(false)
        },73000)

        setTimeout(()=>{
          this.service.swalAlart.close()
          clearInterval(paymentQueryInterval)
          setTimeout(() => {
            this.isLoading=this.disableFinish=false
          }, 15000);
        },30000)


     }
    providers:any=[
      {icon:'assets/img/payment/vodafone_cash_logo.png',value:"VODAFONE"},
      {icon:'assets/img/payment/mtn_momo_logo.png',value:"MTN"},
      {icon:'assets/img/payment/airtel_tigo_money_logo.png',value:"ARTLTIGO"}
    ]


    onFinish(closeDialog=true){
      let payload: any = {}

      console.log("data->>> " + JSON.stringify(this.data))
      console.log("data->>> " + this.data.quotePayload.policy_lob)

      if(this.appuser.role=='agent'){
        payload={
          agent_code:this.appuser.code,
          client_code:this.data.quotePayload.client_code,
          module:'agent',
          transaction_token:this.data.quotePayload.transaction_token,
          policy_sequence:this.data.quotePayload.policy_sequence,
          platform:'webapp',
          policy_lob: this.data.quotePayload.policy_lob,
        }
      }else if(this.appuser.role=='broker-user'){
        payload={
          agent_code:this.appuser.broker_user_main_broker_code,
          broker_user_code:this.appuser.code,
          client_code:this.data.quotePayload.client_code,
          module:'broker-user',
          transaction_token:this.data.quotePayload.transaction_token,
          policy_sequence:this.data.quotePayload.policy_sequence,
          platform:'webapp',
          policy_lob: this.data.quotePayload.policy_lob,
        
        }
    
      }else if(this.appuser.role=='client'){
        payload={
          client_code:this.appuser.code,
          module:'client',
          transaction_token:this.data.quotePayload.transaction_token,
          policy_sequence:this.data.quotePayload.policy_sequence,
          platform:'webapp',
          policy_lob: this.data.quotePayload.policy_lob,
        }

      }else{}
     

      this.queryInfo='querying payment confirmation...'

      if(closeDialog)
      console.log("finalize-momo-payment->>> " + payload.policy_lob)

      this.service.swalAlart.showLoading()

      this.service.manualQuery(payload)
      .toPromise()
      .then((response:any)=>{
        this.service.swalAlart.close()
          if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
            this.service.swalAlart.close();

            console.log("policy_lob===> " + payload.policy_lob);
          
            if(payload.policy_lob === "20"){
              this.service.swalAlart.fire({
                title: 'Payment successful',
                html: response.hasOwnProperty('stickerUrl')? `SMS and Email sent with Policy details to print<br><br>Kindly print <a target='_blank' href='${response.stickerUrl}'>Sticker</a> and <a target='_blank' href='${response.certificateUrl}'>Certificate</a>`:`Payment completed successfully;your certificate has been mailed to you!<br><br/>Kindly click the <a target='_blank' href='${response.certificateUrl}'>Preview</a> link to view certificate`,
                icon: 'success',
                confirmButtonText:'Done',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              })
              .then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close()
              }
                
              });
            }else if (payload.policy_lob === "50"){
              this.service.swalAlart.fire({
                title: 'Payment successful',
                html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a><br><br><a target='_blank' href='${response.travelDocUrl}'>Click here to print <b>travel documents</b></a>`,
                icon: 'success',
                confirmButtonText:'Done',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result)=>{
                if(result){
                  this.router.navigate(['transactions']);
                  this.dialogRef.close();
                }
               });

            }else{
              this.service.swalAlart.fire({
                title: 'Payment successful',
                html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
                icon: 'success',
                confirmButtonText:'Done',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result)=>{
                if(result){
                  this.router.navigate(['transactions']);
                  this.dialogRef.close();
                }
               });
               
            }

         

          }else if(response.hasOwnProperty('responseCode') && response.responseCode=='E55'){

            if(closeDialog){
              this.openfinalizeMomoRetryPaymentDialog(payload)
              this.dialogRef.close();
            }

          }else if (response.hasOwnProperty('responseCode') && response.responseCode=='001'){
            // this.quotePayload.module="PAID";
            this.service.swalAlart.close();
            this.service.swalAlart.fire({
              title: 'Processing Payment...',
              html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close();
              }
             });

        }else if (response.hasOwnProperty('responseCode') && response.responseCode=='500'){
          this.service.swalAlart.close()
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Server error',
            icon: 'error',
          });
        }
        else{

        }
      })
    }

   
   openfinalizeMomoRetryPaymentDialog(payload){
    const dialogRef = this.dialogRetryRef.open(FinalizeRetryMomoComponent, {
      disableClose :true,
      width: '350px',
      data: { quotePayload: payload}
    });
    dialogRef.afterClosed().subscribe((result:any) => {

      if(result.hasOwnProperty('data')){
        result.data.quotePayload.module=this.service.currentUser().role.toLowerCase();
        //console.log('Data',result.data)
      }
    });
  }

  
}
