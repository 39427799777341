import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../../modules/service/dashboard.service';
import {TpagtService} from '../../../modules/service/tpagtservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material';
import {Chart} from 'chart.js'



interface DurationPeriod {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-widget-toppeagentgraph',
  templateUrl: './toppeagentgraph.component.html',
  styleUrls: ['./toppeagentgraph.component.scss']
})
export class ToppeagentgraphComponent implements OnInit {
  chart: Chart;

  selectedValue: string;
  selected = '30';

  days: DurationPeriod[] = [
    {value: '7', viewValue: 'Last 7 Days'},
    {value: '30', viewValue: 'Last 30 Days'},
    {value: 'months', viewValue: 'Last Year'}
  ];


  // charts:any;
  ud :any //get users email


  total:any;
  transact:any;
  pending:any;

  loaderror:any=''

  sday:boolean=true;
  thday:boolean=false;
  yrday:boolean=false;


  loading:boolean = false;
  topperformstartdate:any;
  topperformenddate:any;
  selectmonth: any;

  periodchart:any=[];



  constructor(public service: DashboardService, public router:Router,  public topperfomservice: TpagtService) {
    this.ud=this.service.currentUser();
  }

  ngOnInit() {

    if (this.ud.role == "agent"){

      this.thday = false;
      this.sday = false;
      this.yrday = true;

      var today = new Date(); //get todays date
       //console.log(month);


      this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date
      //console.log(this.topperformenddate);


      today.setDate(today.getDate() - 30) // subtract 7 days from todays date
      this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start date
      //console.log(this.topperformstartdate);

      this.perfomanceDetails()

    }else{

    }



  }


  perfomanceDetails(){



    // var charts=[]

  //  const canvas: any = document.getElementById('topperformingagent');
  //     const ctx = canvas.getContext('2d');


  //         if(this.window != undefined)
  //         this.window.destroy();
  //         this.window = new Chart(ctx, {});

   // var ctxLine = document.getElementById("line-chart").getContext("2d");


   //charts.destroy();
    let minDate = new Date(this.topperformstartdate)
    let maxDate = new Date(this.topperformenddate)

    var days ;
     days  = (maxDate.getTime()-minDate.getTime())/(1000 * 3600 * 24)
    // let thirdmonth = (maxDate.getTime()-minDate.getTime())/(1000 * 3600 * 24)

    if (days > 30 ){

      days = "months";
    }


    if(this.chart!=null){

     // console.log("we go destroyyyy")
      this.chart.destroy();
  }
    //document.querySelector("#rep").innerHTML = '<canvas id="topperformingagent"></canvas>';


    //let month = (maxDate.getMonth()-minDate.getMonth())/(1000*3600*12);

  // if(month > 1 || month===12 ){
      if (this.topperformstartdate === undefined || this.topperformenddate === undefined) {
        swal.fire({ icon: 'error',title: 'Oops...',text: 'Start Date or End Date was not selected.',footer: 'Please, make the necessary changes and try again.'});
      } else if(this.topperformstartdate > this.topperformenddate) {
        swal.fire({ icon: 'error',title: 'Oops...',text: 'Start Date is more than End Date.',footer: 'Please, make the necessary changes and try again.'});
      } else {
        this.loading = true;
        this.service.getTopPerformingAgent({email:this.ud.email,minDate:this.topperformstartdate,maxDate:this.topperformenddate,duration:days.toString(), role:this.ud.role})
          .subscribe(data => {
            this.loading = false;
            //console.log("data" + data['responseData'])
            this.periodchart = data['responseData'];


            var labels = [],total=[];
            data['responseData'].forEach(element => {
              labels.push(element.agent_id);
              //amounts.push(parseFloat(element.total.replace(/,/g,'')));
            //  total.push(element.total);
            total.push( parseFloat(element.total.replace(/,/g,'')));

            //  parseFloat(element.total.replace(/,/g,''))
            });


            this.chart = this.topperfomservice.getTopPerformChart(labels,total,'topperformingagent');
   // chart.destroy();
  //  chart = this.topperfomservice.getTopPerformChart(labels,total,'topperformingagent');

            // this.topperformenddate = ''
            // this.topperformstartdate = ''

          },error => {
            this.loading = false;
            swal.fire({ icon: 'error',title: 'Oops...',text: error.message,footer: 'Please, make the necessary changes and try again.'});
          })
      }
    //}

    //  else {
    //   return  swal.fire({ icon: 'error',title: 'Oops...',text: 'Interval Should be 7 or 30 days',footer: 'Please, make the necessary changes and try again.'});
    // }
  }





   removeData(chart) {
    chart.data.labels.pop();
    chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
    });
    chart.update();
}

  day_7(){
    this.sday = true;
    this.thday = false;
    this.yrday = false;

    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 7) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start dateISOString().substring(0,10) //set that date to start date

    this.perfomanceDetails()
  }

  day_30(){
    this.thday = true;
    this.sday = false;
    this.yrday = false;
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() -30) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start dateSOString().substring(0,10) //set that date to start date

    this.perfomanceDetails()
  }

  day_365(){
    this.thday = false;
    this.sday = false;
    this.yrday = true;
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 365) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start dateng().substring(0,10) //set that date to start date
    this.perfomanceDetails()
  }



  changeRatio(event: MatSelectChange) {
    //console.log(event.value);

if (event.value == "7"){
  //console.log("doing 7");
  this.day_7()

}
else if (event.value == "30"){

  //console.log("doing 30");
  this.day_30()

}
else {

  //console.log("doing 365");
  this.day_365()
}


  }


}
