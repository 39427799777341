import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from '../../service/dashboard.service';
import * as moment from 'moment';
import swal, { SweetAlertResult } from 'sweetalert2'
import { Router } from '@angular/router';

interface selectable {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {


  dobStartAt=new Date(moment(moment().format('YYYY-MM-DD')).subtract(18,'years').format('YYYY-MM-DD'))

  formStep: number=1;
  validate: boolean=false;

  audience: selectable[] = [
    {value: '01', viewValue: 'Individual'},
    {value: '02', viewValue: 'Corporate'},
  ];
  genderData: selectable[] = [
    {value: '1', viewValue: 'Male'},
    {value: '2', viewValue: 'Female'},
  ];

  id_typeData: selectable[]=[
    {value: 'Driver License', viewValue: 'Driver License'},
    {value: 'Voter ID', viewValue: 'Voter ID'},
    {value: 'Passport', viewValue: 'Passport'},
    {value: 'National ID', viewValue: 'National ID'},
    {value: 'NHIS Card', viewValue: 'NHIS Card'},
  ]

  public selectedValue: string = "";
  public isInValidDob: boolean=false;

  public usrType:any;

  public agentshow:boolean = false;
  public customershow:boolean = false;

  private pin_error:boolean =false;


  public payload:any={}

  constructor(public service: DashboardService,private router:Router,private _snackBar :MatSnackBar, public dialogRef: MatDialogRef<SignupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      //this.selectedColor = data.selectedColor;
      this.payload={
          client_type_code:"01",
          module:"client",
          created_by:()=>{
            return `${this.payload.first_name} ${this.payload.last_name}`
          },
          first_name:this.data.customer_first_name || "",
          last_name:this.data.customer_last_name || "",
          home_email:this.data.customer_email || "",
          home_mobile :this.data.customer_phone || "",
          work_email:"",
          work_mobile:"",
          pin:"",
          pin2:"",
          dob:"",
          gender:"",
          idType: '',
          idNumber: '',
          tin:"",
          home_zip_code:"",
          answer1:"",
          answer2:"",
          answer3:""
      }

    }

  ngOnInit() {
    this.agentshow = true;
    this.customershow = false;

  }
  dobFilter(date: Date){
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'),'years')>=18;
  };

  isValidDate=dateString=>{
    this.isInValidDob=false;
    let isValid=this.dobFilter(new Date(dateString));
    if(!isValid){
      this.isInValidDob=true;
      this.alertSnackBar(`Date of birth must be greater or equal to:${moment(this.dobStartAt).format('LL')}`,'Invalid Date');
    }
    return isValid;
  }

  onContinue=()=>{
    this.validate=false;
    if(this.formStep==1){
       if(this.payload.client_type_code.trim().length==0
            ||this.payload.first_name.trim().length==0
            || (this.payload.client_type_code!='02' && this.payload.last_name.trim().length==0)
            || !this.isValidDate(this.payload.dob)
            || this.payload.home_mobile.trim().length==0
            || this.payload.idType=='Select ID Type'
            || this.payload.idNumber.trim().length==0
         )
       this.validate=true;
       else
       this.payload.dob=moment(this.payload.dob).format('DD/MM/YYYY');
       this.formStep=2;
    }
    else if(this.formStep==2){
      if(this.payload.pin=="" || this.payload.pin!=this.payload.pin2 || !this.payload.answer1 || !this.payload.answer2 || !this.payload.answer3)
      this.validate=true;
      else if(!this.service.isValidatePin(this.payload.pin)){
        this.pin_error=this.validate=true;

        setTimeout(()=>this.service.swalAlart.fire({
          text:'PIN must not contain sequential nor be all repeating digits.',
          title:'Invalid Pin',
          icon:"error"
        }),100)
        this.alertSnackBar('PIN must not contain sequential nor be all repeating digits.','Invalid Pin');
      }
      else
      this.onSumbit();
    }

    if(this.validate)
    this.service.swalAlart.fire({
      icon: 'warning',
         text: 'Kindly fill the highted fields!',
         timer: 1000,
        showConfirmButton: false
    })
  }

  onSumbit(){
    this.service.swalAlart.showLoading();
    this.service.customerSignUp(this.payload)
    .toPromise()
    .then((response: any)=>{
      if(response.hasOwnProperty('responseCode') && response.responseCode==='000'){
        //console.log('response',response);
        this.service.swalAlart.fire({
          icon: 'success',
             text: 'Customer registration successful',
            //  timer: 1000,
            showConfirmButton: true
        })
        .then((result:SweetAlertResult)=>{
          if(result.value){
            this.dialogRef.close({ event: 'close', data: this.data['via_quick_quote']?{
              customer_first_name:response.responseObject.customer_first_name,
              customer_last_name:response.responseObject.customer_last_name,
              client_code:response.responseObject.client_code,
              dob:this.payload.dob,
              customer_phone:response.responseObject.home_mobile,
              customer_email:response.responseObject.home_email
            }:{} });

            if(!this.data['via_quick_quote'])
            this.router.navigate([''])
          }
        })
      }
      else if(response.hasOwnProperty('responseCode') && response.responseCode=='E58'){
        this.service.swalAlart.fire({
          title:"Account Not Found",
          icon:"warning",
          text:'Sorry, we found no phone number associated to your account. Kindly contact your branch office for assistance.',
        })
        this.service.swalAlart.hideLoading()
       }
      else if(response.hasOwnProperty('responseCode') && response.responseCode == 'DA01'){
        swal.fire({
          title: 'Failed',
          text: 'This account already exist',
          icon: 'error',
        });
      }

      else
      this.service.swalAlart.fire({
        icon: 'warning',
           text: 'Customer creation failed!',
           timer: 1000,
          showConfirmButton: false
      })
    })
    .catch(error=>{
      this.service.swalAlart.fire({
        icon: 'error',
           text: 'An Error occured',
           timer: 1000,
          showConfirmButton: false
      })
    })
  }

  validatePin(event){
    let pin=!isNaN(+`${event.target.value}`)?parseInt(event.target.value):0;

    if(!pin)
    event.target.value='';
  }

  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onDateDobChange(input?: any){
     this.isValidDate(this.payload.dob)
  }

  onPrevious(){
    if(this.formStep>1)
    this.formStep--;
  }
}
