import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ScKryptoService } from './ScKryptoService';
import Swal from 'sweetalert2';
import { EnvService } from './env/env.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  live_Url:any= "https://smartcover-api.herokuapp.com//smartCover/api/";
  baseURL:any = "https://smartcover-api.herokuapp.com//smartCover/api/agents/";
  baseURL2:any = "https://smartcover-api.herokuapp.com//smartCover/api/";
  VanTestURL="https://smartcover-vang-api.herokuapp.com/smartCover/api";
  

  // activeBaseURL="https://ecomputing.vanguardassurance.com/smartCover/api";
  // activeBaseURL="https://ecomputing.vanguardassurance.com:8085/smartCover/testapi";
  // activeBaseURL="https://da14-197-251-216-202.ngrok.io/smartCover/testapi";
 
  
  activeUrl="";
  agent_code="";

  swalAlart=Swal;

  googleGeoLocURL = 'https://www.googleapis.com/geolocation/v1/geolocate'
  googleKey = 'AIzaSyBIBE7Ammv9lTPMzd-pGJUZK2PaYZQro0I'
  googleGeoCodeURL = 'https://maps.googleapis.com/maps/api/geocode/json'





  notifyBucket: any = "/topics/com.ecl.smartcoveragent";
  notifyUrl: any = 'https://fcm.googleapis.com/fcm/send';
  MailSender_Url:any= "https://smartcover-api-dev.azurewebsites.net/smartCover/api";
  FlutterPayURL: any = 'https://api.flutterwave.com/v3/payments';


  isLoggedInStatus:any;


   private httpHeaders = new HttpHeaders()
   .set('Content-Type', 'application/json')
   .set('Access-Control-Allow-Origin', '*')
   .set("Acces-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS")
   .set("Acces-Control-Allow-Headers", "Origin, Content-Type, Cookies");

   private smshttpHeaders = new HttpHeaders()
   .set('Content-Type', 'application/json')
   //.set('Access-Control-Allow-Origin', '*')
   .set('Authorization', 'key=AIzaSyBlTd4JoSMbrs8W5eeRESJ5_TMFIHrgnBw')
   //.set("Acces-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS")
   //.set("Acces-Control-Allow-Headers", "Origin, Content-Type, Cookies");

   private FlutterHttpHeaders = new HttpHeaders()
  //  .set('Access-Control-Allow-Origin', '*')
  //  .set('Acces-Control-Allow-Methods', '*')
  //  .set('Access-Control-Allow-Credentials', 'true')
  //  .set('Access-Control-Allow-Headers','Origin, Content-Type, Cookies, Authorization')
   .set('Content-Type', 'application/json')
   .set('Authorization', 'Bearer FLWSECK_TEST-f245ad1f5b6ff6b57e0d6c2a2276df8f-X')


   private FormDataHttpHeaders = new HttpHeaders()
   .set('Content-Type', 'multipart/form-data')


   private options = {
    headers: this.httpHeaders
   };

   private smsoptions = {
    headers: this.smshttpHeaders
   };

   private flutterOptions = {
    headers: this.FlutterHttpHeaders
   };

   private multipartOptions = {
    headers: this.FormDataHttpHeaders
   };


  constructor(private http: HttpClient,private krypto: ScKryptoService,  public env: EnvService, ) {
    console.log("env.apiUrl --->>> " + this.env.apiUrl)
    
    this.activeUrl = this.env.apiUrl ;
    this.agent_code = this.currentUser()?this.currentUser().code:'';

  }

  get isLoggedIn(){
    this.isLoggedInStatus = localStorage.isLoggedInStatus
    if(!this.isLoggedInStatus){
      return false
    } else {
      return true
    }
  }

  logoutUser(){
    //localStorage.removeItem("appuser");
    localStorage.clear();
  }

  currentUser(user_data?: any,transform: boolean=false){

    if(user_data){
      if(transform)
      user_data=this.krypto.transFormLoginResponse(user_data);

      localStorage.setItem('appuser',this.krypto.encrypt(JSON.stringify(user_data)));
    }
    else{
      user_data=localStorage.getItem('appuser');
      try {
        user_data=user_data? JSON.parse(this.krypto.decrypt(user_data)):null;

      } catch (error) {
        user_data=null;
      }
    }
    return user_data;
  }

  //onChasisChange
  checkChasis(payload: any){
    return this.http.post(this.activeUrl + "/general/search_chassis", payload, this.options);
  }

 //login api
  webLogin(payload:any){
    return this.http.post(this.activeUrl + "/general/sign_in", payload, this.options);
  }


  //change pin/password
  changePin(payload: any){
    return this.http.post(this.activeUrl + "/general/change_pin", payload, this.options);
  }

  changePinBrokerUser(payload: any){
    return this.http.post(this.activeUrl + "/brokeruser/change_pin", payload, this.options);
  }

  changePinBrokerAdmin(payload: any){
    return this.http.post(this.activeUrl + "/v2/brokeradmin/change_pin", payload, this.options);
  }

  //reset pin/password
  resetPin(payload: any){
    return this.http.post(this.activeUrl + "/general/request_pin_reset", payload, this.options);
  }

  //reset pin/password broker
  resetPinBroker(payload: any){
      return this.http.post(this.activeUrl + "/brokeruser/request_reset", payload, this.options);
  }
  


  //answer security questions
   answerSecurityQuestions(payload: any){
    return this.http.post(this.activeUrl + "/general/reset_pin", payload, this.options);
   }

  //answer security questions broker user
  answerSecurityQuestionsBroker(payload: any){
      return this.http.post(this.activeUrl + "/brokeruser/reset_pin", payload, this.options);
  }


   //create quote api
   creatQuote(payload:any){
    return this.http.post(this.baseURL2 + "webApp/add_quotes", payload, this.options);
   }


  // all policies created by agent api
  getAgentPolicies(agent_id:any){
    return this.http.post(this.baseURL + "get_policies", JSON.stringify({agent_id: agent_id}), this.options);//agent policies
   }


  // all stickers sold by agent api
  getAgentStickers(agent_id:any){
    return this.http.post(this.baseURL + "get_stickers", JSON.stringify({agent_id: agent_id}), this.options);//agent policies
   }

  // all total and daily production amount
  getAnaytics(payload){
    return this.http.post(this.activeUrl+'/transactions/get_transactions_analytics',payload, this.options);//analytics
  }
  // covers
  getCovers(){
    return this.http.post(this.activeUrl+'/general/retrieve_products',{policy_lob:"50"}, this.options);//analytics
  }

  // fire items
  getFireItems(policy_sequence){
    return this.http.post(this.activeUrl+'/general/get_quotes_items',{policy_sequence:policy_sequence}, this.options);//analytics
  }
  // fire legacy items
  getFireLegacyItems(payload){
    return this.http.post(this.activeUrl+'/general/get_quotes_items_legacy',payload, this.options);
  }


  // products
  getProducts(paylod){
    return this.http.post(this.activeUrl+'/general/retrieve_products',paylod, this.options);//analytics
  }

  // Occupation
  getOccupation(){
    return this.http.post(this.activeUrl+'/general/retrieve_profession',{policy_lob:"50"}, this.options);//analytics
  }

  // for Cash inflow, cheque, mobile money
Cashsegregate(payload){
  return this.http.post(this.activeUrl+'/transactions/get_transactions_total_type',payload, this.options);
 }

 //transaction graph
gettransactionDetails(data){
  return this.http.post(this.activeUrl +'/transactions/get_transactions_Period_Details',data, this.options)
}

//print sticker
printSticker(payload){
  //return this.http.post(this.activeUrl +'/general/print_docs',payload,this.options);
  return this.http.post(this.activeUrl +'/v2/policy/print_doc',payload,this.options);
}

// sticker sales analytics
getStickersalesgraph(data){
  return this.http.post(this.activeUrl +'/transactions/get_stickerOverPeriod',data, this.options)
  }

// get list of banks
getBanksList(){
  return this.http.post(this.activeUrl +'/general/retrieve_banks',{},this.options)
}

//Cash Pay api
CashPay(payload){
      return this.http.post(this.baseURL + "/add_insurance", payload, this.options);
}


//Momo Pay api
DigiPay(payload:any){
  return this.http.post(this.FlutterPayURL,payload,this.flutterOptions);
}

//load policies api
LoadPolicies(payload:any){
  return this.http.post(this.baseURL2 + "clients/verify_policy", payload, this.options);
}

//submit new claim api
NewClaim(formData){
  return this.http.post(this.baseURL2 + "clients/initiate_claim", formData);
}

// get user transactions
getTransaction(payload){
  return this.http.post(this.activeUrl +'/transactions/get_transactions_nic',(payload), this.options);//Transactions
 }


 sendOtp(payload){
   return this.http.post(this.activeUrl+'/general/request_OTP',payload,this.options);
 }

 getPolicyInfo(payload,flow='all'){
   return this.http.post(this.activeUrl+(flow=='today'?'/v2/policy/get_policy_info_temp':'/v2/policy/get_policy_info'),payload,this.options);
  //  return this.http.post(this.activeUrl+'/v2/policy/get_policy_info_temp',(payload), this.options);
 }

 getPoliciesForRenewal(payload){
  return this.http.post(this.activeUrl+'/v2/policy/get_policies',(payload), this.options);
}

 //get the policies for document printing
 getPoliciesForDocuments(payload){
   //return this.http.post(this.activeUrl+'/general/info_for_print',payload,this.options);
   return this.http.post(this.activeUrl+'/v2/policy/info_for_print',payload,this.options);
 }

 //get all quotes here
  getquote(payload){
    return this.http.post(this.activeUrl+'/v2/quotes/get_quotes',(payload), this.options);
    //return this.http.post(this.activeUrl+'/general/get_quotes',(payload), this.options);
   }


// all customers api
  //  getCustomers(email, role){
  //   return this.http.post(this.live_Url+'/managers/get_clients',JSON.stringify({email: email, role:role}), this.options);//customers
  //  }


  //  new Customers
  // newClient(email, role){
  //   return this.http.post(this.live_Url+'/managers/get_newClients',({email:email, role:role}), this.options)
  // }

  getNotifications(id){
    return this.http.post(this.live_Url+'/agents/get_notifications',({id:id}), this.options)
  }

  // Send notifications
  sendNotification(data:any){
    return this.http.post(this.notifyUrl,data,this.smsoptions)
  }

  // Send Notifications
  sendSmstoDb(data){
    console.log(data);

    return this.http.post(this.live_Url+'/managers/add_notification',data, this.options)
  }




// top performing agents
getTopPerformingAgent(data:any){
  return this.http.post(this.live_Url+ '/managers/get_topPerformingAgents',data, this.options)
}

  // policy sales graph
  policiesGraphapi(data){
    return this.http.post(this.live_Url+'/managers/get_PolicyOverPeriod', data, this.options)
  }
  // claims sections
  claimProcess(email, role){
    return this.http.post(this.live_Url+'/claims/get_claims',({email:email, role:role}), this.options)
    }
// reister managers
addManager (mdata){
  console.log(mdata);
  return this.http.post(this.MailSender_Url+'/managers/add',mdata, this.options)

}

// get all managers
getManagers(email:any, role){
  return this.http.post(this.live_Url+'/get_managers',JSON.stringify({email:email, role:role}), this.options)
}

// get all countries
getCountries(){
  return this.http.post(this.activeUrl+'/general/retrieve_countries',{policy_lob:"50"}, this.options)
}

// get all stickers here
getAllstickers(email:any, role){
return this.http.post(this.live_Url+'/managers/get_stickers',JSON.stringify({email:email, role:role}), this.options);
}

  // get all policies listings
getAllpolicies(email:any, role){
  return this.http.post(this.live_Url+'/managers/get_policies',JSON.stringify({email:email, role:role}), this.options);
}


  //  Search Customer
  searchCustomer(payload:any){
    return this.http.post(this.activeUrl+'/agents/search_customers', payload, this.options)

  }
  //  Search Make
  searchMake(agent_code, search_parameter,customer_code){
    return this.http.post(this.activeUrl+'/general/search_make_model',({agent_code:agent_code, make:search_parameter,client_code:customer_code,activity:"make",module:"client"}), this.options)
  }
  //  Search Model
  searchModel(agent_code,make_code ,search_parameter,customer_code){
    return this.http.post(this.activeUrl+'/general/search_make_model',({agent_code:agent_code,make:"", make_code:make_code,model:search_parameter,client_code:customer_code,activity:"model",module:"client"}), this.options)
  }
  //  Search Model
  searchColor(search_parameter){
    return this.http.post(this.activeUrl+'/general/retrieve_color',({color:search_parameter}), this.options)
  }

  //get all the schedules/risks
  getSchedulesAndRisks(){
    return this.http.post(this.activeUrl+'/general/retrieve_usages',({}), this.options)
  }

  //construction types
  getConstructionTypes(){
    return this.http.post(this.activeUrl+'/general/retrieve_construction_types',({}), this.options)
  }

  //get currencies
  getCurrencies(){
    return this.http.post(this.activeUrl+'/general/retrieve_currency',({}), this.options)
  }
  //get currencies
  getBodyTypes(){
    return this.http.post(this.activeUrl+'/general/retrieve_body_types',({}), this.options)
  }

  //submit Moto Quote
  submitMotoQuote(payload :any){
    return this.http.post(this.activeUrl+'/general/add_quote',(payload), this.options)
  }

  //create quote
  createQuote(payload :any){
    return this.http.post(this.activeUrl+'/v2/quotes/create',(payload), this.options)
  }

  //add vehicle
  addVehicle(payload :any){
      return this.http.post(this.activeUrl+'/v2/quotes/update',(payload), this.options)
  }

  //add vehicle
  editVehicle(payload :any){
    return this.http.post(this.activeUrl+'/v2/quotes/update',(payload), this.options)
}

  //add vehicle
  deleteVehicle(payload :any){
    return this.http.post(this.activeUrl+'/v2/quotes/delete',(payload), this.options)
}


  //submit Fire Quote
  submitFireQuote(payload :any){
    return this.http.post(this.activeUrl+'/general/add_quote',(payload), this.options)
  }
  //submit Travel Quote
  submitTravelQuote(payload :any){
    return this.http.post(this.activeUrl+'/general/add_quote',(payload), this.options)
  }

  //submit payment
  subPayment(payload: any){
    if(payload.policy_lob == '20' && payload.transaction_type == 'MobileMoney'){
      return this.http.post(this.activeUrl+'/v2/transactions/motor/digital',(payload), this.options)
   
    } if(payload.policy_lob == '20' && payload.transaction_type == 'Card'){
      return this.http.post(this.activeUrl+'/v2/transactions/motor/digital',(payload), this.options)
    
    } else if(payload.policy_lob == '20' && payload.transaction_type == 'Cash'){
      return this.http.post(this.activeUrl+'/v2/transactions/motor/cash',(payload), this.options)

    }else if(payload.policy_lob == '20' && payload.transaction_type == 'Cheque'){
      return this.http.post(this.activeUrl+'/v2/transactions/motor/cheque',(payload), this.options)

    }
    else{
      let endpoint=''
      if(payload.hasOwnProperty('transaction_type') && payload.transaction_type=='MobileMoney')
      endpoint='/add_insurance_momo'
      else if(payload.transaction_type=='Card')
      endpoint='/add_insurance_momo_vang'
      else
      endpoint='/agents/add_insurance'
      return this.http.post(this.activeUrl+endpoint,(payload), this.options)
    }
   
  }

  digitalPayment(payload: any){
    return this.http.post(this.activeUrl+'/v2/transactions/motor/digital',(payload), this.options)
  }

  cashPayment(payload: any){
    return this.http.post(this.activeUrl+'/v2/transactions/motor/cash',(payload), this.options)
  }

  chequePayment(payload: any){
    return this.http.post(this.activeUrl+'/v2/transactions/motor/cheque',(payload), this.options)
  }


  //Query Momo payment
  subQueryMomoPayment(payload: any){
    return this.http.post(this.activeUrl+'/manual_query',(payload), this.options)
  }

  manualQuery(payload: any){
    if(payload.policy_lob == '20'){
      return this.http.post(this.activeUrl+'/v2/transactions/motor/manual_query',(payload), this.options)
    }else{
      return this.http.post(this.activeUrl+'/manual_query',(payload), this.options)
    }
   
  }

  //formating
  strDateToDBFormat(date :string){
       if(date){
        let dateParts=date.split("/");
        if(dateParts.length>1)
        return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
       }
       return null;

  }
  dateToDBFormat(date :Date){
    let date1= new Date(date);
    return `${date1.getFullYear()}-${(date1.getMonth()+1)}-${date1.getDate()}`;
  }
   // Customer sign up api
   customerSignUp(payload:any){
    return this.http.post(this.activeUrl + "/clients/create_account", payload, this.options);
  }
   agentCustomerSignUp(payload:any){
    return this.http.post(this.activeUrl + "/clients/create_account", payload, this.options);
  }

  verifyAgentAccount(payload){
    return this.http.post(this.activeUrl+'/agents/agent_verify',payload,this.options);
  }

  validateOTP(payload){
    return this.http.post(this.activeUrl+'/general/validate_OTP',payload,this.options);
  }

  agentAddSecurityQuestions(payload){
    return this.http.post(this.activeUrl+'/agents/add_security_answers',payload,this.options);
  }

  clientAddSecurityQuestions(payload) {
    return this.http.post(this.activeUrl + '/clients/add_security_answers', payload, this.options);
  }

  brokerAddSecurityQuestions(payload) {
    return this.http.post(this.activeUrl + '/brokeruser/add_security_answers', payload, this.options);
  }

  brokerAdminAddSecurityQuestions(payload) {
    return this.http.post(this.activeUrl + '/v2/brokeradmin/add_security_answers', payload, this.options);
  }

  agentUpdatePin(payload){
    return this.http.post(this.activeUrl+'/agents/update_pin',payload,this.options);
  }

  activateCustomer(payload){
    return this.http.post(this.activeUrl+'/clients/activate_account',payload,this.options);
  }

  webLoginAsBrokerUser(payload){
    return this.http.post(this.activeUrl+'/brokeruser/login',payload,this.options);
  }

  webLoginAsBrokerAdmin(payload){
    return this.http.post(this.activeUrl+'/v2/brokeradmin/login',payload,this.options);
  }

  cancelTravelPolicy(payload:any){
    return this.http.post(this.activeUrl + "/v2/policy/travel/cancel", payload, this.options);
  }

  updateTravelPolicy(payload:any){
    return this.http.post(this.activeUrl + "/v2/policy/travel/update", payload, this.options);
  }

  holdPolicy(payload:any){
    return this.http.post(this.activeUrl + "/v2/policy/travel/put_on_hold", payload, this.options);
  }

  reactivatePolicy(payload:any){
    return this.http.post(this.activeUrl + "/v2/policy/travel/enable_policy", payload, this.options);
  }

  extendPolicy(payload:any){
    return this.http.post(this.activeUrl + "/v2/policy/travel/extend_policy", payload, this.options);
  }

  reducePolicy(payload:any){
    return this.http.post(this.activeUrl + "/v2/policy/travel/reduce_policy", payload, this.options);
  }


    exportToExcel(payload){


    // let queryParams = new HttpParams({ fromObject: payload }); 
   

    // console.log("queryParams"+queryParams)

  
   // return this.http.get(this.base_Url + '/report/create?'+queryParams, { responseType: 'blob' as 'blob'   })

   // return this.http.post(this.base_Url + '/report/create', payload , { responseType: 'blob' as 'blob'   })



   const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'blob'
    }

    
    return this.http.post(this.activeUrl + '/report/create', payload , {headers, responseType: 'blob' as 'blob'   } )
  }

    getBranches() {
    return this.http.post(this.activeUrl + '/general/retrieve_branches', ({}), this.options)
  }


  getAllAgents() {
    return this.http.post(this.activeUrl + '/agents/all_agents', ({}), this.options)
  }

  getBrokerUsers(payload) {
    return this.http.post(this.activeUrl + '/brokeruser/get_by_agent_code', payload, this.options)
  }

  GetPaymentPlatform(payload){
    return this.http.post(this.activeUrl+'/v2/payment_company/all',payload, this.options);
   }

   sendOTP(payload:any){
    return this.http.post(this.activeUrl + "/v2/otp/sendOtp", payload, this.options);
  }

  verifyOTP(payload:any){
    return this.http.post(this.activeUrl + "/v2/otp/verifyOtp", payload, this.options);
  }




  getUserLatLng() {
    localStorage.setItem('googleAPI', 'googleAPI')
    return this.http.post<any>(this.googleGeoLocURL + '?key=' + this.googleKey, this.options);
    
  }

  getUserCountryCode(lat: string,lng: string) {

    console.log("In code get service block")
    let resultType = 'country';

    localStorage.setItem('googleAPI', 'googleAPI')
    return this.http.get(this.googleGeoCodeURL +'?latlng='+lat+ ','+ lng +'&key=' + this.googleKey + '&' + 'result_type='+ resultType)
    
    
  }

















  //validations
   validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
   }
   validatePhone(phone){
     if(phone && !isNaN(+`${phone.trim()}`) && phone.trim().length==10 && phone.startsWith('0'))
      return true
    else
      return false;
   }

   isValidatePin(pin){
     let regEx= new RegExp('^(?!(\\d)\\1+$|(?:0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)|9(?=0)){5}\\d$|(?:0(?=9)|1(?=0)|2(?=1)|3(?=2)|4(?=3)|5(?=4)|6(?=5)|7(?=6)|8(?=7)|9(?=8)){5}\\d$)\\d{6}$');
      return regEx.test(pin);
   }

  strPadLeft(number, length) {

    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }

    return str;
  }
}
