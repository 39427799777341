import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { ChartService } from './modules/service/chart.service';
import * as moment from 'moment';
import { NgTippyModule } from 'angular-tippy';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule, CurrencyPipe} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {MatTabsModule} from '@angular/material/tabs';
import { DisableRightClickService } from './modules/service/disable-right-click.service';
import { EnvServiceProvider } from './modules/service/env/env.service.provider';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatSelectModule,
    MatTooltipModule,
    GooglePlaceModule,
    MatTabsModule
  ],
  providers: [
    EnvServiceProvider,
    ChartService, 
    CurrencyPipe,
    DisableRightClickService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
