import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../modules/service/dashboard.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-widget-dlystickersales',
  templateUrl: './dlystickersales.component.html',
  styleUrls: ['./dlystickersales.component.scss']
})
export class DlystickersalesComponent implements OnInit {

  falseAuth:boolean = true;


  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;
  renewpolicy:any;
  stickersoldtoday:any = 0;


  loading:boolean = false
  ud :any //get users email
  loaderror:any = ''



  constructor( private router:Router, private service:DashboardService) {
    this.ud=this.service.currentUser();
  }

  ngOnInit() {

    if (this.ud.role == "agent"){

      this.loading = true
      let payload={agent_code:this.ud.code,role:this.ud.role}

      this.service.getAnaytics(payload).subscribe(data=>{
        this.loading = false

        this.stickersoldtoday = data['today_sticker'][0]['TOTALCOUNT'];
        this.stickersoldtoday = 0;


      }, error => {
        this.loading = false
        this.loaderror = 'Check your Internet!'
        swal.fire({
          title: 'Oops...',
          text: error.name,
          footer: ''
        });
      });

    }else{

    }



    }

}
