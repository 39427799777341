import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
import swal from 'sweetalert2';
import { DefaultComponent } from 'src/app/layouts/default/default.component';

interface Target {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-profile-account',
  templateUrl: './profile-account.component.html',
  styleUrls: ['./profile-account.component.scss']
})

export class ProfileAccountComponent implements OnInit {

  sideBaropen = true;


profile={
    id:'',
    email:'',
    firstName:'',
    lastName:'',
    managerEmail:'',
    phone:'',
    role:'',
    status:''
}


  appuser: any;
  mobile: boolean = true

  constructor(
    public router:Router, 
    public service:DashboardService, 
    public formBuilder: FormBuilder,
    public DefaultComponent: DefaultComponent
    ) {
     this.appuser=this.service.currentUser();
     this.profile.id = this.appuser.code
    this.profile.firstName = this.appuser.firstName
    this.profile.lastName = this.appuser.lastName
    this.profile.email = this.appuser.email
    this.profile.phone = this.appuser.phone
    this.profile.managerEmail = this.appuser.managerEmail
  }

  ngOnInit():void {
    this.DefaultComponent.getScreenWidth().subscribe(width => {
      if (width < 767) {
        console.log("mobile")
        this.mobile = true
     }
     else if
     (width > 767) {
      console.log("desktop")
      this.mobile = false
     }
   });

  }

  EditProfile(){

  }

  sideBarToggler(){
    this.DefaultComponent.sideBaropen = !this.DefaultComponent.sideBaropen
  }


}
