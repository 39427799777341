import { Injectable } from '@angular/core';
import {Chart} from 'chart.js'

@Injectable()
export class ChartService {


  constructor(){}
  

  perfomanceChart(labels, amounts,canvasid,module){


    var color = ""
    if (module == "agent"){
      var color = "#03a1f9";
    }
    else if (module == "customer"){
      var color = "#e544a1";
    }
    else {

      var color = "#03a1f9";
    }

    const canvas: any = document.getElementById('detailstrans');
    const ctx = canvas.getContext('2d');

  var gradientFill = ctx.createLinearGradient(500, 0, 100, 0);

  // var color = "#03a1f9";
  // var colorMagenta = "#e544a1";
    // gradientFill.addColorStop(0, "rgba(128, 182, 244, 0.6)");
    // gradientFill.addColorStop(1, "rgba(244, 144, 128, 0.6)");

//new color 


// gradientFill.addColorStop(0, "rgba(223, 248, 248,1)");
// gradientFill.addColorStop(1, "rgba(149, 196, 237, 1)");




      return new Chart(canvasid, {
        type:"line",
        data: {
          labels: labels, // your labels array
          datasets: [
            {// DEF7F8
              data: amounts, // your data array
              lineTension: 0,           
              // borderColor: 'rgb(243, 245, 247)',‎#000000
              borderColor: '#696969',
              borderWidth: 1,
              pointStrokeColor : "#03a1f9",
               pointColor : "#03a1f9",
               pointBackgroundColor: color,
             strokeColor : "#03a1f9",
             pointRadius: 3.5,
              // pointHighlightFill: "#fff",
              // pointHighlightStroke: "#ff6c23",
           //   borderColor: 'background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);',
              // barThickness: 4,
             // backgroundColor: '#DEF7F8',
              borderSkipped : true,
              showLines : false,
         
            backgroundColor:gradientFill,
             fill: false,
            },
            {
              // data: canvasid, // your data array
              // borderColor: 'ffffff',
              //fill: false
            },
          ]
        },
        options: {
      
          responsive: true,
          datasetStrokeWidth : 3,
          pointDotStrokeWidth : 4,
          animation: {
            easing: "easeInOutBack"
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              
              display: true,
              drawBorder: false,
              gridLines: {
                drawBorder: false, //<- set this
                color: "rgba(0, 0, 0, 0)",
                display: false ,
               
             },
             ticks: {
              fontFamily :'gotham',
              fontSize: 12,
              //padding: 20,
             // fontColor: "rgba(0,0,0,0.5)",
              //fontStyle: "bold"
          }
            }],
            yAxes: [{

              //to show y axis label legend
            //   scaleLabel: {
            //     display: true,
            //     labelString: 'Amount',
            //     fontColor: '#000000',
            //     fontSize:15
            // },
              ticks: {
                    //fontColor: "rgba(0,0,0,0.5)",
                  //  fontfamily : "$font-primary",
                    //fontStyle: "bold",
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    padding: 20,
                    fontFamily :'gotham',
                    fontSize: 12,
                    // fontSize: 13
                },
              display: true,
              drawBorder: false,
              gridLines: {
                display: false ,
                drawBorder: false ,//<- set this
                color: "rgba(0, 0, 0, 0)",
               
                //drawTicks: false,
                 //   display: false,
             },
            }],
          }
        }
      });
  }



  // getStickerChart(labels,datecreated){



  //   return new Chart(labels, {
  //     type: 'line',
  //     data: {
  //       labels: labels, // your labels array
  //       datasets: [
  //         {
  //           data: datecreated, // your data array
  //           borderColor: 'background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);',
  //             backgroundColor: [
  //               'rgba(200, 200, 235, 1)',
  //               'rgba(255, 99, 132, 1)',
  //               'rgba(75, 192, 192, 1)',
  //               'rgba(153, 102, 255, 1)',
  //               'rgba(255, 159, 64, 1)',
  //               'rgba(94, 107, 46, 1)',
  //               'rgba(107, 46, 58, 1)',
  //               'rgba(107, 46, 58, 1)',
  //               'rgba(208, 103, 152, 1)'

  //           ],
  //           fill: true
  //         },
         
  //       ]
  //     },
  //     options: {
  //       legend: {
  //         display: false
  //       },
  //       scales: {
  //         xAxes: [{

          
  //           display: true
  //         }],
  //         yAxes: [{

          
  //           display: true
  //         }],
  //       }
  //     }
  //   });


  // }



}
