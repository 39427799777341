import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../../modules/service/dashboard.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-widget-digichannel',
  templateUrl: './digichannel.component.html',
  styleUrls: ['./digichannel.component.scss']
})
export class DigichannelComponent implements OnInit {
  total:any;
  transact:any;
  pending:any;
  chequeamount:any;
  digichannel:any;

  loaderror:any='';
  loading:boolean = false;
  ud :any //get users email

  constructor(private service:DashboardService) {
    this.ud=this.service.currentUser();
  }

  ngOnInit() {

    if (this.ud.role == "agent"){

      this.loading = true
      let payload={agent_code:this.ud.code,role:this.ud.role}

      this.service.Cashsegregate(payload).subscribe(data=>{
        this.loading = false
        //this.total = parseFloat(data['cash'][0]['totalAmount'].replace(/,/g,''));
        //this.chequeamount = parseFloat(data['cheque'][0]['totalAmount'].replace(/,/g,''));
        if(data.hasOwnProperty('mobilemoney'))
        this.digichannel = parseFloat(data['mobilemoney'][0]['totalAmount'].replace(",",""));
      }, error => {
        this.loading = false
        this.loaderror = 'failed to fetch data. Reload'
        swal.fire({
          title: 'Oops...',
          text: error.name,
          footer: ''
        });
      });

    }else{

    }





  }


}
