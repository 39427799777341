import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-set-security-questions',
  templateUrl: './set-security-questions.component.html',
  styleUrls: ['./set-security-questions.component.scss']
})
export class setSecurityQuestionsComponent implements OnInit {

  validate: boolean=false;


  constructor(private service:DashboardService, private router:Router, public dialog: MatDialog, public dialogRef: MatDialogRef<setSecurityQuestionsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

      this.appuser=this.service.currentUser();
      this.payload={...this.payload,client_code:this.appuser.code}

    }

  payload: any={
    module:'client',
    answer1:'',
    answer2:'',
    answer3:''

  }



  ngOnInit() {}

  appuser: any

  onSetSecurityQues(){
    if(!this.payload.answer1 || !this.payload.answer2 || !this.payload.answer3){
      this.validate=true;
    }else{
      this.submitAnswers();
    }
    
  }


  submitAnswers(){
    console.log("payload>>>>>" + JSON.stringify(this.payload))

    this.service.swalAlart.showLoading();
    this.service.clientAddSecurityQuestions(this.payload)
    .toPromise()
    .then((response:any)=>{
       if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
          this.service.swalAlart.fire({
            title:'Successful',
            text:`Your security questions are set.`,
            icon: 'success'

          }).then((dialogResponse)=>{
            if(dialogResponse.hasOwnProperty('value') && dialogResponse.value==true){
             this.dialogRef.close({ event: 'close', data: {} });
             this.router.navigate(['']);
             window.localStorage.clear();
            
            }
           
         });
         
       
        }else if(response.hasOwnProperty('responseCode') && response.responseCode=='400'){
       
          this.service.swalAlart.fire({
            title:'Failed',
            text: response.responseMessage,
            //timer:2000,
          })

        }else{
        this.service.swalAlart.fire({
          title:'Failed',
          text: response.responseMessage,
          //timer:2000,
        })
       }
    })
    .catch(error=>{
      this.service.swalAlart.fire({
        title:'Failed',
        text:`Saving security questions and answers failed. Please try again!`,
        //timer:2000,
      })
    })

  }


}
