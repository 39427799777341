import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import swal from 'sweetalert2'

@Component({
  selector: 'app-widget-cashinflow',
  templateUrl: './cashinflow.component.html',
  styleUrls: ['./cashinflow.component.scss']
})
export class CashinflowComponent implements OnInit {

  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;

  loaderror:any='';
  loading:boolean = false;
  ud :any //get users email




  constructor(private service:DashboardService) {
    this.ud=this.service.currentUser();
  }

  ngOnInit() {

    if (this.ud.role == "agent"){

      this.loading = true
      let payload={agent_code:this.ud.code,role:this.ud.role}

      this.service.Cashsegregate(payload).subscribe(data=>{
        this.loading = false
        this.total = parseFloat(data['cash'][0]['totalAmount'].replace(",",""));
        //this.transacttoday = parseFloat(data['cheque'][0]['totalAmount'].replace(/,/g,''));
        //this.policytoday = data['mobilemoney'][0]['totalAmount'];
        //console.log(this.total);


      }, error => {
        this.loading = false
        this.loaderror = 'failed to fetch data. Reload'
        swal.fire({
          title: 'Oops...',
          text: error.name,
          footer: ''
        });
      });


    }else {

    }



  }



}
