import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service'
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})

export class ProductsComponent implements OnInit {


  constructor(
    private router: Router,
    private service: DashboardService,
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  onMotorClicked() {
    // const dialogRef = this.dialog.open(UpdateTravelOptions, {
    //   disableClose :false,
    //   width: '373px',
    //   data: { module:''}
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });

    localStorage.removeItem("motor-quote")
    localStorage.removeItem("objecth_id")
    localStorage.setItem('quote_activity', 'new')
    this.router.navigate(["/fleet-motor-quote"])
  }

  onTravelClick() {
   // this.router.navigate(["/travelquote"])
  }

  onFireClicked() {
    this.router.navigate(["/firequote"])
  }
}
