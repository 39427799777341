import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from '../../service/dashboard.service';
import * as moment from 'moment';
import swal from 'sweetalert2'

interface selectable {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

  formStep: number = 1;
  validate: boolean = false;

  audience: selectable[] = [
    { value: '0', viewValue: 'Individual' },
    { value: '1', viewValue: 'Corporate' },
  ];
  
  genderData: selectable[] = [
    { value: '1', viewValue: 'Male' },
    { value: '0', viewValue: 'Female' },
  ];

  id_typeData: selectable[] = [
    { value: 'Driver License', viewValue: 'Driver License' },
    { value: 'Voter ID', viewValue: 'Voter ID' },
    { value: 'Passport', viewValue: 'Passport' },
    { value: 'National ID', viewValue: 'National ID' },
    { value: 'NHIS Card', viewValue: 'NHIS Card' },
  ]

  selectedValue: string = "";

  dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(18, 'years').format('YYYY-MM-DD'))

  em: any;
  pwd: any;
  usrType: any;

  public agentshow: boolean = false;
  public customershow: boolean = false;


  constructor(public service: DashboardService, public dialogRef: MatDialogRef<CreateCustomerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) {
    this.newCustomerDetails = data.newCustomerDetails;
  }
  ngOnInit() {
    this.agentshow = true;
    this.customershow = false;
  }
  isInValidDob = false;
  newCustomerDetails: any;
  payload: any = {
    client_type_code: "",
    module: "agent",
    created_by: () => {
      return `${this.payload.first_name} ${this.payload.last_name}`
    },
    first_name: "",
    last_name: "",
    home_email: "",
    home_mobile: "",
    work_email: "",
    work_mobile: "",
    pin: "",
    pin2: "",
    customer_date_of_birth: "",
    gender: "",
    idType: "",
    idNumber: "",
    tin: "",
    home_zip_code: "",
    answer1: "",
    answer2: "",
    answer3: ""
  }


  SignUp() {
    swal.showLoading()
    //this.payload.dob=moment(this.payload.customer_date_of_birth).format('YYYY-MM-DD');

    if (this.payload.client_type_code == '0')
      this.payload.client_type_code = '01'
    else if (this.payload.client_type_code == '1')
      this.payload.client_type_code = '02'

    if (this.payload.gender == '0')
      this.payload.gender = '2'

    this.service.agentCustomerSignUp(this.payload)
      .toPromise()
      .then((response: any) => {
        swal.hideLoading()

        if (response.hasOwnProperty('responseCode') && response.responseCode === '000') {
          swal.fire({
            title: 'Successful',
            text: 'Your account has been created succeasfully. Kindly check your email for account details',
            icon: 'success',
            showConfirmButton: true
          });
        }
        else if (response.hasOwnProperty('responseCode') && response.responseCode === 'DA01') {
          swal.fire({
            title: 'Failed',
            text: 'This account already exist',
            icon: 'error',
          });
        }
      }).catch(err => {
        swal.hideLoading()

        swal.fire({
          title: 'Error',
          text: 'Check your internet connection and try again',
          icon: 'error',
        });
      })
  }
  dobFilter = (date) => {
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'), 'years') >= 18;
  };
  isValidDate = dateString => {
    this.isInValidDob = false;
    let isValid = this.dobFilter(new Date(dateString));
    if (!isValid) {
      this.isInValidDob = true;
      this.alertSnackBar(`Date of birth must be greater or equal to:${moment(this.dobStartAt).format('LL')}`, 'Invalid Date');
    }
    return isValid;
  }
  onContinue = () => {
    this.validate = false;
    if (this.payload.client_type_code.trim().length == 0
      || this.payload.first_name.trim().length == 0
      || (this.payload.client_type_code.trim().length == 0 && this.payload.last_name.trim().length == 0)
      || !this.isValidDate(this.payload.customer_date_of_birth)
      || this.payload.home_mobile.trim().length == 0
      || this.payload.idType == 'Select ID Type'
      || this.payload.idNumber.trim().length == 0
    ) {
      this.validate = true;
      if (this.validate)
        this.service.swalAlart.fire({
          icon: 'warning',
          text: 'Kindly fill the highted fields!',
          timer: 1000,
          showConfirmButton: false
        })
    }
    else
      this.onSubmit();
  }

  onSubmit() {
    this.service.swalAlart.showLoading();
    this.payload.customer_date_of_birth = moment(this.payload.customer_date_of_birth).format('DD/MM/YYYY');

    if (this.payload.client_type_code == '0')
      this.payload.client_type_code = '01'
    else if (this.payload.client_type_code == '1')
      this.payload.client_type_code = '02'

    this.service.agentCustomerSignUp(this.payload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close();
        if (response.hasOwnProperty('responseCode') && response.responseCode === '000') {
          this.service.swalAlart.fire({
            title: 'Successful',
            text: 'Customer account created succeasfully',
            icon: 'success',
            showConfirmButton: true
          });
          this.newCustomerDetails = {
            ...this.newCustomerDetails,
            customer_code: response.responseObject.client_code,
            type: this.payload.client_type_code,
            customer_first_name: this.payload.first_name,
            customer_last_name: this.payload.last_name,
            date_of_birth: moment(this.payload.customer_date_of_birth).format('DD/MM/YYYY'),
            customer_email: this.payload.home_email,
            customer_phone: this.payload.home_mobile,
            digital_address: this.payload.home_zip_code,
            id_type: this.payload.idType,
            id_number: this.payload.idNumber,
            tin: this.payload.tin
          }
          if (this.newCustomerDetails.type == '02') {
            let companyNameParts = this.newCustomerDetails.customer_first_name.split(" ");
            this.newCustomerDetails.customer_last_name = companyNameParts[1] ? companyNameParts[1] : null;
          }

          this.dialogRef.close({ event: 'close', data: this.newCustomerDetails });
        }
        else if (response.hasOwnProperty('responseCode') && response.responseCode == 'DA01')
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Customer account already exist!',
            icon: 'error',
          });
        else
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Customer account creation failed!',
            icon: 'error',
          });
      }).catch(error => {
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Error',
          text: 'Kindly check connection and retry!',
          icon: 'error',
        });
      })
  }
  validatePin(event) {
    let pin = !isNaN(+`${event.target.value}`) ? parseInt(event.target.value) : 0;
    if (!pin)
      event.target.value = '';
  }
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  onDateDobChange(input?: any) {
    this.isValidDate(this.payload.customer_date_of_birth)
  }

}
