import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  title: string;
  message: string;
  constructor( public dialogRef: MatDialogRef<InfoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private router: Router,private _snackBar: MatSnackBar) {
      this.title=data.title;
      this.message=data.message;
    }

    ngOnInit() {}

}
