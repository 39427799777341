import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ChangePinComponent } from 'src/app/modules/dialogs/change-pin/change-pin.component';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  appuser: any=null;
  constructor(public router:Router,public service: DashboardService,private dialog:MatDialog) {
       this.appuser=this.service.currentUser()


       if(!this.appuser)
       this.router.navigate(['']);
  }

  ngOnInit() {
  }

  userprofile(){
    this.router.navigate(['profile']);
  }
  changePin(){
    const dialogRef = this.dialog.open(ChangePinComponent, {
      disableClose :true,
      width: '300px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  logout(){

    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'logout ?',
      text: "You are logging out...",
      showCancelButton: true,
      confirmButtonText: 'Yes Confirm',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

    window.localStorage.clear();
    this.router.navigate(['']);

      } else (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      )

    })
}

}
