import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from '../../service/dashboard.service';
import { QuoteBenefitsComponent } from '../quote-benefits/quote-benefits.component';

export interface QuoteElement {
  value: string;
  label: string;
}

@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit {
  constructor(public service: DashboardService, public dialog :MatDialog, public dialogRef: MatDialogRef<QuoteSummaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

      if(data){
        //console.log('Fire Summary',data)
        this.confirmedQuote=data.confirmedQuote;

        if(data.quote.hasOwnProperty('confirmedRenewal'))
        this.confirmedRenewal=data.quote.confirmedRenewal;
        else if(data.hasOwnProperty('confirmedRenewal'))
        this.confirmedRenewal=data.confirmedRenewal;

        if(data.quote.hasOwnProperty('isPolicy'))
        this.isPolicy=data.quote.isPolicy;

        if(data.quote.hasOwnProperty('hideMailStatus'))
        this.hideMailStatus=data.quote.hideMailStatus;

        if(data['flow']){
          //when the data is coming from travel quote
          this.flow=data['flow'];
          this.selectedQuote=data.quote;
          let email_item: any=null;

          if(data['flow']=='moto')
          email_item=data.quote.CUSTOMER_EMAIL;
          else
          email_item=this.selectedQuote.find((item: any)=>item.label=='Customer Email');

          if(email_item)
           this.customer_email=email_item.value;
        }

        if(data['flow']=='moto'){

          this.selectedQuote=[
            {value: data.quote.DATE_COMPLETED,label:'Date Prepared'}
          ];

          if(data.quote.PREPARED_BY)
          this.selectedQuote=[...this.selectedQuote,{value: data.quote.PREPARED_BY,label:'Prepared By'}]

          //console.log('selected Quote',this.data.quote)

          this.selectedQuote=[
              ...this.selectedQuote,
            {value: `${data.quote.CUSTOMER_FIRST_NAME?data.quote.CUSTOMER_FIRST_NAME:''} ${data.quote.CUSTOMER_LAST_NAME}`.trim(),label:'Customer Name'},
            {value: data.quote.CUSTOMER_EMAIL,label:'Customer Email'},
            {value: data.quote.CLIENT_CODE,label:'Customer Code'},
            {value: data.quote.CUSTOMER_PHONE,label:'Mobile Number'},
            {value: data.quote.YEAR_OF_MANUFACTURE,label:'Year of Manufacturing'},
            {value: `${data.quote.MAKE} ${data.quote.QUOTE_MODEL}`,label:'Make & Model'},
            {value: `${data.quote.VEHICLE_REGISTRATION}`,label:'RegNo.'},
            {value: data.quote.CUBIC_CAPACITY,label:'Cubic Capacity'},
            {value: `${data.quote.CURRENCY_CODE} ${data.quote.SUM_INSURED}`,label:'Sum Insured'},
            {value: data.quote.DURATION_TYPE? `${(data.quote.DURATION_TYPE.toLowerCase()=='short term'|| data.quote.DURATION_TYPE.toLowerCase()=='shortrate'||data.quote.DURATION_TYPE.toLowerCase()=='short rate')?data.quote.MONTHS:data.quote.QUOTE_DAYS} ${(data.quote.DURATION_TYPE.toLowerCase()=='short term'|| data.quote.DURATION_TYPE.toLowerCase()=='shortrate')?'Month(s)':'Day(s)'}`:'N/A',label:'Duration'},
            {value:(data.quote.EXCESS_TYPE_CODE=='ENA'?'NO':'YES'),label:'Excess Bought'},
            {value:`${data.quote.CURRENCY_CODE} ${!data.quote.TPPD_LIMIT?0:data.quote.TPPD_LIMIT.toString().trim()}`,label:'Extra TPPD Bought'},
            {value:`${data.quote.CURRENCY_CODE} ${!data.quote.EXTRA_PA?0:data.quote.EXTRA_PA.toString().trim()}`,label:'Additional PA'},
            {value:`${data.quote.CURRENCY_CODE} ${data.quote.INPUTTED_DISCOUNT||0}`,label:'Additional Loading'},
            {value:data.quote.INCEPTION_DATE,label:'Start Date'},
            {value:data.quote.EXPIRY_DATE,label:'Expiry Date'},
            {value:(data.quote.PRODUCT_CODE=='221'?'MOTOR COMPREHENSIVE':(data.quote.PRODUCT_CODE=='223'?'THIRD PARTY FIRE & THEFT':'THIRD PARTY'))+',<br/>'+data.quote.RISK_DESCRIPTION,label:'Insurance Product'}
          ];

          this.customer_email= data.quote.CUSTOMER_EMAIL;
        }

        if(data['flow']){
          if(data['hide_foreign_currency']==1)
            this.total_premium1=`<span class="first">${'GHS'} ${data.total_premium}</span>`;
          else{
            this.total_premium1=`${'GHS'} ${data.total_premium}`;
            this.total_premium2=data.foreign_currency && data.foreign_currency!='GHC' && data.foreign_currency!='GHS' ?`${data.foreign_currency.replace('GHC','GHS')} ${data.foreign_amount}`:null;
          }
        }
        else
        this.total_premium1=`${data.quote.CURRENCY_CODE} ${data.quote.COST_OF_INSURANCE}`;
      }
    }

    confirmedQuote: boolean=false
    confirmedRenewal: boolean=false
    selectedQuote: QuoteElement[];
    total_premium1:string="";
    total_premium2:string="";
    isPolicy:boolean=false;
    hideMailStatus:boolean=false;
    customer_email:string="";
    flow:string="moto";
    displayedColumns: string[] = ['label', 'value'];

    query={
      filter:"",
      selectValue:"",
      filteredResults:[]
    }

  ngOnInit() {
    this.query.filter="";
  }
  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {confirmedQuote:true} });
  }
  editPolicy(){
    this.dialogRef.close({ event: 'close', data: {confirmedQuote:true,edit:true} });
  }
  showBenefits(){
    const ref = this.dialog.open(QuoteBenefitsComponent, {
      disableClose :true,
      width: '650px',
      data: { }
    });

    ref.afterClosed().subscribe(result => {});
  }
}
