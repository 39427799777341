import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../modules/service/dashboard.service';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import swal from 'sweetalert2';


@Component({
  selector: 'app-admitagent',
  templateUrl: './admitagent.component.html',
  styleUrls: ['./admitagent.component.scss']
})
export class AdmitagentComponent implements OnInit {
  userdata;

  agentform: FormGroup;
  constructor(private formbuilder:FormBuilder, private router: Router, private service:DashboardService) {
    this.agentform = this.formbuilder.group({
      firstName:new FormControl('',[Validators.required]),
      lastName:new FormControl('',[Validators.required]),
      phone:new FormControl('',Validators.compose([Validators.pattern(/^(\+233)[0-9]\d{8}$/),Validators.required])),
      email:new FormControl('',[Validators.required]),
      location: new FormControl('',[Validators.required]),
      managerName: new FormControl('',[Validators.required]),
      managerEmail: new FormControl('',[Validators.required]),
    })
   }

  ngOnInit() {
    this.userdata=JSON.parse(localStorage.getItem('userdata'));
  }

  register(){
    this.router.navigate(['/assignsticker'])
  }


  addagent(){
    /* if (this.agentform.valid) {
      swal.showLoading()
      this.service.addagent(this.agentform.value)
      .subscribe(response => {
        swal.hideLoading()
        if (response['responseCode'] === '000') {
          this.agentform = this.formbuilder.group({
            firstName:new FormControl('',[Validators.required]),
            lastName:new FormControl('',[Validators.required]),
            phone:new FormControl('',Validators.compose([Validators.pattern(/^(\+233)[0-9]\d{8}$/),Validators.required])),
            email:new FormControl('',[Validators.required]),
            //password: new FormControl('',[Validators.required]),
            location: new FormControl('',[Validators.required]),
            managerName: new FormControl('',[Validators.required]),
            managerEmail: new FormControl('',[Validators.required]),
          })
          swal.fire("Success",response['responseMessage'], "success");
          console.log(response);

          this.router.navigate(['/registeredagents']);
        } else {
          swal.fire({
            title: 'Oops...',
            text: response['responseMessage'],
            footer: ''
          });
        }

      }, error => {
        swal.fire({
          title: 'Oops...',
          text: error,
          footer: ''
        });
        swal.hideLoading()
      })
    } else {
      swal.fire({
        title: 'Oops...',
        text: 'Please fill all form fields',
        footer: ''
      });
    } */
  }


  viewregistered(){
    this.router.navigate(['/registeredagents']);
  }

  registeragents(){
    this.router.navigate(['/agentadmission'])
  }

  stickerlistings(){
    this.router.navigate(['/stickerlistings']);
  }
}
