import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DashboardService } from '../service/dashboard.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CreateCustomerComponent } from '../dialogs/create-customer/create-customer.component';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
import { ChequePaymentComponent } from '../dialogs/cheque-payment/cheque-payment.component';
import { CashPaymentComponent } from '../dialogs/cash-payment/cash-payment.component';
//import { DebitCardPaymentComponent } from '../dialogs/debit-card-payment/debit-card-payment.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import swal, { SweetAlertResult } from 'sweetalert2';
import { MomoDebitCardPaymentComponent } from '../dialogs/momo-debit-card-payment/momo-debit-card-payment.component';
import Swal from 'sweetalert2';
//import { MomoPaymentComponent } from '../dialogs/momo-payment/momo-payment.component';



interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_TYPE?: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}
interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}

interface ConstructionTypeResponse {
  responseCode: string,
  responseMessage: string,
  code: string,
  data: string,
  account_activation_requested: string,
  reset_requested: string,
  agent_pin_set: string,
  profile: string,
  responseData: ConstructionType[]
}
interface ConstructionType {
  TABLED_NAME: string;
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
}

interface RoofTypeResponse {
  responseCode: string,
  responseMessage: string,
  code: string,
  data: string,
  account_activation_requested: string,
  reset_requested: string,
  agent_pin_set: string,
  profile: string,
  responseData: RoofType[]
}
interface RoofType {
  TABLED_NAME: string;
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
}


interface Item {
  name: string;
  value: string;
}

interface Term {
  value: string;
  viewValue: string;
  days: string;
}
interface fireProducts {
  PRODUCT_DESC: string;
  PRODUCT_CODE: string;
}
interface CurrencyResponse {
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}
interface Currency {
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}

interface Product {
  PRODUCT_DESC: string;
  PRODUCT_CODE: string;
}

interface ProductResponse {
  responseCode: string,
  responseMessage: string,
  code: null,
  data: null,
  account_activation_requested: null,
  reset_requested: null,
  agent_pin_set: null,
  profile: Array<Product>
}

@Component({
  selector: 'app-fire-quote',
  templateUrl: './fire-quote.component.html',
  styleUrls: ['./fire-quote.component.scss']
})



export class FireQuoteComponent implements OnInit {
  appuser: any;
  terms: Array<Term> = [
    { value: '01', viewValue: 'Annual Term', days: '365' },
    { value: '02', viewValue: 'Short Term', days: '1' },
  ];

  fireProducts: Array<fireProducts> = [
    { PRODUCT_DESC: 'BEST HOME POLICY', PRODUCT_CODE: '131' },
    { PRODUCT_DESC: 'BEST SHOP OWNERS POLICY', PRODUCT_CODE: '132'}
  ];

  buildItems: Array<Item> = [];
  contentItems: Array<Item> = [];
  currencies: Array<Currency> = [];
  //customer
  searchCustomerCtrl = new FormControl();

  item_name: string = "";
  item_value: string = "";


  selectedProduct: Product;
  filteredCustomers: CustomerProfile[];

  //list of construction types
  constructionTypes: ConstructionType[];
  selectedConstructionType: ConstructionType;

  //list of roof types
  roofTypes: RoofType[];
  selectedRoofType: RoofType;


  //loadings
  isCustomerLoading: boolean = false;

  //is renewal
  renewal: boolean = false;

  //payload for submitting quote
  payload: any = {};
  filterClientPayload: any = {};


  isNewCustomer: true;

  //selected records from dialogs
  selectedCustomer: CustomerProfile = {
    CLIENT_CODE: "",
    CLIENT_TYPE: "",
    CLIENT_FAMILY_NAME: "",
    CLIENT_HOME_MOBILE: "",
    CLIENT_FIRST_NAME: "",
    CLIENT_HOME_EMAIL: "",
    CLIENT_DATE_OF_BIRTH: "",
    DISPLAY_NAME: "",
    DISPLAY_SHORT_NAME: "",
    isNewCustomer: false
  };

  //searchCustomerDialogValue :any;
  //prepare quote json
  confirmedQuote: boolean = false;
  errors = {}
  selectedMethod: string;

  //is processing
  processing = false;
  quote: any = {
    days_label: "Day(s)",
    days: "365",
    start_date: new Date(),
    end_date: '',
    term: '01',
    loss_of_rent_value: '0',
    additional_pa_amt: '0',
    additional_pa_domestic_emp_amt: '2000',
    add_pa_empl_total: '0',
    domestic_emp_no: '0',
    additional_pa_household_mem_amt: '2000',
    add_pa_household_total: '0',
    household_mem_no: '0',
    public_liability: '0',
    construction_type: '',
    roof_type: '',
    content_sum: '',
    building_sum: '',
    currency_code: 'GHC',
    exchange_rate: '1',
    cover_type_code: '131',
    location: ''
  }


  isOptional = false;


  todayDate = new Date();
  dateTxt: string;

  is_loss_of_rent: boolean
  is_additional_pa: boolean
  is_additional_pa_domestic_employees: boolean
  is_domestic_emp_no: boolean
  is_person_accident: boolean
  is_public_liability: boolean
  is_building_sum_insured: boolean
  is_content_sum_insured: boolean
  is_additional_pa_household_member: boolean
  is_household_mem_no: boolean


  products: Array<Product> = []

  latlng = {
    lat: '',
    lng: ''
  }


  countryCode: string = 'GH';
  options = {
    types: [],
    componentRestrictions: { country: this.countryCode },
    strictBounds: true
  }

  floodAreaBlacklist = ["Adabraka", "Circle", "Graphic Road", "North Industrial Area", "South Industrial Area"]


  constructor(private service: DashboardService, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar) {

    this.appuser = this.service.currentUser();
    console.log("module>>>>" + this.appuser.role)

    if (!this.appuser)
      this.router.navigate(['']);

    this.renewal = false;

    this.selectedConstructionType = {
      TABLED_CODE: '',
      TABLED_DESIGNATION: '',
      TABLED_NAME: ''
    };

    this.selectedRoofType = {
      TABLED_CODE: '',
      TABLED_DESIGNATION: '',
      TABLED_NAME: ''
    };

    this.selectedProduct = {
      PRODUCT_CODE: '131',
      PRODUCT_DESC: 'HOMEOWNERS'
    }

    this.onlossOfRentChanged(true)
    this.onPublicLiabilityChanged(true)
    this.onAdditionalPAChanged(true)

  }



  dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(18, 'years').format('YYYY-MM-DD'))




  // get the selected editable quote
  populateEditableQuote(selectQuote) {
    //console.log('selected quote',selectQuote)
    this.quote.edit_mode = true;

    this.quote = {
      ... this.quote,
      policy_no: selectQuote.POLICY_SEQUENCE,
      customer_code: selectQuote.CLIENT_CODE,
      customer_first_name: (selectQuote.CUSTOMER_FIRST_NAME ? selectQuote.CUSTOMER_FIRST_NAME : selectQuote.CUSTOMER_LAST_NAME),
      customer_last_name: selectQuote.CUSTOMER_LAST_NAME,
      customer_email: selectQuote.CUSTOMER_EMAIL,
      customer_phone: selectQuote.CUSTOMER_PHONE,
      prepared_by: selectQuote.PREPARED_BY,
      date_of_birth: this.service.strDateToDBFormat(selectQuote.CUSTOMER_DATE_OF_BIRTH),
      start_date: this.service.strDateToDBFormat(selectQuote.INCEPTION_DATE),
      end_date: this.service.strDateToDBFormat(selectQuote.EXPIRY_DATE),
      cover_type_code: selectQuote.PRODUCT_CODE.toString(),
      construction_type: selectQuote.FIRE_CONSTRUCT_TYPE_CODE,
      roof_type: selectQuote.FIRE_ROOF_TYPE_CODE,
      currency_code: selectQuote.CURRENCY_CODE,
      location: selectQuote.FIRE_LOCATION,
      digital_address: selectQuote.DIGITAL_ADDRESS,
      exchange_rate: selectQuote.EXCHANGE_RATE,
      property_description: selectQuote.FIRE_GEN_DESC || selectQuote.PRODUCT_DESCRIPTION,
      building_sum: selectQuote.BUILDING_SUM_INSURED,
      content_sum: selectQuote.CONTENT_SUM_INSURED,
      loss_of_rent: selectQuote.LOSS_OF_RENT_VALUE,
      additional_pa_amt: selectQuote.EXTRA_PA,
      additional_pa_domestic_emp_amt: selectQuote.FIRE_ADD_PA_EMPL,

      add_pa_empl_total: selectQuote.FIRE_ADD_EMPL_TOTAL,

      domestic_emp_no: selectQuote.FIRE_ADD_PA_EMPL_NO,
      additional_pa_household_mem_amt: selectQuote.FIRE_ADD_PA_HOUSEHOLD_AMT,

      add_pa_household_total: selectQuote.FIRE_ADD_HOUSEHOLD_TOTAL,

      household_mem_no: selectQuote.FIRE_ADD_HOUSEHOLD_NO,
      public_liability: selectQuote.FIRE_ADD_PL,
      days: selectQuote.QUOTE_DAYS,
      term: (selectQuote.DURATION_TYPE == "shortrate" || selectQuote.DURATION_TYPE == "short rate") ? '02' : '01',
      legacy_policy_number: selectQuote.LEGACY_POLICY_SEQUENCE
    }


    this.onStartDateChanged();
    //select construction type
    this.selectedConstructionType.TABLED_CODE = this.quote.construction_type
    this.selectedConstructionType.TABLED_DESIGNATION = selectQuote.FIRE_CONSTRUCT_TYPE_DESC

    //select roof type
    this.selectedRoofType.TABLED_CODE = this.quote.roof_type
    this.selectedRoofType.TABLED_DESIGNATION = selectQuote.FIRE_ROOF_TYPE_DESC


    //select product
    this.selectedProduct.PRODUCT_CODE = this.quote.cover_type_code
    this.selectedProduct.PRODUCT_DESC = selectQuote.PRODUCT_DESCRIPTION

    this.is_loss_of_rent = selectQuote.LOSS_OF_RENT_VALUE ? true : false
    this.is_public_liability = selectQuote.FIRE_ADD_PL ? true : false;
    this.is_additional_pa = selectQuote.EXTRA_PA ? true : false;
    this.is_additional_pa_domestic_employees = selectQuote.FIRE_ADD_PA_EMPL ? true : false;
    this.is_domestic_emp_no = selectQuote.FIRE_ADD_PA_EMPL_NO ? true : false;

    this.is_additional_pa_household_member = selectQuote.FIRE_ADD_PA_HOUSEHOLD_AMT ? true : false;
    this.is_household_mem_no = selectQuote.FIRE_ADD_HOUSEHOLD_NO ? true : false;

    this.is_building_sum_insured = selectQuote.BUILDING_SUM_INSURED ? true : false;
    this.is_content_sum_insured = selectQuote.CONTENT_SUM_INSURED ? true : false;


    //load fire items
    (this.renewal ? this.service.getFireLegacyItems({ client_code: selectQuote.CLIENT_CODE, agent_code: selectQuote.AGENT_CODE, legacy_policy_sequence: selectQuote.LEGACY_POLICY_SEQUENCE, module: this.appuser.role }) : this.service.getFireItems(selectQuote.POLICY_SEQUENCE))
      .toPromise()
      .then((response: any) => {
        if (response && response['responseData']) {

          // populate building items
          if (response['responseData'].buildingArray && response.responseData.buildingArray.length) {
            this.buildItems = [];
            response.responseData.buildingArray.map((item: any, index: number) => {
              this.buildingTotal += item.item_si ? parseFloat(item.item_si) : 0

              this.buildItems.push({ name: item.item_desc.toString(), value: item.item_si.toString() })
            })
          }

          // populate content items
          if (response['responseData'].contentArray && response.responseData.contentArray.length) {
            this.contentItems = [];
            response.responseData.contentArray.map((item: any, index: number) => {
              this.contentTotal += item.item_si ? parseFloat(item.item_si) : 0
              this.contentItems.push({ name: item.item_desc.toString(), value: item.item_si.toString() })
            })
          }
        }
      })
  }

  ngOnInit() {
    let selectedQuote = history.state.editableQuote;

    //check to see if the state was passed for renewal
    if (history.state.hasOwnProperty('renewal'))
      this.renewal = history.state.renewal;

    this.buildItems.push({ name: '', value: '' });
    this.contentItems.push({ name: '', value: '' });

    if (selectedQuote)
      this.searchCustomerCtrl = new FormControl({ value: selectedQuote.CLIENT_CODE ? selectedQuote.CLIENT_CODE : null, disabled: true });

    else if (this.appuser && this.appuser.role == 'client') {

      this.quote = {
        ... this.quote,
        customer_code: this.appuser.code,
        client_code: this.appuser.code.toString(),
        customer_first_name: this.appuser.first_name,
        customer_last_name: this.appuser.last_name,
        customer_phone: this.appuser.phone,
        customer_email: this.appuser.email,
        date_of_birth: this.service.strDateToDBFormat(this.appuser.dob)
      }

      this.searchCustomerCtrl = new FormControl({ value: this.quote.customer_code, disabled: true });
    }

    this.selectedMethod = 'Mobile';
    this.showCurrencies();

    //load products
    //this.onLoadProducts();

    //load construction types
    this.onLoadConstructionTypes();

    //preselect end date
    this.onDurationSelected();

    //on Customer Filter
    if (!selectedQuote)
      this.onFilterCustomer();
    else {
      //update editable quotes
      this.populateEditableQuote(selectedQuote)
      this.quote.edit_mode = true;
    }


    //get google locations
    this.getUserLatLng();

  }

  addBuildRow() {
    let { name, value } = this.buildItems[this.buildItems.length - 1];

    //check and prevent adding new row when the total items exceeds the sum insured
    let total: number = 0;
    let building_sum = this.quote.building_sum ? parseFloat(this.quote.building_sum) : 0;
    for (let i = 0; i < this.buildItems.length; i++)
      total += this.buildItems[i].value ? parseFloat(this.buildItems[i].value) : 0;

    if (name != '' && value != '' && total < building_sum)
      this.buildItems.push({ name: '', value: '' });
    else if (name != '' && value != '' && total >= building_sum)
      this.alertSnackBar("Total sum of Items cannot exceeed Building Sum Insured Value", "Building Sum Limit");
  }
  deleteBuildRow(index) {
    if (this.buildItems.length == 1) {
      return false;
    } else {
      this.buildItems.splice(index, 1);
      return true;
    }
  }
  addContentRow() {
    let { name, value } = this.contentItems[this.contentItems.length - 1];

    //check and prevent adding new row when the total items exceeds the sum insured
    let total: number = 0;
    let content_sum = this.quote.content_sum ? parseFloat(this.quote.content_sum) : 0;
    for (let i = 0; i < this.contentItems.length; i++)
      total += this.contentItems[i].value ? parseFloat(this.contentItems[i].value) : 0;

    if (name != '' && value != '' && total < content_sum)
      this.contentItems.push({ name: '', value: '' });
    else if (name != '' && value != '' && total >= content_sum)
      this.alertSnackBar("Total sum of Items cannot exceeed Content Sum Insured Value", "Contents Sum Limit");
  }
  deleteContentRow(index) {
    if (this.contentItems.length == 1) {
      return false;
    } else {
      this.contentItems.splice(index, 1);
      return true;
    }
  }
  //Start Date dateFilters
  startDateFilter = (date: Date) => {
    return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days') >= 0;
  }
  //End Date dateFilters
  endDateFilter = (date: Date) => {
    let diff = moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.quote.start_date).format('YYYY-MM-DD'), 'days');
    return diff > 0 && diff <= 364;
  }
  onStartDateChanged() {
    let start_date = this.quote.start_date ? moment(this.quote.start_date).format('YYYY-MM-DD') : null;

    let days = parseInt(this.quote.days);

    if (days >= 365)
      days = 364;

    let end_date = moment(start_date).add(!isNaN(+`${days}`) ? days : 1, 'days').format('YYYY-MM-DD');

    this.quote.end_date = end_date;


    if (this.quote.start_date && this.quote.end_date) {
      if (this.quote.term == '01')
        this.quote.days = '365';
      else {
        this.quote.days = this.quote._days = moment(end_date).diff(start_date, 'days').toString();
        if (this.quote.term == '02')
          this.quote.days = this.quote._months = Math.round(moment(end_date).diff(start_date, 'days') / 31);
      }
    }
  }
  onEndDateChanged(date: Date) {
    let start_date = this.quote.start_date ? moment(this.quote.start_date).format('YYYY-MM-DD') : null;

    let days = 0, months = 0;
    if (start_date) {
      if (this.quote.term == '02') {
        months = Math.round(moment(moment(date).format('YYYY-MM-DD')).add(1, 'days').diff(start_date, 'days') / 31);
        this.quote.days = this.quote._months = this.quote._months = months;

        if (this.quote.term == '02' && months == 0) {
          this.quote.term = '03';
          this.quote.days_label = "Days"
        }
      }
      if (this.quote.term != '02') {
        days = moment(moment(date).format('YYYY-MM-DD')).diff(start_date, 'days');
        this.quote.days = days.toString();
      }
    }

    if (days && days < 365 && this.quote.term == '01') {
      months = moment(moment(date).format('YYYY-MM-DD')).diff(start_date, 'months');

      if (months) {
        this.quote.days_label = "Months";
        this.quote.term = '02';
        this.quote.days = months;
      }
      else {
        this.quote.days_label = "Days";
        this.quote.term = '03';
      }
    }
    else if (days == 365)
      this.quote.term = '01';

  }
  //on term selection
  onDurationSelected() {

    //Get the associated days to the term
    let durations = this.terms.filter((duration: Term) => duration.value == this.quote.term);

    if (durations.length > 0)
      this.quote.days = durations[0].days;

    //recalculate the end date
    if (!isNaN(+`${this.quote.days}`) && this.quote.start_date)
      this.quote.end_date = moment(this.quote.start_date).subtract((this.quote.term == '02' ? 1 : 0), 'days').add(parseInt(this.quote.days) >= 364 ? 364 : parseInt(this.quote.days), (this.quote.term == '02' ? 'months' : 'days')).format('YYYY-MM-DD');

    //change the days label to months when the term changes to short term
    if (this.quote.term == '02') {
      this.quote.days_label = 'Months';
      let months = Math.round(Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'), 'days')) / 31);
      this.quote._months = this.quote.days = months ? months : 1;

      let days = Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
    }
    else {
      this.quote.days_label = 'Days';
      let days = this.quote.days = Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
      this.quote._months = '';
    }
    if (this.quote.term == '01' && this.quote.days != '' && parseInt(this.quote.days) >= 364)
      this.quote.days = 365;

  }
  showCurrencies() {
    this.service.getCurrencies()
      .toPromise()
      .then((response: CurrencyResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((currency: Currency) => {
            this.currencies.push(currency);
          })
        }
      }).catch(err => {
        // console.log('Currencies load error',err);
      });
  }
  onCurrencySelected(code: string) {
    let selectedCurrency = this.currencies.filter((currency: Currency) => currency.CURRENCY == code);

    if (selectedCurrency.length) {
      this.quote.exchange_rate = selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code = code;
    }
  }


  //autocomplete search customer
  onFilterCustomer() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCustomers = [];
          this.isCustomerLoading = true;
        }),
        switchMap(value => {
          if (value && value.length) {

            if (this.appuser.role == "agent") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                agent_code: this.appuser.code,
                search_parameter: value
              }

            } else if (this.appuser.role == "broker-user") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                broker_user_code: this.appuser.code,
                search_parameter: value
              }
            }

            console.log("filterClientPayload>>>>>>" + this.filterClientPayload)
            return this.service.searchCustomer(this.filterClientPayload)
              .pipe(
                finalize(() => {
                  this.isCustomerLoading = false
                }),
              )
          } else {
            this.isCustomerLoading = false
            return new Observable<[]>();
          }


        }
        )
      )
      .subscribe((response: CustomerResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.profile.map(r => {

            r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);

            let name_parts = [];
            if (r.CLIENT_FIRST_NAME)
              name_parts.push(r.CLIENT_FIRST_NAME);

            if (r.CLIENT_FAMILY_NAME)
              name_parts.push(r.CLIENT_FAMILY_NAME);

            if (r.CLIENT_HOME_MOBILE)
              name_parts.push(r.CLIENT_HOME_MOBILE);

            //if(r.CLIENT_CODE)
            //name_parts.push(`(${r.CLIENT_CODE})`);

            r.DISPLAY_NAME = name_parts.join(',');

            /*  let temp=name_parts[0];
             name_parts[0]=name_parts[name_parts.length-1];
             name_parts[name_parts.length-1]=temp;

             r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
            r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;

            r.isNewCustomer = false;


            this.filteredCustomers.push(r);
          })

        }
      });
  }





  //selected selected customer
  onSelectCustomer(customer: CustomerProfile) {
    this.errors = { ...this.errors, customer_code: false };

    this.selectedCustomer = customer;
    this.quote.customer_code = this.selectedCustomer.CLIENT_CODE
    this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME ? this.selectedCustomer.CLIENT_FIRST_NAME : this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
    this.quote.customer_email = this.selectedCustomer.CLIENT_HOME_EMAIL
    this.quote.date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    this.quote.customer_type_code = this.selectedCustomer.CLIENT_TYPE
  }

  //customer change event
  onCustomerChange(event) {
    if (!event.target.value) {
      this.selectedCustomer = {
        CLIENT_CODE: "",
        CLIENT_FAMILY_NAME: "",
        CLIENT_HOME_MOBILE: "",
        CLIENT_FIRST_NAME: "",
        CLIENT_HOME_EMAIL: "",
        CLIENT_DATE_OF_BIRTH: "",
        DISPLAY_NAME: "",
        DISPLAY_SHORT_NAME: "",
        isNewCustomer: false
      }
      this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME
      this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
      this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
      this.quote.customer_email = this.selectedCustomer.CLIENT_HOME_EMAIL
      this.quote.date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    }
  }
  //open customer creation
  openCustomerCreationDialog() {
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      disableClose: true,
      width: '800px',
      data: { newCustomerDetails: {} }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data') && result.data.hasOwnProperty('customer_code'))
        this.quote = {
          ...this.quote,
          customer_code: result.data.customer_code,
          type: result.data.type,
          customer_first_name: result.data.customer_first_name,
          customer_other_names: result.data.customer_other_names,
          customer_last_name: result.data.customer_last_name,
          date_of_birth: moment(result.data.date_of_birth).format('YYYY-MM-DD'),
          customer_email: result.data.customer_email,
          customer_phone: result.data.customer_phone,
          digital_address: result.data.digital_address,
          id_type: result.data.id_type,
          id_number: result.data.id_number,
          tin: result.data.tin
        }

      if (this.quote.type == '0')
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_last_name},${this.quote.customer_phone}`;
      else
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_phone}`;
    });
  }



  // onLoadProducts() {
  //   this.products = [];
  //   this.service.getProducts({ policy_lob: '10' })
  //     .toPromise()
  //     .then((response: ProductResponse) => {
  //       if (response.responseCode == '000') {
  //         this.products = response.profile
  //         this.service.swalAlart.close();
  //       }
  //     })
  //     .catch((error: any) => {
  //       this.service.swalAlart.close();
  //       //console.log('Error',error);
  //     })

  // }



  onLoadConstructionTypes() {
    this.products = [];
    this.service.getConstructionTypes()
      .toPromise()
      .then((response: ConstructionTypeResponse) => {
        if (response.responseCode == '000') {
          this.constructionTypes = response.responseData;
          this.roofTypes = response.responseData;
          this.service.swalAlart.close();
        }
      })
      .catch((error: any) => {
        this.service.swalAlart.close();
        console.log('Error', error);
      })
  }





  //additional cover changed
  onlossOfRentChanged(checked: boolean) {
    this.is_loss_of_rent = checked;
    if (checked) {
      this.quote.loss_of_rent = '10000.00'
    } else {
      this.quote.loss_of_rent = ''
    }

  }

  onAdditionalPAChanged(checked: boolean) {
    this.is_additional_pa = checked;
    if (checked) {
      this.quote.additional_pa_amt = '10000.00'
    } else {
      this.quote.additional_pa_amt = ''
    }

  }

  onAdditionalPADomesticEmployeesAmountChanged(changed: boolean) {
    this.is_additional_pa_domestic_employees = changed;
    this.quote.add_pa_empl_total = ''
    //this.quote.additional_pa_domestic_emp_amt = ''
  }

  onDomesticEmployeesNumberChanged(changed: boolean) {
    this.is_domestic_emp_no = changed;
    this.quote.domestic_emp_no = ''
    this.quote.add_pa_empl_total = ''
    //this.quote.additional_pa_domestic_emp_amt = ''
  }

  onAdditionalPAHouseholdMembersAmountChanged(changed: boolean) {
    this.is_additional_pa_household_member = changed;
    this.quote.add_pa_household_total = ''
    //this.quote.additional_pa_household_mem_amt = ''
  }

  onHouseholdMembersNumberChanged(changed: boolean) {
    this.is_household_mem_no = changed;
    this.quote.household_mem_no = ''
    this.quote.add_pa_household_total = ''
    //this.quote.additional_pa_household_mem_amt = ''
  }


  onPersonalAccidentChanged(changed: boolean) {
    this.is_person_accident = changed;
  }

  onPublicLiabilityChanged(checked: boolean) {
    this.is_public_liability = checked;
    if (checked) {
      this.quote.public_liability = '7500.00'
    } else {
      this.quote.public_liability = ''
    }

  }

  onBuildingSumInsuredChanged(changed: boolean) {
    this.is_building_sum_insured = changed;

    if (!this.is_building_sum_insured) {
      this.quote.building_sum = ''
      this.buildItems.length = 0
      this.buildItems.push({ value: '', name: '' })
    }
  }

  onContentSumInsuredChanged(changed: boolean) {
    this.is_content_sum_insured = changed;

    if (!this.is_content_sum_insured) {
      this.quote.content_sum = ''
      this.contentItems.length = 0
      this.contentItems.push({ value: '', name: '' })
    }

  }


  onCoverSelected(product_code: string) {
    console.log("building_sum>>>>" + this.quote.building_sum)

    this.selectedProduct = this.fireProducts.find((cover: fireProducts) => cover.PRODUCT_CODE == product_code);
    if (this.buildItems.length == 0) {
      this.quote.building_sum = '';
      this.buildItems.push({ value: '', name: '' })
    }

    if(product_code == '132'){
      this.quote.loss_of_rent = 0.00
      this.quote.public_liability = 5000.00
      this.quote.additional_pa_amt = 5000.00

    }else{
      this.quote.loss_of_rent = 10000.00
      this.quote.public_liability = 7500.00
      this.quote.additional_pa_amt = 10000.00
    }

  }

  onConstructionTypeSelected(construction_type: string) {
    this.selectedConstructionType = this.constructionTypes.find((construction: ConstructionType) => construction.TABLED_CODE == construction_type);
  }

  onRoofTypeSelected(roof_type: string) {
    this.selectedRoofType = this.roofTypes.find((roof: RoofType) => roof.TABLED_CODE == roof_type);
  }

  //On Form submit
  onSubmit = (event) => {
    event.preventDefault();

    let validationResponse = this.validateForm();
    if (validationResponse)
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      })
    else {

      if (this.checkfloodAreaBlacklist(this.quote.location)){
          this.floodAreaBlacklistAlert()
      }else{

      this.service.swalAlart.fire({
        title: "Do you want to proceed with quote?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {

            //start of processing
            this.processing = true;
            let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();

            this.payload = {
              customer_first_name: this.quote.customer_first_name,
              customer_last_name: this.quote.customer_last_name,
              customer_other_names: this.quote.customer_other_names,
              customer_email: this.quote.customer_email,
              customer_phone: this.quote.customer_phone,
              prepared_by: prepared_by,
              status: 'incomplete',
              module: this.appuser.role.toLowerCase(),
              status_of_client: (this.renewal ? 'renew' : 'new'),
              customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
              customer_branch_code: this.appuser.agency,
              customer_type_code: this.quote.customer_type_code,
              policy_lob: "13",
              platform: "Webapp",
              product: this.quote.cover_type_code,
              policy_agency: this.appuser.agency || '502',
              policy_office: this.appuser.agency,
              fire_gen_desc: this.quote.property_description,
              fire_location: this.quote.location.toString()
            }

         
            if (this.appuser.role == 'agent') {
              this.payload = {
                ...this.payload,
                agent_code: this.appuser.code.toString(),
                client_code: this.quote.customer_code.toString(),
                broker_user_code: '',
              }
            }
            else if (this.appuser.role == 'client') {
              this.payload = {
                ...this.payload,
                agent_code: '',
                client_code: this.appuser.code.toString(),
                customer_first_name: this.appuser.first_name,
                customer_last_name: this.appuser.last_name,
                customer_phone: this.appuser.phone,
                customer_email: this.appuser.email,
                date_of_birth: this.service.strDateToDBFormat(this.appuser.dob),
                broker_user_code: '',
              }
            } else if (this.appuser.role == 'broker-user') {
              this.payload = {
                ...this.payload,
                agent_code: this.appuser.broker_user_main_broker_code.toString(),
                client_code: this.quote.customer_code.toString(),
                broker_user_code: this.appuser.code.toString(),
              }
            }


            this.service.swalAlart.showLoading();

            if (!this.renewal && this.quote.edit_mode)
              this.finalizeQuote(this.quote.policy_no)
            else
              this.service.submitFireQuote(this.payload)
                .toPromise()
                .then((response: any) => {
                  if (response.responseCode == '000') {
                    this.service.swalAlart.close();
                    this.finalizeQuote(response.responseData)
                  }
                  else if (response.responseCode == 'E56')
                    this.service.swalAlart.fire({
                      title: 'Error',
                      text: "Sorry, we couldn't find any branch associated with this account. Kindly contact your branch manager.",
                      icon: 'error',
                    });
                  else if (response.responseCode == 'E57')
                    this.service.swalAlart.fire({
                      title: 'Error',
                      text: "Sorry, you cannot renew policy at this branch,kindly contact your branch office",
                      icon: 'error',
                    });
                }).catch(error => {
                  this.processing = false;
                  this.service.swalAlart.close();
                  this.service.swalAlart.fire({
                    title: 'Error',
                    text: 'Server Error,retry later',
                    icon: 'error',
                  });
                })
          }
        })
      }

    }


  }



  //confirm login pin to continue
  confirmPin() {
    let currentUser = this.service.currentUser();
    if (currentUser.role == "broker-user") {
      this.loginAsBrokerUser()
    } else {
      this.loginAsAgentClient()
    }
  }

  loginAsAgentClient() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          if (currentUser.role == "client") {
            loginPayload = {
              phone: currentUser.phone,
              pin: result.value,
              module: 'client'
            }

          } else if (currentUser.role == "agent") {
            loginPayload = {
              agent_code: currentUser.code,
              pin: result.value,
              module: 'agent'
            }

          } else { }

          swal.showLoading()
          this.service.webLogin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });

  }

  loginAsBrokerUser() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_user_code: currentUser.code,
            broker_user_pin: result.value,
            module: 'broker-user'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerUser(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }







  //choose payment method
  choosePaymentMethod() {
    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, method: '', total_premium: this.quote.total_premium, policy_lob: "13" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.selectedMethod = result.data.method;

        if (this.selectedMethod)
          this.confirmPin();

        this.validateForm();
      }
    });
  }


  //open momo Payment Dialog
  openMomoPaymentDialog() {
    const dialogRef = this.dialog.open(MomoDebitCardPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.payload, total_premium: this.quote.total_premium, policy_lob: "13" }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data')) {

        if (result.data.hasOwnProperty('quotePayload'))
          this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }

    });
  }



  //open cash Payment Dialog
  openCashPaymentDialog() {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, total_premium: this.quote.total_premium, policy_lob: "13" }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }

    });
  }
  //open Cheque Payment Dialog
  openChequePaymentDialog() {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, total_premium: this.quote.total_premium, policy_lob: "13" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }
    });
  }



  finalizeQuote = (policy_no) => {
    this.payload = {
      prepared_by: this.payload.prepared_by,
      policy_sequence: policy_no,
      module: this.appuser.role,
      agent_code: this.payload.agent_code,
      client_code: this.payload.client_code,
      status_of_client: this.renewal ? 'renew' : "new",
      currency_code: this.quote.currency_code,
      fleet: null,
      exchange_rate: parseFloat(this.quote.exchange_rate).toFixed(2).toString(),

      customer_first_name: this.quote.customer_first_name,
      customer_last_name: this.quote.customer_last_name,
      customer_other_names: "",

      customer_email: this.quote.customer_email,

      customer_phone: this.quote.customer_phone,
      customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
      customer_branch_code: this.appuser.agency,
      customer_type_code: this.quote.customer_type_code,

      inception_date: moment(this.quote.start_date).format('DD/MM/YYYY'),
      expiry_date: moment(this.quote.end_date).format('DD/MM/YYYY'),

      product: this.quote.cover_type_code,

      policy_lob: "13",

      building_sum_insured: (this.quote.cover_type_code == '131' || this.quote.cover_type_code == '132') && this.quote.building_sum ? this.quote.building_sum.toString() : 0,
      content_sum_insured: (this.quote.cover_type_code == '131' || this.quote.cover_type_code == '132') && this.quote.content_sum ? this.quote.content_sum.toString() : 0,
      loss_of_rent_value: this.quote.loss_of_rent ? parseFloat(this.quote.loss_of_rent) : 0.00,
      additional_pa_amt: this.quote.additional_pa_amt ? parseFloat(this.quote.additional_pa_amt) : 0.00,
      additional_pa_domestic_emp_amt: this.quote.additional_pa_domestic_emp_amt ? parseFloat(this.quote.additional_pa_domestic_emp_amt) : 2000,
      add_pa_empl_total: this.quote.add_pa_empl_total ? parseFloat(this.quote.add_pa_empl_total) : 0.00,
      domestic_emp_no: this.quote.domestic_emp_no ? parseInt(this.quote.domestic_emp_no) : 0,

      additional_pa_household_emp_amt: this.quote.additional_pa_household_mem_amt ? parseFloat(this.quote.additional_pa_household_mem_amt) : 2000,

      add_pa_household_total: this.quote.add_pa_household_total ? parseFloat(this.quote.add_pa_household_total) : 0.00,
      household_emp_no: this.quote.household_mem_no ? parseInt(this.quote.household_mem_no) : 0,

      excess_type_code: (this.quote.excess_buy_back ? 'EIB' : 'ENA'),
      tppd_limit: this.quote.tppd_value ? this.quote.tppd_value : "0",
      legacy_policy_sequence: this.quote.legacy_policy_number,

      intermediary_first_name: this.appuser.first_name ? this.appuser.first_name : '',
      intermediary_last_name: this.appuser.last_name ? this.appuser.last_name : '',
      intermediary_other_names: "",
      intermediary_phone: this.appuser.phone,
      intermediary_email: this.appuser.email,

      platform: "Webapp",

      intermediary_type_code: "04",
      intermediary_branch_code: "502",


      policy_agency: this.appuser.agency || '502',
      policy_office: this.appuser.agency,

      additional_pl: this.quote.public_liability ? parseFloat(this.quote.public_liability) : 0.00,
      fire_general_description: this.quote.property_description,
      buildingArray: this.buildingTotal ? this.buildingArray() : [],
      contentArray: this.contentTotal ? this.contentArray() : [],
      fire_construct_type: this.selectedConstructionType.TABLED_DESIGNATION,
      fire_construct_code: this.selectedConstructionType.TABLED_CODE,
      roof_type_desc: this.selectedRoofType.TABLED_DESIGNATION,
      roof_type_code: this.selectedRoofType.TABLED_CODE,
      fire_location: this.quote.location,
      digital_address: this.quote.digital_address,
      fire_gen_desc: this.quote.property_description
    }

    if (this.appuser.role == 'agent') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.customer_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.payload = {
        ...this.payload,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        customer_first_name: this.appuser.first_name,
        customer_last_name: this.appuser.last_name,
        customer_phone: this.appuser.phone,
        customer_email: this.appuser.email,
        date_of_birth: this.service.strDateToDBFormat(this.appuser.dob),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.customer_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }
    }

    //end lagacy values for renewals
    if (this.quote.days_label.toLowerCase() == 'months' || this.quote.days_label.toLowerCase() == 'month(s)')
      this.payload = {
        ...this.payload,
        months: this.quote.days
      }
    else
      this.payload = {
        ...this.payload,
        days: this.quote.days
      }


    if (this.quote.term == '01')
      this.payload.duration_type = 'annual';
    else if (this.quote.term == '02')
      this.payload.duration_type = 'shortrate';


    this.service.swalAlart.showLoading();
    this.service.submitFireQuote(this.payload)
      .toPromise()
      .then((response: any) => {
        this.quote.total_premium = response.responseConvertedValue ? response.responseConvertedValue.toString().replace(",", "").trim() : '0';
        this.quote.foreign_currency = response.responseCurrencyValue;
        this.quote.foreign_amount = response.responseData.toString().replace(",", "").trim();

        this.confirmQuote();
        this.service.swalAlart.close();
        this.processing = false;

        console.log("building_sum_insured>>>>>>" + this.payload.building_sum_insured)
      }).
      catch(error => {
        console.log("error>>>>>>>>" + error)
        this.processing = false;
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Error',
          text: error,
          icon: 'error',
        });
      })
  }

  //building array
  buildingArray = () => {
    let items = [];
    for (let i = 0; i < this.buildItems.length; i++) {
      if (this.buildItems[i].name && this.buildItems[i].value)
        items.push({ item_no: (i + 1), item_desc: this.buildItems[i].name, item_si: this.buildItems[i].value })
    }
    return items;
  }
  //content array
  contentArray = () => {
    let items = [];
    for (let i = 0; i < this.contentItems.length; i++) {
      if (this.contentItems[i].name && this.contentItems[i].value)
        items.push({ item_no: (i + 1), item_desc: this.contentItems[i].name, item_si: this.contentItems[i].value })
    }
    return items;
  }
  //run validation
  validateForm(input?: any): boolean {
    let hasError = false;
    this.errors = {
      customer_code: false,
      customer_phone: false,
      date_of_birth: false,

      cover_type_code: false,
      schedule_code: false,
      start_date: false,
      end_date: false,

      currency_code: false,
      customer_email: false,
      loss_of_rent: false,
      additional_pa_amt: false,
      additional_pa_domestic_emp_amt: false,
      domestic_emp_no: false,
      additional_pa_household_mem_amt: false,
      household_mem_no: false,
    }


    if (!this.quote.customer_code) {
      hasError = true;
      this.errors = { ...this.errors, customer_code: true };
    }
    if (isNaN(+`${this.quote.customer_phone}`) || !this.service.validatePhone(this.quote.customer_phone)) {
      hasError = true;
      this.errors = { ...this.errors, customer_phone: true };
    }
    if (!this.quote.currency_code || (this.quote.currency_code && this.quote.currency_code.trim().length == 0)) {
      hasError = true;
      this.errors = { ...this.errors, currency_code: true };
    }

    if (!this.quote.cover_type_code) {
      hasError = true;
      this.errors = { ...this.errors, cover_type_code: true };
    }

    if (!this.quote.construction_type) {
      hasError = true;
      this.errors = { ...this.errors, construction_type: true };
    }

    if (!this.quote.roof_type) {
      hasError = true;
      this.errors = { ...this.errors, roof_type: true };
    }

    if (!this.quote.property_description) {
      hasError = true;
      this.errors = { ...this.errors, property_description: true };
    }

    if (this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)) {
      hasError = true;
      this.errors = { ...this.errors, customer_email: true };
    }

    if (!this.quote.location) {
      hasError = true;
      this.errors = { ...this.errors, location: true };
    }

    if (!(moment(this.quote.date_of_birth).isValid() && moment(moment().format('YYYY-MM-DD')).diff(moment(this.quote.date_of_birth).format('YYYY-MM-DD'), 'years') >= 18)) {
      hasError = true;
      this.errors = { ...this.errors, date_of_birth: true };

      this.alertSnackBar(`Date of birth must be greater or equal to:${moment(this.dobStartAt).format('LL')}`, 'Invalid Date');
    }

    if (!(moment(this.quote.start_date).isValid())) {
      hasError = true;
      this.errors = { ...this.errors, start_date: true };
    }

    if (!(moment(this.quote.end_date).isValid())) {
      hasError = true;
      this.errors = { ...this.errors, end_date: true };
    }

    if (!this.quote.location) {
      hasError = true;
      this.errors = { ...this.errors, location: true };
    }

    if (!this.quote.construction_type) {
      hasError = true;
      this.errors = { ...this.errors, construction_type: true };
    }

    if (!this.quote.roof_type) {
      hasError = true;
      this.errors = { ...this.errors, roof_type: true };
    }

    if (this.quote.property_description && !this.quote.property_description) {
      hasError = true;
      this.errors = { ...this.errors, property_description: true };
    }

    if (this.quote.start_date && !this.quote.start_date) {
      hasError = true;
      this.errors = { ...this.errors, start_date: true };
    }

    if (this.quote.end_date && !this.quote.end_date) {
      hasError = true;
      this.errors = { ...this.errors, end_date: true };
    }

    if (this.quote.content_sum && this.contentTotal && this.contentTotal.toString() != this.quote.content_sum) {
      hasError = true;
      this.errors = { ...this.errors, content_sum: true, content_items: true };
      this.alertSnackBar("Total sum of content items should be equal to the sum insured.", "Content Items Validation");
    }


    if (this.quote.building_sum && this.buildingTotal && this.buildingTotal.toString() != this.quote.building_sum) {
      hasError = true;
      this.errors = { ...this.errors, building_sum: true, building_items: true };

      this.alertSnackBar("Total Building Items Total must be equal to Building Sum Insured.", "Building Items Validation");
    }


    if ((this.quote.cover_type_code == '101' || this.quote.cover_type_code == '131') && this.quote.lost_of_rent && this.quote.building_sum && parseFloat(this.quote.lost_of_rent) > (0.1 * parseFloat(this.quote.building_sum))) {
      this.errors = { ...this.errors, building_sum: true, loss_of_rent: true };
      this.alertSnackBar(`Value for Loss of Rent cannot be more than 10% of the building value of ${this.quote.building_sum}`, "Lost of Rent Validation");

    }


    return hasError;
  }
  //trigger the snackbar
  alertSnackBar(message: string, action: string, duration: number = 5000) {
    this._snackBar.open(message, action, {
      duration: duration,
    });
  }

  //on lost of rent input
  lost_of_rent_change($event) {
    this.quote.lost_of_rent = $event.target.value;
    if ((this.quote.cover_type_code == '101' || this.quote.cover_type_code == '131') && this.quote.lost_of_rent && this.quote.building_sum && parseFloat(this.quote.lost_of_rent) > (0.1 * parseFloat(this.quote.building_sum))) {
      this.alertSnackBar(`Value for Loss of Rent cannot be more than 10% of the building value of ${this.quote.building_sum}`, "Lost of Rent Validation", 2000);
      this.errors = { ...this.errors, building_sum: true, loss_of_rent: true };
    }
  }

  //prevent under age
  dobFilter(date: Date) {
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'), 'years') >= 18;
  }
  //confirm quote
  confirmQuote() {
    let summary = []

    if(this.quote.cover_type_code=='131'){
      summary = [
        { label: 'Date Prepared', value: moment().format('DD/MM/YYYY') },
        { label: 'Prepared By', value: this.payload.prepared_by },
        { label: 'Customer Name', value: `${this.payload.customer_first_name} ${this.payload.customer_last_name}` },
        { label: 'Customer Email', value: this.payload.customer_email },
        { label: 'Customer Code', value: this.payload.client_code },
        { label: 'Mobile Number', value: this.payload.customer_phone },
        { label: 'Product Plan', value: this.selectedProduct.PRODUCT_DESC },
        { label: 'Location of Building/Proprty', value: this.payload.fire_location },
        { label: 'Digital Address of Building/Proprty', value: this.payload.digital_address },
        { label: 'Construction Type', value: this.payload.fire_construct_type },
        { label: 'Roof Type', value: this.payload.roof_type_desc },
        { label: 'Duration', value: (this.payload.duration_type.toLowerCase() == 'annual' ? 'One Year(365)' : this.payload.duration_type) },
        { label: 'Building Sum Insured', value: (this.payload.building_sum_insured ? this.payload.building_sum_insured : 0) },
        { label: 'Content Sum Insured', value: this.payload.content_sum_insured ? this.payload.content_sum_insured : 0 },
        { label: 'Loss of Rent', value: this.payload.loss_of_rent_value },
        { label: 'Public Liability', value: this.payload.additional_pl },
        { label: 'Additional PA', value: this.payload.additional_pa_amt },
        { label: 'Total Premium for all domestic employees', value: this.payload.add_pa_empl_total },
        { label: 'Number of Domestic Employees', value: this.payload.domestic_emp_no },
        { label: 'Total Premium for all household members', value: this.payload.add_pa_household_total },
        { label: 'Number of Household Members', value: this.payload.household_emp_no },
        { label: 'Excess on Building', value: '10% each and every loss' },
        { label: 'Excess on Content', value: '10% each and every loss' },
        { label: 'Start Date', value: this.payload.inception_date },
        { label: 'End Date', value: this.payload.expiry_date }
      ];
      
    }else{
      summary = [
        { label: 'Date Prepared', value: moment().format('DD/MM/YYYY') },
        { label: 'Prepared By', value: this.payload.prepared_by },
        { label: 'Customer Name', value: `${this.payload.customer_first_name} ${this.payload.customer_last_name}` },
        { label: 'Customer Email', value: this.payload.customer_email },
        { label: 'Customer Code', value: this.payload.client_code },
        { label: 'Mobile Number', value: this.payload.customer_phone },
        { label: 'Product Plan', value: this.selectedProduct.PRODUCT_DESC },
        { label: 'Location of Building/Proprty', value: this.payload.fire_location },
        { label: 'Digital Address of Building/Proprty', value: this.payload.digital_address },
        { label: 'Construction Type', value: this.payload.fire_construct_type },
        { label: 'Roof Type', value: this.payload.roof_type_desc },
        { label: 'Duration', value: (this.payload.duration_type.toLowerCase() == 'annual' ? 'One Year(365)' : this.payload.duration_type) },
        { label: 'Building Sum Insured', value: (this.payload.building_sum_insured ? this.payload.building_sum_insured : 0) },
        { label: 'Content Sum Insured', value: this.payload.content_sum_insured ? this.payload.content_sum_insured : 0 },
        { label: 'Public Liability', value: this.payload.additional_pl },
        { label: 'Additional PA', value: this.payload.additional_pa_amt },
        { label: 'Total Premium for all shop attendants', value: this.payload.add_pa_empl_total },
        { label: 'Number of Shop Attendants', value: this.payload.domestic_emp_no },
        { label: 'Excess on Building', value: '10% each and every loss' },
        { label: 'Excess on Content', value: '10% each and every loss' },
        { label: 'Start Date', value: this.payload.inception_date },
        { label: 'End Date', value: this.payload.expiry_date }
      ];
    }
   

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        quote: summary,
        confirmedQuote: true,
        total_premium: this.quote.total_premium,
        foreign_currency: this.quote.foreign_currency,
        foreign_amount: this.quote.foreign_amount,
        flow: 'fire',
        hide_foreign_currency: 0
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        if (this.confirmedQuote)
          this.choosePaymentMethod();
      }
    });
  }





  //on building item value typed
  buildingTotal: number = 0;
  onBuildingItem(index: number, $event) {
    this.buildingTotal = 0;
    let building_sum = this.quote.building_sum ? parseFloat(this.quote.building_sum) : 0;
    for (let i = 0; i < this.buildItems.length; i++)
      this.buildingTotal += this.buildItems[i].value ? parseFloat(this.buildItems[i].value) : 0;

    if (this.buildingTotal > building_sum)
      this.buildItems[this.buildItems.length - 1].value = '';
  }
  //on building item value typed
  contentTotal: number = 0;
  onContentItem(index: number, $event) {
    this.contentTotal = 0;
    let content_sum = this.quote.content_sum ? parseFloat(this.quote.content_sum) : 0;
    for (let i = 0; i < this.contentItems.length; i++)
      this.contentTotal += this.contentItems[i].value ? parseFloat(this.contentItems[i].value) : 0;

    if (this.contentTotal > content_sum)
      this.contentItems[this.contentItems.length - 1].value = '';
  }




  //Fetch user's Lat and Lng From google geolocation
  getUserLatLng() {
    this.service.getUserLatLng()
      .toPromise()
      .then((response: any) => {
        console.log("country code res --- " + JSON.stringify(response));

        this.latlng.lat = response.location.lat;
        this.latlng.lng = response.location.lng;
        console.log(this.latlng);

        this.getCountryCode(this.latlng.lat, this.latlng.lng);

      }
      )
      .catch(error => {
        error => {
          console.log(' ------ error caught in service ----');
          let errorCode = error.status;
          let errorResponse = JSON.stringify(error.error);

          if (errorCode === 400 && errorResponse.indexOf("ResponseMessage")) {
            console.error("Error Response: " + errorResponse);

            let errorobj = JSON.parse(errorResponse);

            if (errorobj.responseCode === "E01") {
              console.error('Error: ' + errorobj.responseMessage);
            } else {
              console.error('Error ' + error.message);
            }

          } else if (errorCode === 401) {
            console.error("Error Response: " + errorResponse);
            let errorobj = JSON.parse(errorResponse);

            console.error('Error ' + error.message);
          }

          return throwError(error);
        }

      })
      .finally(() => {
        //remove googleAPI from storage after response
        localStorage.removeItem('googleAPI')
      })
  }

  //fetch user's country code from google geocoding
  getCountryCode(latitude: string, longitude: string) {
    console.log("In code get methd");
    this.service.getUserCountryCode(latitude, longitude)
      .toPromise()
      .then((response: any) => {

        if (response.status === 'OK') {

          console.log("getUserCountryCode>>>>>>>>>\n" + JSON.stringify(response.results[0].address_components[0].short_name));

          this.countryCode = response.results[0].address_components[0].short_name;
          console.log("Country Code from Google - " + this.countryCode);

          this.options.componentRestrictions.country = this.countryCode;

          console.log(this.options);

          // //Initialize Company form
          // this.initializeCompanyForm();
          // console.log("Initialized form"); 


        } else {

          console.log('Error ' + response.responseMessage);
        }
      }
      )
      .catch(error => {
        error => {
          console.log(' ------ error caught in service ----');
          let errorCode = error.status;
          let errorResponse = JSON.stringify(error.error);

          if (errorCode === 400 && errorResponse.indexOf("ResponseMessage")) {
            console.error("Error Response: " + errorResponse);

            let errorobj = JSON.parse(errorResponse);

            if (errorobj.responseCode === "E01") {
              console.error('Error: ' + errorobj.responseMessage);
            } else {
              console.error('Error ' + error.message);
            }

          } else if (errorCode === 401) {
            console.error("Error Response: " + errorResponse);
            let errorobj = JSON.parse(errorResponse);

            console.error('Error ' + error.message);
          }

          return throwError(error);
        }

      })
      .finally(() => {
        //remove googleAPI from storage after response
        localStorage.removeItem('googleAPI')
      })

  }



  onLossOfRentInputChange($event) {
    let lossOfRentValueNow = this.quote.loss_of_rent
    let lossOfRentCapLimit = 10000.00

    if (lossOfRentValueNow > lossOfRentCapLimit) {
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Amount for loss of rent cannot exceed GHS 10,000.00 limit',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((dialogResponse) => {
        if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
          this.quote.loss_of_rent = ''
        }
      })

    }

  }


  onPublicLiabilityInputChange($event) {
    let publicLiabilityValueNow = this.quote.public_liability
    let publicLiabilityCapLimit = 7500.00

    if (publicLiabilityValueNow > publicLiabilityCapLimit) {
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Amount for public liability cannot exceed GHS 7,500.00 limit',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((dialogResponse) => {
        if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
          this.quote.public_liability = ''
        }
      })

    }

  }


  onDomesticEmpInputChange($event) {
    //   let domesticEmpValueNow = this.quote.additional_pa_domestic_emp_amt
    //   let domesticEmpCapLimit = 2000.00

    //   if(domesticEmpValueNow > domesticEmpCapLimit){
    //     this.service.swalAlart.fire({
    //       title: 'Validation Alert',
    //       text: 'Amount for each domestic staff cannot exceed GHS 2,000.00 limit',
    //       icon: 'warning',
    //       confirmButtonText: 'Ok',
    //       allowOutsideClick: false
    //     }).then((dialogResponse) => {
    //         if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
    //           this.quote.additional_pa_domestic_emp_amt = ''
    //       }
    // })

    // }

  }


  onHouseholdMemInputChange($event) {
    //   let householdMemValueNow = this.quote.additional_pa_household_mem_amt
    //   let householdMemCapLimit = 10000.00

    //   if(householdMemValueNow > householdMemCapLimit){
    //     this.service.swalAlart.fire({
    //       title: 'Validation Alert',
    //       text: 'Amount for each household member cannot exceed GHS 10,000.00 limit',
    //       icon: 'warning',
    //       confirmButtonText: 'Ok',
    //       allowOutsideClick: false
    //     }).then((dialogResponse) => {
    //         if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
    //           this.quote.additional_pa_household_mem_amt = ''
    //       }
    // })

    // }

  }


  onAdditionalPAInputChange($event) {
    let additionalPAValueNow = this.quote.additional_pa_amt
    let additionalPACapLimit = 10000.00

    if (additionalPAValueNow > additionalPACapLimit) {
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Amount for additional PA (Insured) cannot exceed GHS 10,000.00 limit',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((dialogResponse) => {
        if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
          this.quote.additional_pa_amt = ''
        }
      })

    }

  }

  NumberOfDomesticEmpInputChanged($event) {
    let capitalSum = 2000
    let premiumPerEmployee = 0.02 * capitalSum
    let totalEmployees = this.quote.domestic_emp_no
    let totalPremium = premiumPerEmployee * totalEmployees
    this.quote.add_pa_empl_total = totalPremium

  }

  NumberOfHouseHoldMemInputChanged($event) {
    let capitalSum = 2000
    let premiumPerMember = 0.02 * capitalSum
    let totalMembers = this.quote.household_mem_no
    let totalPremium = premiumPerMember * totalMembers
    this.quote.add_pa_household_total = totalPremium
    console.log("total premium>>>>>>" + totalPremium)
  }


  NumberOfShopAttendantsInputChanged($event) {
    let capitalSum = 2000
    let premiumPerMember = 0.02 * capitalSum
    let totalMembers = this.quote.household_mem_no
    let totalPremium = premiumPerMember * totalMembers
    this.quote.add_pa_household_total = totalPremium
    console.log("total premium>>>>>>" + totalPremium)
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  onBackClicked() {
    this.router.navigate(["/products"])
  }


  handleAddressChange(event: any) {
    this.quote.location = event.name + ", \n" + event.formatted_address
    console.log("fireLocation>>>>>" + this.quote.location.toString())

    if (this.checkfloodAreaBlacklist(event.name)){
        this.floodAreaBlacklistAlert()
    }
    //console.log(this.checkfloodAreaBlacklist(event.name))
   
  }

  checkfloodAreaBlacklist(locationName:any): boolean{
    for (let i = 0; i < this.floodAreaBlacklist.length; i++) {
      if (locationName.toLowerCase().indexOf(this.floodAreaBlacklist[i].toLocaleLowerCase()) !== -1) {
        return true
      }
    }

    return false
  }

  floodAreaBlacklistAlert() {
    Swal.fire({
      icon: 'info',
      title: 'Oops...',
      text: 'Sorry! You cannot insure your building/contents in the selected location.',
      showCloseButton: true,
      allowOutsideClick: false
    }).then((result) => {
      if(result){
        this.quote.location = ''
      }
    })
  }



}
