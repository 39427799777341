import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-finalize-retry-momo',
  templateUrl: './finalize-retry-momo.component.html',
  styleUrls: ['./finalize-retry-momo.component.scss']
})
export class FinalizeRetryMomoComponent implements OnInit {
  appuser: any;

  constructor(private service:DashboardService,public dialogRef: MatDialogRef<FinalizeRetryMomoComponent>,
    public dialogRetryRef: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private router:Router) {
      this.appuser = this.service.currentUser();
    }

  ngOnInit() {}
  processing:boolean=false;

   reQuery(){
    let payload: any = {}
    
     this.service.swalAlart.showLoading();
     this.processing=true;

    console.log("data->>> " + JSON.stringify(this.data))
    console.log("data->>> " + this.data.quotePayload.policy_lob)

    if(this.appuser.role=='agent'){
      payload={
        agent_code:this.appuser.code,
        client_code:this.data.quotePayload.client_code,
        module:'agent',
        transaction_token:this.data.quotePayload.transaction_token,
        policy_sequence:this.data.quotePayload.policy_sequence,
        platform:'webapp',
        policy_lob: this.data.quotePayload.policy_lob,
      }
    }else if(this.appuser.role=='broker-user'){
      payload={
        agent_code:this.appuser.broker_user_main_broker_code,
        broker_user_code:this.appuser.code,
        client_code:this.data.quotePayload.client_code,
        module:'broker-user',
        transaction_token:this.data.quotePayload.transaction_token,
        policy_sequence:this.data.quotePayload.policy_sequence,
        platform:'webapp',
        policy_lob: this.data.quotePayload.policy_lob,
      
      }
  
    }else if(this.appuser.role=='client'){
      payload={
        client_code:this.appuser.code,
        module:'client',
        transaction_token:this.data.quotePayload.transaction_token,
        policy_sequence:this.data.quotePayload.policy_sequence,
        platform:'webapp',
        policy_lob: this.data.quotePayload.policy_lob,
      }

    }else{}
   

    console.log("finalize-retry-momo-payment->>> " + JSON.stringify(payload))
    this.service.manualQuery(payload)
    .toPromise()
    .then((response:any)=>{
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
          this.service.swalAlart.close();

     
            console.log("policy_lob===> " + payload.policy_lob);
          
            if(payload.policy_lob === "20"){
              localStorage.removeItem('quotePayload')

              this.service.swalAlart.fire({
                title: 'Payment successful',
                html: response.hasOwnProperty('stickerUrl')? `SMS and Email sent with Policy details to print<br><br>Kindly print <a target='_blank' href='${response.stickerUrl}'>Sticker</a> and <a target='_blank' href='${response.certificateUrl}'>Certificate</a>`:`Payment completed successfully;your certificate has been mailed to you!<br><br/>Kindly click the <a target='_blank' href='${response.certificateUrl}'>Preview</a> link to view certificate`,
                icon: 'success',
                confirmButtonText:'Done',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              })
              .then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close()
              }
                
              });
            }else if (payload.policy_lob === "50"){
              localStorage.removeItem('quotePayload')

              this.service.swalAlart.fire({
                title: 'Payment successful',
                html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a><br><br><a target='_blank' href='${response.travelDocUrl}'>Click here to print <b>travel documents</b></a>`,
                icon: 'success',
                confirmButtonText:'Done',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result)=>{
                if(result){
                  this.router.navigate(['transactions']);
                  this.dialogRef.close();
                }
               });

            }else{
              localStorage.removeItem('quotePayload')

              this.service.swalAlart.fire({
                title: 'Payment successful',
                html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
                icon: 'success',
                confirmButtonText:'Done',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result)=>{
                if(result){
                  this.router.navigate(['transactions']);
                  this.dialogRef.close();
                }
               });
               
            }

        }else if (response.hasOwnProperty('responseCode') && response.responseCode=='001'){
          localStorage.removeItem('quotePayload')

          this.service.swalAlart.close();
          this.service.swalAlart.fire({
            title: 'Processing Payment...',
            html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
            icon: 'success',
            confirmButtonText:'Done',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close();
            }
           });

      } else if (response.hasOwnProperty('responseCode') && response.responseCode=='500'){
          this.service.swalAlart.close()
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Server error',
            icon: 'error',
          });
        }
        else{
          this.service.swalAlart.fire({
            title: 'Invoice not paid',
            text: 'Sorry, Invoice to this transaction has not been paid',
            icon: 'warning',
          });
        }
        this.processing=false
    })
    .catch((error)=>{
      this.processing=false;
      this.service.swalAlart.close();
      this.service.swalAlart.fire({
        title: 'Error',
        text: 'Server Error,retry later',
        icon: 'error',
      });
      this.processing=false
    })
  }


  closeDialog(){
    this.dialogRef.close()
    localStorage.removeItem('quotePayload')

    if(this.router.url.includes('/quotes')){
      this.router.navigate(['quotes']);

    }else if(this.router.url.includes('/fleet-motor-quote')){
      this.router.navigate(['fleet-motor-quote']);
    
    }else if(this.router.url.includes('/travelquote')){
    this.router.navigate(['travelquote']);
  
    }else if(this.router.url.includes('/firequote')){
      this.router.navigate(['firequote']);
    }

  }



  

}
