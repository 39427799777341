import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from '../../service/dashboard.service';

interface Method{
  value: string;
  icon: string;
  viewValue: string;
}

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit {
  constructor(public service: DashboardService, public dialogRef: MatDialogRef<SelectPaymentMethodComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.method = data.method;
      this.quotePayload = data.quotePayload;
      this.total_premium=data.total_premium;

      this.appUser=this.service.currentUser();

      if(this.appUser.role=='agent')
        this.methodData=[...this.methodData,{value:'Cheque',icon:'assets/img/payment/Cheque.svg',viewValue:"Cheque"} ,{value:'Cash',icon:'assets/img/payment/Cash.svg',viewValue:"Cash"} ];
      else{
        this.method='Mobile';
        this.closeDialog();
      }
    }

    method: string;
    methodData: Method[]= [{value:'Mobile',icon:'assets/img/payment/Debit card.svg',viewValue:"Mobile Money/Debit card"}];

    quotePayload:any;
    total_premium:0;
    appUser: any;

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {method: this.method,total_premium: this.total_premium} });
  }
}
