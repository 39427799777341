import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import { CashPaymentComponent } from '../dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from '../dialogs/cheque-payment/cheque-payment.component';
import { DebitCardPaymentComponent } from '../dialogs/debit-card-payment/debit-card-payment.component';
import * as moment from 'moment';

import swal, { SweetAlertResult } from 'sweetalert2';
import { MomoPaymentComponent } from '../dialogs/momo-payment/momo-payment.component';
import { FinalizeRetryMomoComponent } from '../dialogs/finalize-retry-momo/finalize-retry-momo.component';
@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  appuser;
  quotes: any = [];
  color: any = ''
  status;//declared a status value which wasnt part of the codes
  searchdata;
  selectedMethod = '';
  confirmedQuote: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  length: number = 0
  pageIndex: number = 0
  pageSize: number = 5
  pageSizeOptions = [5, 10, 20, 50, 100]
  pageChange = false;

  dataSource = new MatTableDataSource();
  displayedColumns = ['no', 'policy_sequence', 'customer_first_name', 'customer_phone', 'product_description', 'duration_type', 'total_premium', 'inception_date', 'expiry_date', 'date_created', 'link', 'edit', 'pay']
  
  paymentStatus: string;
  paymentChannel: string;

  quotePayloadBeforePayment:any


  constructor(
    private service: DashboardService,
     private router: Router,  
     private route: ActivatedRoute, 
     private dialog: MatDialog, 
     private _snackBar: MatSnackBar,
     public dialogRef: MatDialogRef<QuotesComponent>,
     public dialogRetryRef: MatDialog,
     
     ) {
    this.appuser = this.service.currentUser();
    this.getQuotes();
  }

  ngOnInit() { 
    try{
      this.route.paramMap.subscribe(
        params => {
            this.paymentChannel = params.get('payment-channel');
            //this.paymentStatus = params.get('payment-status');
        }
  
      );

    }catch(e){
     // console.log(e)
    }

    // console.log("payment-channel====>" + this.paymentChannel)
    // console.log("payment-status====>" + this.paymentStatus)


    if(this.paymentChannel=='ecobankpay'){
      this.quotePayloadBeforePayment = JSON.parse(localStorage.getItem('quotePayload'))
      console.log("quotePayloadBeforePayment====>" + this.quotePayloadBeforePayment.policy_sequence)

      this.transactionStatus()

    }





  }


  
  payload: any = {
    module: "agent",
    page_size: "5",
    current_page: "0"
  }

  getQuotes = () => {
    if (this.appuser.role === 'client') {
      this.payload = {
        ...this.payload,
        module: 'client',
        client_code: this.appuser.code
      }
    } else if (this.appuser.role === 'agent') {
      this.payload = {
        ...this.payload,
        module: 'agent',
        agent_code: this.appuser.code
      }
    } else if (this.appuser.role === 'broker-user') {
      this.payload = {
        ...this.payload,
        module: 'broker-user',
        broker_user_code: this.appuser.code
      }

    }else if(this.appuser.role==='broker-admin'){
      this.payload={
        ...this.payload,
        module:'agent',
        agent_code:this.appuser.broker_admin_main_code
      }

      this.displayedColumns = ['no', 'policy_sequence', 'customer_first_name', 'customer_phone', 'product_description', 'duration_type', 'total_premium', 'inception_date', 'expiry_date', 'date_created']

      
    } else { }


    this.service.swalAlart.showLoading();
    this.service.getquote(this.payload)
      .toPromise()
      .then((data: any) => {
        console.log("content>>>" + data.pageData.content)
        this.quotes =  data.pageData.content;
        this.dataSource.data =  data.pageData.content;
        this.pageSize = data.pageData.size;
        this.length =  data.pageData.totalElements;

        this.searchdata =  data.pageData.content;
        // this.dataSource.paginator = this.paginator;

        this.service.swalAlart.close();
      }).catch(error => {
        this.service.swalAlart.close();
      })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openFireSummary(selectedQuote) {
    let summary = [
      { label: 'Date Prepared',value: selectedQuote.date_updated },
      { label: 'Prepared By', value: selectedQuote.prepared_by },
      { label: 'Customer Name', value: `${selectedQuote.customer_first_name} ${selectedQuote.customer_last_name}` },
      { label: 'Customer Email', value: selectedQuote.customer_email },
      { label: 'Customer Code', value: selectedQuote.client_code },
      { label: 'Mobile Number', value: selectedQuote.customer_phone },
      { label: 'Product Plan', value: selectedQuote.product_description.product_description },
      { label: 'Location of Building/Proprty', value: selectedQuote.FIRE_LOCATION },
      { label: 'Construction Type', value: selectedQuote.FIRE_CONSTRUCT_TYPE_DESC },
      { label: 'Duration', value: (selectedQuote.duration_type == 'annual' ? 'One Year(365)' : selectedQuote.duration_type) },
      { label: 'Building Sum Insured', value: (selectedQuote.BUILDING_SUM_INSURED ? selectedQuote.BUILDING_SUM_INSURED : 0) },
      { label: 'Content Sum Insured', value: (selectedQuote.CONTENT_SUM_INSURED ? selectedQuote.CONTENT_SUM_INSURED : 0) },
      { label: 'Lost of Rent', value: selectedQuote.LOSS_OF_RENT_VALUE },
      { label: 'Public Liability', value: selectedQuote.FIRE_ADD_PL },
      { label: 'Start Date', value: selectedQuote.inception_date },
      { label: 'End Date', value: selectedQuote.expiry_date }
    ];

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'fire',
        hide_foreign_currency: 0,
        quote: summary,

        total_premium: selectedQuote.total_premium_cv,
        foreign_currency: selectedQuote.currency_code,
        foreign_amount: selectedQuote.total_premium,
        confirmedQuote: false
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }
  openTravelSummary(selectedQuote) {
    let summary = [
      { label: 'Date Prepared', value: selectedQuote.date_updated },
      { label: 'Prepared By', value: selectedQuote.prepared_by },
      { label: 'Customer Name', value: `${selectedQuote.customer_first_name} ${selectedQuote.customer_last_name}` },
      { label: 'Customer Email', value: selectedQuote.customer_email },
      { label: 'Customer Code', value: selectedQuote.client_code },
      { label: 'Mobile Number', value: selectedQuote.customer_phone },
      { label: 'ID Type', value: 'Passport' },
      { label: 'ID Number', value: selectedQuote.quote_objects[0].passport_num },
      { label: 'Plan Type', value: selectedQuote.product_description.product_description },
      //{label:'Coverage',value:''},
      { label: 'Destination', value: selectedQuote.quote_objects[0].destination_country },
      { label: 'Date of Departure', value: selectedQuote.inception_date },
      { label: 'Date of Return', value: selectedQuote.expiry_date }
    ]

    //console.log('summary',summary)
    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'travel',
        hide_foreign_currency: (selectedQuote.PRODUCT_DESCRIPTION == 'VANGUARD SAFE TRAVEL (VST)' ? 1 : 0),
        quote: summary,

        total_premium: selectedQuote.total_premium_cv,
        foreign_currency: selectedQuote.currency_code,
        foreign_amount: selectedQuote.total_premium,
        confirmedQuote: false
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }
  
  openQuoteSummaryDialog(policy_no: string) {
    const selectedPolicy = this.quotes.find((r: any) => r.policy_sequence === policy_no);
  
    localStorage.setItem('motor-quote', JSON.stringify(selectedPolicy))
    localStorage.setItem('quote_activity', 'edit')


    if(selectedPolicy.policy_lob == '20'){
      this.router.navigate(["/fleet-motor-quote"], {
        queryParams: {
          previous: 'quotes'
        },
        queryParamsHandling: 'merge',
        skipLocationChange: false
      });

    }else if(selectedPolicy.policy_lob == '50'){
      this.openTravelSummary(selectedPolicy);
    
    }else if(selectedPolicy.policy_lob == '10'){
      this.openFireSummary(selectedPolicy);

    }




    // const selectedQuote = this.quotes.find((r: any) => r.POLICY_SEQUENCE === policy_no);
    // console.log('selected Quote', selectedQuote)

    // if (selectedQuote.DURATION_TYPE == 'Travel Term')
    //   this.openTravelSummary(selectedQuote);
    // else if (selectedQuote.POLICY_LOB == '10')
    //   this.openFireSummary(selectedQuote);
    // else {
    //   const dialogRef = this.dialog.open(QuoteSummaryComponent, {
    //     disableClose: true,
    //     width: '500px',
    //     data: {
    //       flow: 'moto',
    //       hide_foreign_currency: 0,
    //       quote: selectedQuote,

    //       total_premium: selectedQuote.COST_OF_INSURANCE_CV,
    //       foreign_currency: selectedQuote.CURRENCY_CODE,
    //       foreign_amount: selectedQuote.COST_OF_INSURANCE,
    //       confirmedQuote: false
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe(result => { });
    // }
  }


  editQuoteSummaryDialog(policy_no: string) {
    const selectedQuote = this.quotes.find((r: any) => r.policy_sequence == policy_no);

    
    if (selectedQuote.policy_lob == '10'){
      //this.router.navigate(['/firequote'], { state: { editableQuote: selectedQuote } });
      this.router.navigate(['/firequote'], {
        state: { editableQuote: selectedQuote },
        queryParams: {
          previous: 'quotes'
        },
        queryParamsHandling: 'merge',
        skipLocationChange: false
      });

    }else if (selectedQuote.policy_lob == '50'){
      //this.router.navigate(['/travelquote'], { state: { editableQuote: selectedQuote } });
      this.router.navigate(['/travelquote'], {
        state: { editableQuote: selectedQuote },
        queryParams: {
          previous: 'quotes'
        },
        queryParamsHandling: 'merge',
        skipLocationChange: false
      });

    }else
      this.router.navigate(['/motorquote'], { state: { editableQuote: selectedQuote } });

  }

  payQuoteSummaryDialog(policy_sequence: string) {
    const selectedQuote = this.quotes.find((r: any) => r.policy_sequence == policy_sequence);

    this.choosePaymentMethod(selectedQuote);

  }

  //trigger the snackbar
  alertSnackBar(message: string, action: string, duration: number = 5000) {
    this._snackBar.open(message, action, {
      duration: duration,
    });
  }


  //confirm login pin to continue
  confirmPin(selectedQuote, payload) {
    let currentUser = this.service.currentUser();
    if (currentUser.role == "broker-user") {
      this.loginAsBrokerUser(selectedQuote, payload)
    } else {
      this.loginAsAgentClient(selectedQuote, payload)
    }
  }

  loginAsAgentClient(selectedQuote, payload) {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          if (currentUser.role == "client") {
            loginPayload = {
              phone: currentUser.phone,
              pin: result.value,
              module: 'client'
            }

          } else if (currentUser.role == "agent") {
            loginPayload = {
              agent_code: currentUser.code,
              pin: result.value,
              module: 'agent'
            }

          } else { }

          swal.showLoading()
          this.service.webLogin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog(selectedQuote, payload);
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog(selectedQuote, payload);
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog(selectedQuote, payload);
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog(selectedQuote, payload);
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin(selectedQuote, payload) }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin(selectedQuote, payload) }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });

  }


  loginAsBrokerUser(selectedQuote, payload) {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_user_code: currentUser.code,
            broker_user_pin: result.value,
            module: 'broker-user'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerUser(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog(selectedQuote, payload);
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog(selectedQuote, payload);
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog(selectedQuote, payload);
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog(selectedQuote, payload);
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin(selectedQuote, payload) }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin(selectedQuote, payload) }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }




  //choose payment method
  choosePaymentMethod(selectedQuote) {
    let payload: any = { policy_sequence: selectedQuote.policy_sequence, currency_code: 'GHS', status_of_client: 'new' }

    if (this.appuser.role == 'agent')
      payload = { ...payload, agent_code: this.appuser.code, client_code: selectedQuote.client_code };
    else
      payload = { ...payload, client_code: this.appuser.code };

    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: payload, method: this.selectedMethod, total_premium: selectedQuote.total_premium_cv, policy_lob: selectedQuote.policy_lob }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.selectedMethod = result.data.method;

        if (this.selectedMethod)
          this.confirmPin(selectedQuote, payload);

      }
    });
  }

  //finalized momo payment
  //open cash Payment Dialog
  openMomoPaymentDialog(selectedQuote, payload) {
    const dialogRef = this.dialog.open(MomoPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: payload, total_premium: selectedQuote.total_premium_cv, policy_lob: selectedQuote.policy_lob }
    });
  }
  //open card Payment Dialog
  openCardPaymentDialog(selectedQuote, payload) {
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: payload, total_premium: selectedQuote.total_premium_cv, policy_lob: selectedQuote.policy_lob }
    });

    // dialogRef.afterClosed().subscribe(result => {

    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }
    // });

  }
  //open cash Payment Dialog
  openCashPaymentDialog(selectedQuote, payload) {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: payload, total_premium: selectedQuote.total_premium_cv, policy_lob: selectedQuote.policy_lob }
    });

  }
  //open Cheque Payment Dialog
  openChequePaymentDialog(selectedQuote, payload) {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: { quotePayload: payload, total_premium: selectedQuote.total_premium_cv, policy_lob: selectedQuote.policy_lob }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }
    // });

  }



  handlePageChange(event) {
    console.log("pageIndex>>>> " + event.pageIndex)
    console.log("pageSize>>>> " + event.pageSize)

    // this.pageIndex = event.pageIndex
    // this.pageSize = event.pageSize

    this.payload.page_size = event.pageSize
    this.payload.current_page = event.pageIndex

    this.pageChange = true;
    this.getQuotes();

  }








  transactionStatus(){
    let payload: any = {}

    if(this.appuser.role=='agent'){
      payload={
        agent_code:this.appuser.code,
        client_code:this.quotePayloadBeforePayment.client_code,
        module:'agent',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      }
    }else if(this.appuser.role=='broker-user'){
      payload={
        agent_code:this.appuser.broker_user_main_broker_code,
        broker_user_code:this.appuser.code,
        client_code:this.quotePayloadBeforePayment.client_code,
        module:'broker-user',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      
      }
  
    }else if(this.appuser.role=='client'){
      payload={
        client_code:this.appuser.code,
        module:'client',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      }

    }else{}
   

    this.service.swalAlart.showLoading()
    this.service.manualQuery(payload)
    .toPromise()
    .then((response:any)=>{
      this.service.swalAlart.close()
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
          this.service.swalAlart.close();

          console.log("policy_lob===> " + payload.policy_lob);
        
          if(payload.policy_lob === "20"){
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: response.hasOwnProperty('stickerUrl')? `SMS and Email sent with Policy details to print<br><br>Kindly print <a target='_blank' href='${response.stickerUrl}'>Sticker</a> and <a target='_blank' href='${response.certificateUrl}'>Certificate</a>`:`Payment completed successfully;your certificate has been mailed to you!<br><br/>Kindly click the <a target='_blank' href='${response.certificateUrl}'>Preview</a> link to view certificate`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            .then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close()
            }
              
            });
          }else if (payload.policy_lob === "50"){
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a><br><br><a target='_blank' href='${response.travelDocUrl}'>Click here to print <b>travel documents</b></a>`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close();
              }
             });

          }else{
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close();
                
              }
             });
             
          }

       

        }else if(response.hasOwnProperty('responseCode') && response.responseCode=='E55'){

            this.openfinalizeMomoRetryPaymentDialog(payload)
            this.dialogRef.close();
          

        }else if (response.hasOwnProperty('responseCode') && response.responseCode=='001'){
          localStorage.removeItem('quotePayload')
          
          this.service.swalAlart.close();
          this.service.swalAlart.fire({
            title: 'Processing Payment...',
            html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
            icon: 'success',
            confirmButtonText:'Done',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close();
            }
           });

      }else if (response.hasOwnProperty('responseCode') && response.responseCode=='500'){
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Failed',
          text: 'Server error',
          icon: 'error',
        });
      }
      else{

      }
    })
  }

     
  openfinalizeMomoRetryPaymentDialog(payload){

    const dialogRef = this.dialogRetryRef.open(FinalizeRetryMomoComponent, {
      disableClose :true,
      width: '350px',
      data: { quotePayload: payload}
    });
    dialogRef.afterClosed().subscribe((result:any) => {

      if(result.hasOwnProperty('data')){
        result.data.quotePayload.module=this.service.currentUser().role.toLowerCase();
        //console.log('Data',result.data)
      }
    });

  }






}
