import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-momo-debit-card-payment',
  templateUrl: './momo-debit-card-payment.component.html',
  styleUrls: ['./momo-debit-card-payment.component.scss']
})
export class MomoDebitCardPaymentComponent implements OnInit {
  responseCode: string;
  responseMessage: string;

  constructor(public service: DashboardService,private router: Router, public dialogRef: MatDialogRef<MomoDebitCardPaymentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.quotePayload = data.quotePayload;
      this.total_premium =data.total_premium;
      this.formatted_premium=`${this.quotePayload.currency_code} ${data.total_premium}`;

      this.appUser= this.service.currentUser();

      this.payload={
        ...this.payload,
        policy_sequence:this.quotePayload.policy_sequence,
        //agent_code:this.quotePayload.agent_code,
        module: this.appUser.role.toLowerCase(),
        status_of_client: this.quotePayload.status_of_client
      }

      if(this.appUser.role=='client')
      this.payload={...this.payload,client_code:this.appUser.code.toString()};
      else
      this.payload={...this.payload,agent_code:this.appUser.code.toString()};

    }

    appUser:any;
    method: string;
    quotePayload:any;
    total_premium: string="0";
    formatted_premium: string="";
    payload: any={
      //agent_code:"",
      module:"agent",
      policy_sequence:"",
      transaction_type:"MobileMoney",
      status_of_client:"new"
    };

  ngOnInit() {}
  onSubmit=()=>{
     this.service.swalAlart.showLoading();
      this.service.subPayment(this.payload)
      .toPromise()
      .then((response: any)=>{
        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage
        
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){

          //set a flag to control form clearing
          this.quotePayload.module="PAID";

          this.service.swalAlart.close();

          if(response.hasOwnProperty('transactionUrl')){
              let width=500;
              let height=750;

              let y = window.top.outerHeight / 2 + window.top.screenY - ( height / 2);
              let x = window.top.outerWidth / 2 + window.top.screenX - ( width / 2);

              let paymentWindow = window.open(response.transactionUrl,'_blank',`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,width=${width},height=${height},top=${y},left=${x}`);

            
              this.dialogRef.close({ event: 'close', data: {} });
          }
          else
          this.service.swalAlart.fire({
            title: 'Failed',
            text:'Checkout Page not provided',
            icon: 'error',
          });
        }
        else if(response.hasOwnProperty('responseMessage') && response.responseCode=='E31')
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Vehicle information already exist under a policy. Please check vehicle registration number and try again.',
            icon: 'error',
          });
        else {
          let errors=response.hasOwnProperty('responseMessage') && response.responseCode=='400 BAD_REQUEST'?JSON.parse(response.responseMessage):null;
          this.service.swalAlart.fire({
            title: 'Failed',
            text:errors && errors.errors.length>0?`MID::${errors.errors[0]}`: 'Payment failed!',
            icon: 'error',
          });
        }
      }).catch(error=>{
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Failed',
          text: this.responseMessage,
          icon: 'error',
        });
      })

  }
}
