import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';

import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal, { SweetAlertResult } from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';

import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ShowPremiumTotalComponent } from '../dialogs/show-premium-total/show-premium-total.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import { CashPaymentComponent } from '../dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from '../dialogs/cheque-payment/cheque-payment.component';
import { CreateCustomerComponent } from '../dialogs/create-customer/create-customer.component';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { DebitCardPaymentComponent } from '../dialogs/debit-card-payment/debit-card-payment.component';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
//import { TravelersComponent } from '../dialogs/travelers/travelers.component';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
//import { MomoPaymentComponent } from '../dialogs/momo-payment/momo-payment.component';


interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_TYPE?: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}
interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}


interface Cover {
  PRODUCT_DESC: string;
  PRODUCT_CURRENCY: string;
  PRODUCT_CODE: string;
}


interface CurrencyResponse {
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}

interface Country {
  id: number;
  country_name: string;
  country_code: string;
  call_code: string;
  currency: string;
}
interface coverResponse {
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: Cover[]
}

interface countryResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: Country[]
}
interface occupationResponse {
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: Occupation[]
}

interface Occupation {
  PROFESSION_CODE: string;
  PROFESSION_DESC: string;
}

interface QuoteResponse {
  responseCode: string;
  responseMessage: string;
  responseData: string;
  responseNumber: string;
}


interface year {
  value: string;
  viewValue: string;
}


interface Currency {
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}
interface accessList {
  value: string;
  viewValue: string;
}


var i: number;

@Component({
  selector: 'app-travel-quote',
  templateUrl: './travel-quote.component.html',
  styleUrls: ['./travel-quote.component.scss'],
  providers: [DatePipe]
})



export class TravelQuoteComponent implements OnInit {
  appuser: any;

  //customer
  searchCustomerCtrl = new FormControl();
  searchOccupationCtrl = new FormControl();
  searchCountryCtrl = new FormControl();

  filteredCustomers: CustomerProfile[];
  filteredOccupations: Occupation[];
  filteredCountries: Country[];

  //loadings
  isCustomerLoading: boolean = false;
  isOccupationLoading: boolean = false;
  isCountryLoading: boolean = false;


  //is renewal
  renewal: boolean = false;

  //payload for submitting quote
  payload: any = {};
  filterClientPayload: any = {};

  coverData: Cover[] = [];

  dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(18, 'years').format('YYYY-MM-DD'))

  isNewCustomer: true;


  yearData: year[] = [];

  currencyData: Currency[] = []

  countryData: Country[] = [];

  occupationData: Occupation[] = [];

  accessData: accessList[] = [
    { value: "0", viewValue: "0" },
    { value: "1", viewValue: "1" },
    { value: "2", viewValue: "2" },
    { value: "3", viewValue: "3" },
    { value: "4", viewValue: "4" },
    { value: "5", viewValue: "5" },
    { value: "6", viewValue: "Above 5" },
  ]

  //selected records from dialogs
  selectedCustomer: CustomerProfile = {
    CLIENT_CODE: "",
    CLIENT_TYPE: "",
    CLIENT_FAMILY_NAME: "",
    CLIENT_HOME_MOBILE: "",
    CLIENT_FIRST_NAME: "",
    CLIENT_HOME_EMAIL: "",
    CLIENT_DATE_OF_BIRTH: "",
    DISPLAY_NAME: "",
    DISPLAY_SHORT_NAME: "",
    isNewCustomer: false
  };

  selectedCountry: Country = {
    id: 0,
    country_name: "",
    country_code: "",
    call_code: "",
    currency: ""
  }
  //searchCustomerDialogValue :any;
  //prepare quote json
  confirmedQuote: boolean = false;
  errors = {}
  selectedMethod: string;

  //is processing
  processing = false;
  quote: any = {
    edit_mode: false,
    //customer_id:"",
    agent_id: "VAN-900913",
    prepared_by: "",
    module: "Agent",
    status_of_client: "new",
    vehicle_registration: "",
    make: "",
    model: "",
    chasis_number: "",
    year_of_manufacture: "",
    body_type_code: "",
    seats: 5,
    currency_code: "GHC",
    cubic_capacity: "",
    fleet: null,
    exchange_rate: '1',
    cover_type_code: "",
    destination_coverage: "",
    trip_destination: "",
    cover_name: "",
    schedule_code: "",
    schedule_name: "",
    computation_type_code: "01",

    days_label: "Days",
    days: "0",
    _days: '',
    _months: '',

    comment: "",
    mileage: "",

    loadings: null,
    total_loadings: "0.0",
    short_rate: "100.0",
    color: "",
    fleet_import_id: null,
    schedule_id: null,

    customer_date_of_birth: "29-01-1988",
    customer_first_name: "",
    customer_last_name: "",
    customer_other_names: "",
    customer_email: "",
    customer_phone: "",
    customer_reference: "",
    customer_branch_code: "H1",
    customer_type_code: "01",

    intermediary_number: "AGT-MAXk-0000022",
    intermediary_nic_registration_number: "NIC/MC/020",
    intermediary_first_name: "dd",
    intermediary_last_name: "sd",
    intermediary_other_names: "",
    intermediary_phone: "",
    intermediary_email: "",
    intermediary_type_code: "04",
    intermediary_branch_code: "502",
    branch_code: "",

    cost_of_car: "",
    insurance_type: "",
    rate: "",
    managerEmail: "",

    total_premium: "",
    invoice: "",
    date: "",
    date_of_birth: "",
    term: "",
    start_date: new Date(),
    end_date: "",
    sum_insured: "",
    applicable_accesses: [],
    excess_buy_back: false,
    tppd: false,
    tppd_value: 0.00,
    pa: false,
    pa_value: 0,
    ncd: false,
    ncd_value: "0",
    fd: false,
    vehicle_age_loading: false,
    cc_loading: false,
    customer_code: "",

    //toggle applicable access
    can_excess_buy: true,
    check_excess_buy: false,
    can_tppd_limit: true,
    check_tppd_limit: false,
    can_pa: true,
    check_pa: false,
    can_ncd: false,
    check_ncd: false,
    can_fd: true,
    check_fd: false,
    can_vehicle_age_loading: true,
    check_vehicle_age_loading: false,
    can_cc_loading: true,
    check_cc_loading: false,
    type: "",
    digital_address: "",
    id_type: "",
    id_number: "",
    tin: "",
    policy_no: "",
    passport_no: "",

    //legacy values
    legacy_policy_sequence: "",
    legacy_total_premium: "",
    legacy_tppd_limit: "",
    legacy_excess_type_code: "",
    legacy_cc_added: "",
    legacy_age_added: "",
    legacy_additional_pa_amt: "",
  }


  /*   firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;
    fifthFormGroup: FormGroup;
    sixthFormGroup: FormGroup;
   */
  isOptional = false;

  formattedAmount;
  amount;

  todayDate = new Date();
  dateTxt: string;

  selectedModel: string = "";
  constructor(private datePipe: DatePipe, private _formBuilder: FormBuilder, private service: DashboardService, private router: Router, private currencyPipe: CurrencyPipe, public dialog: MatDialog, private _snackBar: MatSnackBar) {
    this.dateTxt = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');

    this.appuser = this.service.currentUser();
    console.log("module>>>>" + this.appuser.role)

    if (!this.appuser)
      this.router.navigate(['']);

    this.renewal = false;


    //Load covers
    this.onLoadCovers();

    //Load Occupations
    this.loadOccupations();

    //Load countries
    this.onLoadCountries();
  }


  // get the selected editable quote
  populateEditableQuote(selectQuote) {
    this.quote.edit_mode = true;

    //console.log('selected quote',selectQuote)
    //console.log('covers',this.coverData)

    this.quote = {
      ... this.quote,
      customer_code: selectQuote.CLIENT_CODE,
      customer_first_name: (selectQuote.CUSTOMER_FIRST_NAME ? selectQuote.CUSTOMER_FIRST_NAME : selectQuote.CUSTOMER_LAST_NAME),
      customer_last_name: selectQuote.CUSTOMER_LAST_NAME,
      customer_email: selectQuote.CUSTOMER_EMAIL,
      customer_phone: selectQuote.CUSTOMER_PHONE,
      prepared_by: selectQuote.PREPARED_BY,
      date_of_birth: this.service.strDateToDBFormat(selectQuote.CUSTOMER_DATE_OF_BIRTH),
      policy_no: selectQuote.POLICY_SEQUENCE,
      passport_no: selectQuote.PASSPORT_NUMBER,
      cover_type_code: selectQuote.PRODUCT_CODE.toString().trim(),
      destination_country: selectQuote.DESTINATION_COUNTRY,
      start_date: this.service.strDateToDBFormat(selectQuote.INCEPTION_DATE),
      end_date: this.service.strDateToDBFormat(selectQuote.EXPIRY_DATE),
      occupation: selectQuote.PROFESSION_DESC
    }


    if (this.quote.end_date)
      this.onEndDateChanged(new Date(this.quote.end_date));
  }

  onLoadCountries() {
    this.countryData = [];
    // this.service.swalAlart.showLoading();
    this.service.getCountries()
      .toPromise()
      .then((response: countryResponse) => {
        if (response.responseCode == '000') {
          if (response['profile']) {
            response.profile.map((country: Country) => {
              this.countryData.push({ id: country.id, call_code: country.call_code, country_code: country.country_code, country_name: country.country_name, currency: country.currency });
            })
          }
          this.service.swalAlart.close();
        }
      })
      .catch((error: any) => {
        this.service.swalAlart.close();
        console.log('Error', error);
      })

  }
  onLoadCovers() {
    this.countryData = [];
    //this.service.swalAlart.showLoading();
    this.service.getCovers()
      .toPromise()
      .then((response: coverResponse) => {
        if (response.responseCode == '000') {
          response.profile.map((cover: Cover) => {
            //if(cover.PRODUCT_DESC!='VANGUARD SAFE TRAVEL (VST)')
            this.coverData.push({ PRODUCT_CODE: cover.PRODUCT_CODE, PRODUCT_CURRENCY: cover.PRODUCT_CURRENCY, PRODUCT_DESC: cover.PRODUCT_DESC });
          })
          this.service.swalAlart.close();
        }
      })
      .catch((error: any) => {
        this.service.swalAlart.close();
        console.log('Error', error);
      })

  }
  ngOnInit() {
    //get editable quote passed from list of quotes
    let selectedQuote = history.state.editableQuote;

    if (history.state.renewal)
      this.renewal = history.state.renewal


    if (selectedQuote) {
      this.searchCustomerCtrl = new FormControl({ value: selectedQuote.CLIENT_CODE ? selectedQuote.CLIENT_CODE : null, disabled: true });
      this.searchCountryCtrl = new FormControl({ value: selectedQuote.DESTINATION_COUNTRY ? selectedQuote.DESTINATION_COUNTRY : null, disabled: false });
      this.searchOccupationCtrl = new FormControl({ value: selectedQuote.PROFESSION_DESC ? selectedQuote.PROFESSION_DESC.trim() : null, disabled: false });
    }
    else if (this.appuser && this.appuser.role == 'client') {
      this.quote.customer_code = this.appuser.code;
      this.searchCustomerCtrl = new FormControl({ value: this.quote.customer_code, disabled: true });
    }

    if (this.appuser.role == 'agent' || this.appuser.role == 'broker-user')
      this.quote.agent_id = this.appuser.code
    this.quote.intermediary_first_name = this.appuser.first_name
    this.quote.intermediary_last_name = this.appuser.first_name

    this.quote.intermediary_email = this.appuser.email
    this.quote.managerEmail = null //this.appuser.managerEmail
    this.quote.prepared_by = this.appuser.first_name + " " + this.appuser.last_name


    if (this.appuser.role == 'client')
      this.selectedCustomer = {
        CLIENT_CODE: this.appuser.client_id,
        CLIENT_FIRST_NAME: this.appuser.first_name,
        CLIENT_FAMILY_NAME: this.appuser.last_name,
        CLIENT_HOME_EMAIL: this.appuser.email,
        DISPLAY_SHORT_NAME: `${this.appuser.first_name} ${this.appuser.last_name}`,
        CLIENT_HOME_MOBILE: this.appuser.phone
      };

    this.quote = {
      ...this.quote,
      customer_first_name: this.selectedCustomer.CLIENT_FIRST_NAME,
      customer_last_name: this.selectedCustomer.CLIENT_FAMILY_NAME,
      customer_email: this.selectedCustomer.CLIENT_HOME_EMAIL,
      customer_phone: this.selectedCustomer.CLIENT_HOME_MOBILE,
      policy_no: this.appuser.policy_no,
    }


    this.selectedMethod = 'Mobile';

    this.onFilterOccupation();
    this.onFilterCountry();

    //on Customer Filter
    if (!selectedQuote)
      this.onFilterCustomer();
    else {
      //update editable quotes
      this.populateEditableQuote(selectedQuote)
      this.quote.edit_mode = true;
    }

  }

  onCoverSelected(code: string) {

  }
  onScheduleSelected(code: string) {
    console.log("scheduleType=======" + code);
    this.quote.schedule_code = code;

    this.validateForm();
  }
  //on term selection
  onSelectCountry(country: Country) {
    this.quote.destination_country = country.country_name;

    this.validateForm();
  }
  onCurrencySelected(code: string) {
    let selectedCurrency = this.currencyData.filter((currency: Currency) => currency.CURRENCY == code);

    if (selectedCurrency.length) {
      this.quote.exchange_rate = selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code = code;
    }

    console.log('Currency', this.quote.currency_code);
    this.validateForm();
  }
  onNCDSelected(code: string) {
    this.quote.ncd_value = code;
  }
  onStartDateChanged() {
    let start_date = this.quote.start_date ? moment(this.quote.start_date).format('YYYY-MM-DD') : null;

    let days = parseInt(this.quote.days);


    let end_date = moment(start_date).add(!isNaN(+`${days}`) ? days : 1, 'days').format('YYYY-MM-DD');

    this.quote.end_date = end_date;

    if (this.quote.start_date && this.quote.end_date)
      this.quote.days = this.quote._days = (moment(end_date).diff(start_date, 'days') + 1).toString();


    this.validateForm();
  }
  onEndDateChanged(date: Date) {
    let start_date = this.quote.start_date ? moment(this.quote.start_date).format('YYYY-MM-DD') : null;

    let days = 0;
    if (start_date) {

      days = moment(moment(date).format('YYYY-MM-DD')).diff(start_date, 'days');
      this.quote.days = (days + 1).toString();

    }


    this.validateForm();
  }
  //Start Date dateFilters
  startDateFilter = (date: Date) => {
    return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days') >= 0;
  }
  //End Date dateFilters
  endDateFilter = (date: Date) => {
    let diff = moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.quote.start_date).format('YYYY-MM-DD'), 'days');
    return diff >= 0 && diff <= 364;
  }


  /* handle access changes===*/
  onExcessBuyBackChange(checked: boolean) {
    this.quote.excess_buy_back = checked;
  }
  onTppdChange(checked: boolean) {
    this.quote.tppd = checked;
  }
  onPaChange(checked: boolean) {
    this.quote.pa = checked;
  }
  onNcdChange(checked: boolean) {
    this.quote.ncd = checked;
  }
  onFdChange(checked: boolean) {
    this.quote.fd = checked;
  }
  onVehicleAgeLoadingChange(checked: boolean) {
    this.quote.vehicle_age_loading = checked;
  }
  onCCLoadingChange(checked: boolean) {
    this.quote.cc_loading = checked;
  }

  // //open search customer dialog
  // AddTravelersDialog() {
  //   const dialogRef = this.dialog.open(TravelersComponent, {
  //     //width: '800px',
  //     data: { selectedCustomer: this.selectedCustomer, isNewCustomer: this.isNewCustomer }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result.hasOwnProperty('data')) {
  //     }
  //   });
  // }


  
 //confirm login pin to continue
 confirmPin() {
  let currentUser = this.service.currentUser();
  if (currentUser.role == "broker-user") {
    this.loginAsBrokerUser()
  } else {
    this.loginAsAgentClient()
  }
}


  loginAsAgentClient() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          if (currentUser.role == "client") {
            loginPayload = {
              phone: currentUser.phone,
              pin: result.value,
              module: 'client'
            }

          } else if (currentUser.role == "agent") {
            loginPayload = {
              agent_code: currentUser.code,
              pin: result.value,
              module: 'agent'
            }

          } else { }

          swal.showLoading()
          this.service.webLogin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                return
                  //this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                return
                  //this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });

  }

  loginAsBrokerUser() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_user_code: currentUser.code,
            broker_user_pin: result.value,
            module: 'broker-user'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerUser(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                return;
                  //this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                return
                  //this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }












  //choose payment method
  choosePaymentMethod() {
    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, method: this.selectedModel, total_premium: this.quote.total_premium, policy_lob: "50" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.selectedMethod = result.data.method;

        if (this.selectedMethod)
          this.confirmPin();

        this.validateForm();
      }
    });
  }

  // //open card Payment Dialog
  // openCardPaymentDialog() {
  //   const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
  //     disableClose: true,
  //     width: '350px',
  //     data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, method: this.selectedModel, total_premium: this.quote.total_premium, policy_lob: "50" }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {

  //     if (result.hasOwnProperty('data')) {
  //       this.confirmedQuote = result.data.quotePayload.module == 'PAID';

  //       if (this.confirmedQuote) {
  //         setTimeout(() => {
  //           this.router.navigate(['transactions']);
  //         }, 3000)
  //       }
  //     }

  //   });
  // }


  // //open momo Payment Dialog
  // openMomoPaymentDialog() {
  //   const dialogRef = this.dialog.open(MomoPaymentComponent, {
  //     disableClose: true,
  //     width: '350px',
  //     data: { quotePayload: { policy_sequence: this.payload.policy_sequence, client_code: this.quote.customer_code.toString(), status_of_client: this.payload.status_of_client, currency_code: 'GHS' }, total_premium: this.quote.total_premium, policy_lob: "50" }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {

  //     if (result.hasOwnProperty('data')) {

  //       if (result.data.hasOwnProperty('quotePayload'))
  //         this.confirmedQuote = result.data.quotePayload.module == 'PAID';
  //       this.validateForm();

  //       if (this.confirmedQuote) {
  //         setTimeout(() => {
  //           this.router.navigate(['transactions']);
  //         }, 3000)
  //       }
  //     }

  //   });
  // }

  //open cash Payment Dialog
  openCashPaymentDialog() {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, total_premium: this.quote.total_premium, policy_lob: "50" }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }

    });
  }

  //open Cheque Payment Dialog
  openChequePaymentDialog() {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, total_premium: this.quote.total_premium, policy_lob: "50" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }
    });
  }




  onDaysChanged(days: number) {
    //days=!isNaN(+`${this.quote.days}`)?parseInt(this.quote.days):1;
    if (days >= 365) {
      days = 364;
      this.quote.days = "365";
      this.quote.term = "01";
      console.log('Days changed is annual', days)
    }
    else
      this.quote.term = "02";

    //restrict negative days


    if (this.quote.start_date) {
      let start_date = this.quote.start_date ? moment(this.quote.start_date) : null;

      let end_date = moment(start_date).add(days, 'days').format('YYYY-MM-DD');

      this.quote.end_date = end_date;
    }

    if (days <= 0)
      this.quote.days = '1';

  }

  /*=== //handle Access changes==*/
  transformAmount(element) {
    this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, 'GHS ');
    element.target.value = this.formattedAmount;
  }

  onDaysInput(event) {
    let days = !isNaN(+`${event.target.value}`) ? parseInt(event.target.value) : 0;

    if (!days) {
      event.target.value = 1;
    }
    else if (days > 365)
      event.target.value = 365;
  }


  onBackClicked() {
    this.router.navigate(["/products"])
  }


  //open customer creation
  openCustomerCreationDialog() {
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      disableClose: true,
      width: '800px',
      data: { newCustomerDetails: {} }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data') && result.data.hasOwnProperty('customer_code'))
        this.quote = {
          ...this.quote,
          customer_code: result.data.customer_code,
          type: result.data.type,
          customer_first_name: result.data.customer_first_name,
          customer_other_names: result.data.customer_other_names,
          customer_last_name: result.data.customer_last_name,
          date_of_birth: moment(result.data.date_of_birth).format('YYYY-MM-DD'),
          customer_email: result.data.customer_email,
          customer_phone: result.data.customer_phone,
          digital_address: result.data.digital_address,
          id_type: result.data.id_type,
          id_number: result.data.id_number,
          tin: result.data.tin
        }

      if (this.quote.type == '0')
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_last_name},${this.quote.customer_phone}`;
      else
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_phone}`;


    });
  }
  //show currencies
  showCurrencies() {
    this.service.getCurrencies()
      .toPromise()
      .then((response: CurrencyResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((currency: Currency) => {
            this.currencyData.push(currency);
          })
        }
      }).catch(err => {
        console.log('Currencies load error', err);
      });
  }
  //show occupations
  loadOccupations() {
    this.service.getOccupation()
      .toPromise()
      .then((response: occupationResponse) => {
        if (response.responseCode == '000') {
          response.profile.map((occupation: Occupation) => {
            this.occupationData.push(occupation);
          })
        }
      }).catch(err => {
        console.log('occupations load error', err);
      });
  }

  onFilterOccupation() {
    this.searchOccupationCtrl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this.filteredOccupations = [];
          this.isOccupationLoading = true;
        }),
        switchMap((value: string) => {
          if (value && value.length > 0) {

            return Observable.create(observer => {
              let occupations = this.occupationData.filter((response: Occupation) => response.PROFESSION_DESC.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(occupations)
            })
              .pipe(
                finalize(() => {
                  this.isOccupationLoading = false
                }),
              )
          }
          else {
            this.isOccupationLoading = false
            return new Observable<[]>();
          }
        }
        )
      ).subscribe((occupations: any) => {
        occupations.map((response: Occupation) => {
          this.filteredOccupations.push(response);
        })
      })
  }
  onFilterCountry() {
    this.searchCountryCtrl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this.filteredCountries = [];
          this.isCountryLoading = true;
        }),
        switchMap((value: string) => {
          if (value && value.length > 0) {

            return Observable.create(observer => {
              let countries = this.countryData.filter((response: Country) => response.country_name.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(countries)
            })
              .pipe(
                finalize(() => {
                  this.isCountryLoading = false
                }),
              )
          }
          else {
            this.isCountryLoading = false
            return new Observable<[]>();
          }
        }
        )
      ).subscribe((countries: any) => {
        countries.map((response: Country) => {
          this.filteredCountries.push(response);
        })
      })
  }
  //run validation
  validateForm(input?: any): boolean {
    let hasError = false;
    this.errors = {
      start_date: false,
      end_date: false,
      destination_country: false,
      cover_type_code: false,
      occupation: false,
      passport_no: false,
      date_of_birth: false,
      customer_phone: false,
      customer_code: false,
      customer_email: false
    };

    if (!moment(this.quote.start_date).isValid()) {
      hasError = true;
      this.errors = { ...this.errors, start_date: true };
    }
    if (!moment(this.quote.end_date).isValid()) {
      hasError = true;
      this.errors = { ...this.errors, end_date: true };
    }
    if (!this.quote.destination_country) {
      hasError = true;
      this.errors = { ...this.errors, destination_country: true };
    }
    if (!this.quote.cover_type_code) {
      hasError = true;
      this.errors = { ...this.errors, cover_type_code: true };
    }
    if (!this.quote.occupation) {
      hasError = true;
      this.errors = { ...this.errors, occupation: true };
    }
    if (this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)) {
      hasError = true;
      this.errors = { ...this.errors, customer_email: true };
    }
    if (!this.quote.passport_no) {
      hasError = true;
      this.errors = { ...this.errors, passport_no: true };
    }
    if (!this.quote.date_of_birth) {
      hasError = true;
      this.errors = { ...this.errors, date_of_birth: true };
    }
    if (!this.quote.customer_code) {
      hasError = true;
      this.errors = { ...this.errors, customer_code: true };
    }
    if (!this.quote.customer_phone) {
      hasError = true;
      this.errors = { ...this.errors, customer_phone: true };
    }
    if (!this.quote.destination_country) {
      hasError = true;
      this.errors = { ...this.errors, destination_country: true };
    }
    console.log('errors', this.errors)
    return hasError;
  }
  onOccupationChange(event) {
    let occupation = this.occupationData.find((occupation: Occupation) => occupation.PROFESSION_DESC == event.target.value);
    if (!occupation)
      this.quote.occupation = '';

    //console.log('occupation',this.quote.occupation)
  }
  onCountryChange(event) {
    let country = this.countryData.find((country: Country) => country.country_name == event.target.value);
    if (!country)
      this.quote.destination_country = '';

    //console.log('occupation',this.quote.occupation)
  }
  //On Form submit
  onSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm())
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
    else {
      this.service.swalAlart.fire({
        title: "Do you want to proceed with quote?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
            //start of processing
            this.processing = true;
            let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();

            let profession = this.occupationData.find((job: Occupation) => job.PROFESSION_DESC.trim() == this.quote.occupation.trim());

            this.payload = {
              customer_first_name: this.quote.customer_first_name,
              customer_last_name: this.quote.customer_last_name,
              customer_other_names: this.quote.customer_other_names,
              customer_email: this.quote.customer_email,
              customer_phone: this.quote.customer_phone,
              prepared_by: prepared_by,

              status: 'incomplete',
              module: this.appuser.role.toLowerCase(),
              status_of_client: (this.renewal ? 'renew' : 'new'),
              customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
              customer_branch_code: this.appuser.agency,
              customer_type_code: this.quote.customer_type_code,
              platform: 'webapp',
              passport_number: this.quote.passport_no,
              profession_desc: profession ? profession.PROFESSION_DESC : null,
              profession_code: profession ? profession.PROFESSION_CODE : null,
              policy_lob: "50"
            }

            if (this.appuser.role == 'agent') {
              this.payload = {
                ...this.payload,
                agent_code: this.appuser.code.toString(),
                client_code: this.quote.customer_code.toString(),
                broker_user_code: '',
              }
            }
            else if (this.appuser.role == 'client') {
              this.payload = {
                ...this.payload,
                agent_code: '',
                client_code: this.appuser.code.toString(),
                broker_user_code: '',
              }
            } else if (this.appuser.role == 'broker-user') {
              this.payload = {
                ...this.payload,
                agent_code: this.appuser.broker_user_main_broker_code.toString(),
                client_code: this.quote.customer_code.toString(),
                broker_user_code: this.appuser.code.toString(),
              }
            }

            this.service.swalAlart.showLoading();

            if (!this.renewal && this.quote.edit_mode)
              this.finalizeQuote(this.quote.policy_no)
            else
              this.service.submitTravelQuote(this.payload)
                .toPromise()
                .then((response: QuoteResponse) => {
                  if (response.responseCode == '000') {
                    console.log('First Quote Response', response)
                    this.service.swalAlart.close();

                    this.finalizeQuote(response.responseData)
                  }
                  else if (response.responseCode == 'E56')
                    this.service.swalAlart.fire({
                      title: 'Error',
                      text: "Sorry, we couldn't find any branch associated with this account. Kindly contact your branch manager.",
                      icon: 'error',
                    });
                  else if (response.responseCode == 'E57')
                    this.service.swalAlart.fire({
                      title: 'Error',
                      text: "Sorry, you cannot renew policy at this branch,kindly contact your branch office",
                      icon: 'error',
                    });
                })
                .catch(error => {
                  this.processing = false;
                  this.service.swalAlart.close();
                  this.service.swalAlart.fire({
                    title: 'Error',
                    text: 'Server Error,retry later',
                    icon: 'error',
                  });
                })
          }
        })

    }
  }
  finalizeQuote = (policy_no) => {
    let product = this.coverData.find((cover: Cover) => cover.PRODUCT_CODE == this.quote.cover_type_code)
    let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();
    let profession = this.occupationData.find((job: Occupation) => job.PROFESSION_DESC.trim() == this.quote.occupation.trim());

    this.payload = {
      customer_first_name: this.quote.customer_first_name,
      customer_last_name: this.quote.customer_last_name,
      customer_other_names: this.quote.customer_other_names,
      customer_email: this.quote.customer_email,
      customer_phone: this.quote.customer_phone,
      policy_sequence: policy_no.toString(),
      currency_code: product.PRODUCT_CURRENCY,
      prepared_by: prepared_by,
      customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
      passport_number: this.quote.passport_no,

      fleet: null,
      inception_date: moment(this.quote.start_date).format('DD/MM/YYYY'),
      expiry_date: moment(this.quote.end_date).format('DD/MM/YYYY'),
      product: product.PRODUCT_CODE.toString(),
      duration_type: "Travel Term",
      policy_office: this.appuser.agency,
      policy_agency: this.appuser.agency || '502',
      destination_country: this.quote.destination_country.toString(),
      policy_lob: "50",
      module: this.appuser.role.toLowerCase(),
      platform: 'webapp',
      status_of_client: (this.renewal ? 'renew' : 'new'),
      profession_desc: profession ? profession.PROFESSION_DESC : null,
      profession_code: profession ? profession.PROFESSION_CODE : null,

      intermediary_first_name: this.appuser.first_name ? this.appuser.first_name : this.appuser.last_name,
      intermediary_last_name: this.appuser.last_name ? this.appuser.last_name : this.appuser.first_name,
      intermediary_phone: this.appuser.phone,
      intermediary_email: this.appuser.email
    }


    //passing the agent and client code

    if (this.appuser.role == 'agent') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.customer_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.payload = {
        ...this.payload,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.customer_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }
    }


    this.service.swalAlart.showLoading();
    this.service.submitMotoQuote(this.payload)
      .toPromise()
      .then((response: any) => {
        //console.log('quote final response',response);
        this.quote.total_premium = response.responseConvertedValue.toString().replace(",", "").trim();
        this.quote.foreign_currency = response.responseCurrencyValue;
        this.quote.foreign_amount = response.responseData.toString().replace(",", "").trim();
        this.confirmQuote();
        this.service.swalAlart.close();
        this.processing = false;
      }).
      catch(error => {
        console.log('submit error', error)
        this.processing = false;
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Error',
          text: 'Server Error,retry later',
          icon: 'error',
        });
      })
  }

  //confirm quote
  confirmQuote() {
    let product = this.coverData.find((cover: Cover) => cover.PRODUCT_CODE == this.quote.cover_type_code)
    let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();

    let summary = [
      { label: 'Date Prepared', value: moment().format('DD/MM/YYYY') },
      { label: 'Prepared By', value: prepared_by },
      { label: 'Customer Name', value: `${this.quote.customer_first_name} ${this.quote.customer_last_name}` },
      { label: 'Customer Email', value: this.quote.customer_email },
      { label: 'Customer Code', value: this.payload.client_code },
      { label: 'Mobile Number', value: this.quote.customer_phone },
      { label: 'ID Type', value: 'Passport' },
      { label: 'ID Number', value: this.quote.passport_no },
      { label: 'Plan Type', value: product.PRODUCT_DESC },
      //{label:'Coverage',value:''},
      { label: 'Destination', value: this.payload.destination_country },
      { label: 'Date of Departure', value: this.payload.inception_date },
      { label: 'Date of Return', value: this.payload.expiry_date }
    ]

    //console.log('summary',summary)
    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'travel',
        hide_foreign_currency: (product.PRODUCT_DESC == 'VANGUARD SAFE TRAVEL (VST)' ? 1 : 0),
        quote: summary,

        total_premium: this.quote.total_premium,
        foreign_currency: this.quote.foreign_currency,
        foreign_amount: this.quote.foreign_amount,
        confirmedQuote: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;

        this.choosePaymentMethod();
        this.validateForm();
      }
    });
  }

  dobFilter(date: Date) {
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'), 'years') >= 18;
  };
  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


  //autocomplete search customer
  onFilterCustomer() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCustomers = [];
          this.isCustomerLoading = true;
        }),
        switchMap(value => {
          if (value && value.length) {

            if (this.appuser.role == "agent") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                agent_code: this.appuser.code,
                search_parameter: value
              }

            } else if (this.appuser.role == "broker-user") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                broker_user_code: this.appuser.code,
                search_parameter: value
              }
            }

            console.log("filterClientPayload>>>>>>" + this.filterClientPayload)
            return this.service.searchCustomer(this.filterClientPayload)
              .pipe(
                finalize(() => {
                  this.isCustomerLoading = false
                }),
              )
          } else {
            this.isCustomerLoading = false
            return new Observable<[]>();
          }


        }
        )
      )
      .subscribe((response: CustomerResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.profile.map(r => {

            r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);

            let name_parts = [];
            if (r.CLIENT_FIRST_NAME)
              name_parts.push(r.CLIENT_FIRST_NAME);

            if (r.CLIENT_FAMILY_NAME)
              name_parts.push(r.CLIENT_FAMILY_NAME);

            if (r.CLIENT_HOME_MOBILE)
              name_parts.push(r.CLIENT_HOME_MOBILE);

            //if(r.CLIENT_CODE)
            //name_parts.push(`(${r.CLIENT_CODE})`);

            r.DISPLAY_NAME = name_parts.join(',');

            /*  let temp=name_parts[0];
             name_parts[0]=name_parts[name_parts.length-1];
             name_parts[name_parts.length-1]=temp;

             r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
            r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;

            r.isNewCustomer = false;


            this.filteredCustomers.push(r);
          })

        }
      });
  }


  //selected selected customer
  onSelectCustomer(customer: CustomerProfile) {
    this.errors = { ...this.errors, customer_code: false };

    this.selectedCustomer = customer;
    this.quote.customer_code = this.selectedCustomer.CLIENT_CODE
    this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME ? this.selectedCustomer.CLIENT_FIRST_NAME : this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
    this.quote.customer_email = this.selectedCustomer.CLIENT_HOME_EMAIL
    this.quote.date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    this.quote.customer_type_code = this.selectedCustomer.CLIENT_TYPE
  }
  onSelectOccupation(occupation: Occupation) {
    this.quote.occupation = occupation;

    if (occupation)
      this.quote.occupation = occupation.PROFESSION_DESC.trim();
  }
  //customer change event
  onCustomerChange(event) {
    if (!event.target.value) {
      this.selectedCustomer = {
        CLIENT_CODE: "",
        CLIENT_FAMILY_NAME: "",
        CLIENT_HOME_MOBILE: "",
        CLIENT_FIRST_NAME: "",
        CLIENT_HOME_EMAIL: "",
        CLIENT_DATE_OF_BIRTH: "",
        DISPLAY_NAME: "",
        DISPLAY_SHORT_NAME: "",
        isNewCustomer: false
      }
      this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME
      this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
      this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
      this.quote.customer_email = this.selectedCustomer.CLIENT_HOME_EMAIL
      this.quote.date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    }
  }

}


