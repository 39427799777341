import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import { UpdateTravelOptions } from '../dialogs/update-travel-dialog/update-travel-dialog.component';


interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}


interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}

@Component({
  selector: 'app-policy-documents',
  templateUrl: './policy-documents.component.html',
  styleUrls: ['./policy-documents.component.scss']
})
export class PolicyDocumentsComponent implements OnInit {
  appuser: any;
  userdata;
  agent_id;
  customers: any = [];
  searchdata;

  policyFilterFloat: string;

  length: number = 0
  pageIndex: number = 0
  pageSize: number = 5
  pageSizeOptions = [5, 10, 20, 50, 100]
  pageChange = false;

  searchCustomerCtrl = new FormControl();
  isCustomerLoading: boolean;

  policies: any[] = [];

  coverTypes = new Map();

  filteredCustomers: CustomerProfile[]
  selectedCustomer: CustomerProfile

  dataSource = new MatTableDataSource();
  displayedColumns = ['no', 'policy_sequence', 'customer_first_name', 'customer_phone', 'product_description', 'duration_type', 'total_premium', 'inception_date', 'expiry_date', 'date_created', 'view']


  selectedPolicy: any = null;

  filterClientPayload: any = {};


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private service: DashboardService, public dialog: MatDialog) {
    this.appuser = this.service.currentUser();


    this.isCustomerLoading = false

    this.coverTypes.set('203', 'Third Party')
    this.coverTypes.set('201', 'Comprehensive')
    this.coverTypes.set('202', 'Third Party Fire & Theft')

    this.selectedCustomer = {
      ...this.selectedCustomer,
      CLIENT_CODE: "",
      CLIENT_FAMILY_NAME: "",
      CLIENT_FIRST_NAME: "",
      DISPLAY_NAME: ""
    };
  }

  payload: any = {
    page_size: "5",
    current_page: "0"
  }

  ngOnInit() {

    this.userdata = this.service.currentUser()
    this.agent_id = this.userdata.code;

    console.log("Role>>>>>" + this.userdata.role)

    if (this.userdata.role == 'client') {
      console.log("client>>>>>")
      this.selectedCustomer = { ...this.selectedCustomer, CLIENT_CODE: this.userdata.code }
      this.onGetPolicyInfo()
      this.policyFilterFloat = "is-customer"
    }
    else if (this.userdata.role == 'agent' || this.userdata.role == 'broker-user' || this.userdata.role == 'broker-admin') {
      console.log("agent/broker>>>>>")
      this.policyFilterFloat = "is-agent";
      this.onFilterCustomer();

      try {
        let alreadySelectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'))

        if (alreadySelectedCustomer) {
          this.searchCustomerCtrl = new FormControl({ value: alreadySelectedCustomer.DISPLAY_NAME, disabled: false });
          this.onFilterCustomer()
          this.onSelectCustomer(alreadySelectedCustomer)
        }
      } catch (err) {
        console.log(err)
      }


    }


  }

  onSelectPolicy(policy) {
    this.selectedPolicy = policy;
  }

  
  // onPrinkSticker() {
  //   this.service.printSticker({ policy_sequence: this.selectedPolicy.policy_sequence, print_type: "sticker" })
  //     .toPromise()
  //     .then((response: any) => {
  //       console.log('Printable Sticker', response)
  //       if (response && response.responseCode == '000')
  //         window.open(response.stickerUrl, '_blank')
  //       else
  //         this.service.swalAlart.fire({
  //           icon: "error",
  //           text: "No Sticker found",
  //           confirmButtonText: "Ok"
  //         })
  //     })
  // }


  onPrinkCertificate() {

    let certRequestPayload =
    {
      policy_sequence: this.selectedPolicy.policy_sequence,
      print_type: "cert",
      print_form: "0"
    }


    const dialogRef = this.dialog.open(UpdateTravelOptions, {
      disableClose: false,
      width: '373px',
      data: { module: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("print form option===>" + JSON.stringify(result))

      console.log("print form option===>" + JSON.stringify(result))

      if (result.printOption == "Preprinted") {
        certRequestPayload.print_form = "0"
      }else{
        certRequestPayload.print_form = "1"
      }


      this.service.printSticker(certRequestPayload)
        .toPromise()
        .then((response: any) => {
          console.log('Printable Cert', response)
          if (response && response.responseCode == '000')
            window.open(response.certificateUrl, '_blank')
          else
            this.service.swalAlart.fire({
              icon: "error",
              text: "No Certificate found",
              confirmButtonText: "Ok"
            })
        })

    });




  }

  printPreprintedCert(){
    let certRequestPayload =
    {
      policy_sequence: this.selectedPolicy.policy_sequence,
      print_type: "cert",
      print_form: "0"
    }

    this.service.printSticker(certRequestPayload)
    .toPromise()
    .then((response: any) => {
      console.log('Printable Cert', response)
      if (response && response.responseCode == '000')
        window.open(response.certificateUrl, '_blank')
      else
        this.service.swalAlart.fire({
          icon: "error",
          text: "No Certificate found",
          confirmButtonText: "Ok"
        })
    })


  }

  printNormalcert(){

    let certRequestPayload =
    {
      policy_sequence: this.selectedPolicy.policy_sequence,
      print_type: "cert",
      print_form: "1"
    }

    this.service.printSticker(certRequestPayload)
    .toPromise()
    .then((response: any) => {
      console.log('Printable Cert', response)
      if (response && response.responseCode == '000')
        window.open(response.certificateUrl, '_blank')
      else
        this.service.swalAlart.fire({
          icon: "error",
          text: "No Certificate found",
          confirmButtonText: "Ok"
        })
    })

  }

  printTravelDoc(){

    let certRequestPayload =
    {
      policy_sequence: this.selectedPolicy.policy_sequence,
      print_type: "travelDoc",
      print_form: "0"
    }

    this.service.printSticker(certRequestPayload)
    .toPromise()
    .then((response: any) => {
      console.log('Printable travelDoc', response)
      if (response && response.responseCode == '000')
        window.open(response.travelDocUrl, '_blank')
      else
        this.service.swalAlart.fire({
          icon: "error",
          text: "No documents found",
          confirmButtonText: "Ok"
        })
    })

  }




  //autocomplete search customer
  onFilterCustomer() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCustomers = [];
          this.isCustomerLoading = true;
        }),
        switchMap(value => {
          if (value && value.length) {

            if (this.appuser.role == "agent" || this.appuser.role == "broker-admin") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                agent_code: this.appuser.code,
                search_parameter: value
              }

            } else if (this.appuser.role == "broker-user") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                broker_user_code: this.appuser.code,
                search_parameter: value
              }
            }

            console.log("filterClientPayload>>>>>>" + this.filterClientPayload)
            return this.service.searchCustomer(this.filterClientPayload)
              .pipe(
                finalize(() => {
                  this.isCustomerLoading = false
                }),
              )
          } else {
            this.isCustomerLoading = false
            return new Observable<[]>();
          }


        }
        )
      )
      .subscribe((response: CustomerResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.profile.map(r => {

            r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);

            let name_parts = [];
            if (r.CLIENT_FIRST_NAME)
              name_parts.push(r.CLIENT_FIRST_NAME);

            if (r.CLIENT_FAMILY_NAME)
              name_parts.push(r.CLIENT_FAMILY_NAME);

            if (r.CLIENT_HOME_MOBILE)
              name_parts.push(r.CLIENT_HOME_MOBILE);

            //if(r.CLIENT_CODE)
            //name_parts.push(`(${r.CLIENT_CODE})`);

            r.DISPLAY_NAME = name_parts.join(',');

            /*  let temp=name_parts[0];
             name_parts[0]=name_parts[name_parts.length-1];
             name_parts[name_parts.length-1]=temp;

             r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
            r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;

            r.isNewCustomer = false;


            this.filteredCustomers.push(r);
          })

        }
      });
  }




  onSelectCustomer(customer: CustomerProfile) {
    console.log("selectedCustomer>>>" + JSON.stringify(customer))
    this.selectedCustomer = customer;
    localStorage.setItem('selectedCustomer', JSON.stringify(this.selectedCustomer))

    this.onGetPolicyInfo();
  }

  onCustomerChange(event) {
    if (!event.target.value) {
      this.policies = [];
      this.selectedCustomer = {
        ...this.selectedCustomer,
        CLIENT_CODE: "",
        CLIENT_FAMILY_NAME: "",
        CLIENT_FIRST_NAME: "",
        DISPLAY_NAME: ""
      };
    }
  }

  onGetPolicyInfo() {
    this.payload = {
      ...this.payload,
      client_code: this.selectedCustomer.CLIENT_CODE
    }


    this.service.swalAlart.showLoading();
    this.service.getPoliciesForDocuments(this.payload)
      .toPromise()
      .then((res: any) => {

        if (res.hasOwnProperty('responseCode') && res.responseCode == '000') {
          this.policies = res.pageData.content;
          this.dataSource.data = res.pageData.content;
          this.pageSize = res.pageData.size;
          this.length = res.pageData.totalElements;
          this.searchdata = res.pageData.content;

          this.service.swalAlart.close();

        } else if (res.hasOwnProperty('responseCode') && res.responseCode == 'E42') {
          localStorage.removeItem('selectedCustomer')
          this.policies = []
          this.dataSource.data = []

          this.service.swalAlart.fire({
            title: 'Failed',
            text: res.responseMessage,
            icon: 'error',
          });
        }


      })
      .catch(error => {
        this.service.swalAlart.hideLoading();
        this.service.swalAlart.fire({
          title: 'Failed',
          text: error.error,
          icon: 'error',
        });
        this.service.swalAlart.close();
      })
  }



  openQuoteSummaryDialog(policy_no: string) {

    const selectedQuote = this.policies.find((r: any) => r.policy_sequence === policy_no);
    console.log('selected Quote', selectedQuote)

    localStorage.setItem('motor-quote', JSON.stringify(selectedQuote))
    localStorage.setItem('quote_activity', 'info_for_print')
    localStorage.setItem('selectedCustomerCode', this.selectedCustomer.CLIENT_CODE)




    this.router.navigate(["/fleet-motor-quote"], {
      queryParams: {
        previous: 'documents'
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });

  }

  handlePageChange(event) {
    console.log("pageIndex>>>> " + event.pageIndex)
    console.log("pageSize>>>> " + event.pageSize)

    // this.pageIndex = event.pageIndex
    // this.pageSize = event.pageSize

    this.payload.page_size = event.pageSize
    this.payload.current_page = event.pageIndex

    this.pageChange = true;
    this.onGetPolicyInfo();

  }




}
