import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../modules/service/dashboard.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-widget-dlypolicy',
  templateUrl: './dlypolicy.component.html',
  styleUrls: ['./dlypolicy.component.scss']
})
export class DlypolicyComponent implements OnInit {

  falseAuth:boolean = true;


  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;
  renewpolicy:any;
  stickersoldtoday:any;


  loading:boolean = false
  ud: any //get users email
  loaderror:any = ''



  constructor( private router:Router, private service:DashboardService) {
    this.ud=this.service.currentUser();
  }

  ngOnInit() {

    if (this.ud.role == "agent"){

      this.loading = true
      let payload={agent_code:this.ud.code,role:this.ud.role}

      this.service.getAnaytics(payload).subscribe(data=>{
        this.loading = false
  //this.total = parseFloat(data['total_transactions'][0]['totalAmount'].replace(/,/g,''));
  //this.transacttoday = parseFloat(data['today_transaction'][0]['totalAmount'].replace(/,/g,''));
  this.policytoday = data['today_policy'][0]['totalCount'];
  //this.renewpolicy  = data['today_policiesRenewals'][0]['totalCount'];
  //this.stickersoldtoday = data['today_sticker'][0]['totalCount'];
  //console.log(this.total, this.transact);

      }, error => {
        this.loading = false
        this.loaderror = 'Check your Internet!'
        swal.fire({
          title: 'Oops...',
          text: error.name,
          footer: ''
        });
      });

    }

    }

}
