import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';

interface VerificationResponse{
  responseCode:string,
  responseMessage:string,
  code:string,
  data:string,
  account_activation_requested:string,
  reset_requested:string,
  agent_pin_set:string
  profile: Profile[],
  responseData:string
}
interface Profile{
  AGENT_FIRST_NAME:string,
  AGENT_FAMILY_NAME:string,
  AGENT_MANAGER:string,
  AGENT_WORK_EMAIL:string,
  AGENT_AGENCY:string,
  AGENT_WORK_MOBILE:string,
  AGENT_CODE:string
}
@Component({
  selector: 'app-agent-sign-up',
  templateUrl: './agent-sign-up.component.html',
  styleUrls: ['./agent-sign-up.component.scss']
})
export class AgentSignUpComponent implements OnInit {

  constructor(private service:DashboardService, private router:Router,private _snackBar :MatSnackBar, public dialogRef: MatDialogRef<AgentSignUpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private __snackBar: MatSnackBar) { }

    payload={agent_code:'',module:'agent',token:'',pin:'',pin2:'',answer1:'',answer2:'',answer3:''}
    validate=false
    agent_code_error=false
    pin_error=false
    otp_error=false
    resend_otp=false;
    step=1
    selectedProfile: Profile;
    timer:string="00:00";
    totalWaitSeconds:number=0;
    ngOnInit() {}
  sentOtp(){
    this.resend_otp=false
    this.service.swalAlart.showLoading()
    this.onSendOtp()
  }
  onUpdateSecurityQuestions(){
    this.service.agentAddSecurityQuestions({agent_code:this.payload.agent_code,answer1:this.payload.answer1,answer2:this.payload.answer2,answer3:this.payload.answer3})
    .toPromise()
    .then((response:any)=>{
      if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
        this.service.swalAlart.fire({
          title:"Agent Sign Successful",
          text:"Agent Setup successfully",
          icon:"success"
       })
       this.dialogRef.close({ event: 'close', data: {} });
      }

      else
        this.service.swalAlart.fire({
          title:"Security Questions Update Failed",
          icon:"error",
          text:'Could not set new security questions'
        })
    })
    .catch(error=>{
      this.service.swalAlart.fire({
        title:"Security Questions Update Error",
        icon:"error",
        text:'Failed to update Security Questions'
      })
    })
  }
  onUpdatePin(){
    this.service.agentUpdatePin({agent_code:this.payload.agent_code,pin:this.payload.pin})
    .toPromise()
    .then((response: any)=>{
       if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
        this.step=4;
         this.service.swalAlart.close();
       }
        else
        this.service.swalAlart.fire({
          title:"Pin Update Failed",
          icon:"error",
          text:'Could not set new Pin'
        })
    }).catch(error=>{
      this.service.swalAlart.fire({
        title:"Pin Update Error",
        icon:"error",
        text:'Failed to update Pin'
      })
    })
  }
  onVerifyAccount(){
    this.service.verifyAgentAccount({agent_code:this.payload.agent_code,module:'agent'})
    .toPromise()
    .then((response:VerificationResponse)=>{
         if(response.hasOwnProperty('responseCode') && response.responseCode=='000')
         {
           if(response.agent_pin_set=='true'){
            this.service.swalAlart.fire({
              title:"Active Account",
              icon:"info",
              html:"Your account is already active<br>Kindly go ahead and login!",
            })

           this.service.swalAlart.hideLoading()
           this.dialogRef.close({ event: 'close', data: {} });
           }
           else{
            this.selectedProfile=response.profile[0]
               this.onSendOtp(()=>{
                this.step=2;
               });
           }

         }
         else if(response.hasOwnProperty('responseMessage'))
         {
          this.service.swalAlart.fire({
            title:"Active Account Not Found",
            icon:"warning",
            text:response.responseMessage,
          })
          this.service.swalAlart.hideLoading()
         }
    })

  }
  onAgentCode(event){
    if(isNaN(+`${event.target.value}`))
     this.agent_code_error=this.validate=true;
    else
    this.agent_code_error=this.validate=false;
  }
  onPrecious(){
    if(this.step>3)
    this.step++;
  }
  onValidateOTP(){
    this.service.validateOTP({phone:this.selectedProfile.AGENT_WORK_MOBILE,token:this.payload.token,module:'agent'})
    .toPromise()
    .then((response: any)=>{
      if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
        this.validate=this.otp_error=false
        this.step=3
        this.otp_error=false;
        this.service.swalAlart.close()
      }
      else{
        this.service.swalAlart.fire({
          title:"Otp Verification Failed",
          icon:"error",
          text:'Invalid Otp'
        })
        this.otp_error=true;
        this.service.swalAlart.hideLoading()
      }
    }).catch(error=>{
      this.service.swalAlart.fire({
        title:"OTP verification Error",
        icon:"error",
        text:'Failed to verify Otp'
      })
      this.otp_error=true;
      this.service.swalAlart.hideLoading()
    })
  }
  onSendOtp(callback=null){
    this.resend_otp=false
    this.service.sendOtp({phone:this.selectedProfile.AGENT_WORK_MOBILE,module:'agent'})
    .toPromise()
    .then((response: any)=>{
        let first3Chars :string=this.selectedProfile.AGENT_WORK_MOBILE.substr(0,3);
        let last3Chars :string=this.selectedProfile.AGENT_WORK_MOBILE.substr(-3,3);
        let userPhone=`${first3Chars}XXXX`+last3Chars;
         if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
           this.service.swalAlart.fire({
              title:"OTP Sent",
              icon:"success",
              timer:1500,
              text:`A One Time Pin has been sent to your number::${userPhone}`
           })
           this.otp_error=false

           if(callback)
           callback();

           this.service.swalAlart.hideLoading()
         }
         else{
            this.service.swalAlart.fire({
              title:"OTP Failed",
              icon:"error",
              text:`Failed to deliver a one time Pin to your number::${this.selectedProfile.AGENT_WORK_MOBILE}`
            })
           this.otp_error=true
           this.service.swalAlart.hideLoading()
         }
    }).catch(error=>{
        this.otp_error=true
        this.service.swalAlart.fire({
          title:"OTP Failed",
          icon:"error",
          text:`Otp Error`
        })
        this.service.swalAlart.hideLoading()
    })

    function pad(val) {
      var valString = val + "";
      if (valString.length < 2) {
        return "0" + valString;
      } else {
        return valString;
      }
    }
    let updateTimer=setInterval(()=>{
        if(this.totalWaitSeconds>=30000)
         clearInterval(updateTimer);

          ++this.totalWaitSeconds;
          this.timer=pad(parseInt((this.totalWaitSeconds/60).toString()))+':'+pad(parseInt((this.totalWaitSeconds%60).toString()));
    },1000);

    setTimeout(()=>{
      this.resend_otp=true
    },300000)
  }
  onPrevious(){
    this.step--;
  }
  onContinue(){
    this.resend_otp=false
    if(!this.validate && this.step==1){
      this.service.swalAlart.showLoading()
           this.onVerifyAccount();
    }
    else if(this.selectedProfile){
      if(this.step==2){
        this.service.swalAlart.showLoading()
        this.onValidateOTP();
      }
      else if(!this.otp_error && this.step==3){
        if(!this.payload.pin){
           this.pin_error=this.validate=true;
           this.alertSnackBar('Pin cannot be empty','Required');
        }
        else if(this.payload.pin.length!=6){
          this.pin_error=this.validate=true;
          this.alertSnackBar('Pin can only be six characters long','Constraint');
        }
        else if(isNaN(+`${this.payload.pin}`)){
          this.pin_error=this.validate=true;
          this.alertSnackBar('Pin must be a number','Required');
        }
        else if(this.payload.pin!=this.payload.pin2){
          this.pin_error=this.validate=true;
          this.alertSnackBar('Specified Pins do not match','Required');
        }
        else{
          this.service.swalAlart.showLoading()
          this.onUpdatePin();
        }
      }
      else if(this.step==4){
        if(!this.payload.answer1)
          this.validate=true;
        else if(!this.payload.answer2)
          this.validate=true;
        else if(!this.payload.answer3)
          this.validate=true;
        else{
          this.service.swalAlart.showLoading()
          this.onUpdateSecurityQuestions();
        }
      }
    }

  }

  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
 }
}
