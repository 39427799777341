import * as CryptoJS from 'crypto-js';
export class ScKryptoService{
   key: string="23g[0FbV7zx&:*8obZI&lm!uC3'Y:kt:czzSAAH`xVyn>.tj90nrj6?m,m^uIPK";
   constructor(){
   }

   encrypt(plainText,key?){
     return CryptoJS.AES.encrypt(plainText,key?key: this.key).toString();
   }

   decrypt(cipherText,key?){
     return CryptoJS.AES.decrypt(cipherText,key?key:this.key).toString(CryptoJS.enc.Utf8);
   }

   

   transFormLoginResponse(response: any){
    if(response.hasOwnProperty('AGENT_CODE'))
       return {
            first_name:response.AGENT_FIRST_NAME,
            code:response.AGENT_CODE,
            last_name:response.AGENT_FAMILY_NAME,
            email:response.AGENT_WORK_EMAIL,
            phone:response.AGENT_WORK_MOBILE,
            dob:null,
            role:'agent',
            agency:response.AGENT_AGENCY
        }
    else
       return {
           first_name:response.CLIENT_FIRST_NAME,
           code:response.CLIENT_CODE,
           last_name:response.CLIENT_FAMILY_NAME,
           email:response.CLIENT_HOME_EMAIL,
           phone:response.CLIENT_HOME_MOBILE,
           dob:response.CLIENT_DATE_OF_BIRTH,
           role:'client',
           agency:response.CLIENT_AGENCY || null
       }
  }
}
