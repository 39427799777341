import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-widget-dlytransact',
  templateUrl: './dlytransact.component.html',
  styleUrls: ['./dlytransact.component.scss']
})
export class DlytransactComponent implements OnInit {
  falseAuth:boolean = true;

  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;
  renewpolicy:any;
  stickersoldtoday:any;



  @ViewChild('element') element: ElementRef;
  private options = {
    content: 'This is a basic popup'
  }


  loading:boolean = false
  ud :any//get user id
  loaderror:any = ''

  config = {
    content: 'This is a basic Popup'
  }

  constructor( private router:Router, private service:DashboardService) {
    this.ud=this.service.currentUser()
  }

  ngOnInit() {

    if (this.ud.role == "agent"){

      this.loading = true
      let payload={agent_code:this.ud.code,role:this.ud.role}
    this.service.getAnaytics(payload).subscribe(data=>{
      //console.log('response dash',data)
    this.loading = false
//this.total = parseFloat(data['total_transactions'][0]['totalAmount'].replace(/,/g,''));
if(data['today_transaction'])
this.transacttoday = parseFloat(data['today_transaction'][0]['totalAmount'])
//this.policytoday = data['today_policy'][0]['totalCount'];
//this.renewpolicy  = data['today_policiesRenewals'][0]['totalCount'];
//this.stickersoldtoday = data['today_sticker'][0]['totalCount'];
//console.log(this.total, this.transact);

    }, error => {
      this.loading = false
      this.loaderror = 'Check your Internet!'
      swal.fire({
        title: 'Oops...',
        text: error.name,
        footer: ''
      });
    });

    }else{

    }


    }


    over(){
//console.log("hover")




}






}
