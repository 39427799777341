import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

interface Target {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-select-user-type',
  templateUrl: './select-user-type.component.html',
  styleUrls: ['./select-user-type.component.scss']
})

export class SelectUserTypeComponent implements OnInit {

  user_type=""
  audience: Target[] = [
    {value: 'agent', viewValue: 'Intermediary'},
    {value: 'client', viewValue: 'Customer'},
  ];
  constructor(public dialogRef: MatDialogRef<SelectUserTypeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.user_type=data.user_type;
    }
  ngOnInit() {
  }

  onSelectUserType(){
    this.dialogRef.close({ event: 'close', data: {user_type:this.user_type} });
  }
}
